// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_wrapper_porcentage__1D0tv {\n  display: grid;\n  position: relative;\n  padding: 1em;\n  background-color: white;\n  grid-template-columns: repeat(auto-fill, 45%);\n  grid-gap: 2em; }\n\n.styles_percentage_group__13cji {\n  display: grid;\n  position: relative;\n  padding: 1em;\n  grid-template-columns: repeat(auto-fill, 23%);\n  grid-gap: 2em; }\n\n.styles_tableReceive__3ejUY > div > div > div > div > div > div th {\n  border-right: 1px solid #E9E9E9; }\n\n.styles_tableReceive__3ejUY > div > div > div > div > div > div tr:nth-child(1) th {\n  background: #245046 !important; }\n\n.styles_tableReceive__3ejUY > div > div > div > div > div > div tr:nth-child(2) th {\n  background: #8FC0AA !important; }\n\n@media (max-width: 1000px) {\n  .styles_wrapper_porcentage__1D0tv {\n    grid-template-columns: 100%; }\n  .styles_percentage_group__13cji {\n    grid-template-columns: repeat(auto-fill, 100%); } }\n", ""]);
// Exports
exports.locals = {
	"wrapper_porcentage": "styles_wrapper_porcentage__1D0tv",
	"percentage_group": "styles_percentage_group__13cji",
	"tableReceive": "styles_tableReceive__3ejUY"
};
module.exports = exports;
