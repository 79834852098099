import React, {FC} from 'react'

interface Props {
    onClick?: any  
    style: object
}

const CustomButton:FC<Props> = ({children, onClick, ...props}) => {
    return (
        <button onClick={onClick} {...props}>{children}</button>
    )
}

export default CustomButton
