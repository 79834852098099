import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageHeader,
  Table,
  Modal,
  Checkbox,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Space
} from 'antd';
import exportIcon from '../../../assets/icon/export.svg';
import PlusOutlinedIcon from '../../../assets/icon/plusOutlined.svg';

import {
  fetchAllUsers,
  fetchHome,
  editUser,
  setSelectedRowKeys,
  deleteUser,
  saveUser
} from '../../../redux/users/actions';
import styles from './styles.module.scss';

import { DownloadOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import CustomButton from '../../../components/custom-button';
import AddUserModal from './components/add-modal';

function Users() {
  const headers = [
    { label: 'Nombre', key: 'name' },
    { label: 'Apellidos', key: 'lastname' },
    { label: 'Email', key: 'email' },
    { label: 'Telefono', key: 'telefono' },
    { label: 'DNI', key: 'dni' },
    { label: 'Rol usuario', key: 'role' },
  ];

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      Key:'name',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'Apellidos',
      dataIndex: 'lastname',
      key: 'lastname',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },

    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'telefono',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'DNI',
      dataIndex: 'dni',
      key: 'dni',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'Rol usuario',
      dataIndex: 'role',
      key: 'role',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Activo',
      dataIndex: 'id',
      key: 'activo',
      render: (text: any, record: any) => {
        const obj = {
          children: <Checkbox checked={record.activo} />,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      render: (text: any, record: any) => {
        const obj = {
          children: <DeleteOutlined
          style={{ fontSize: '20px', color: '#47464E'}}
        />,
          props: {
            onClick: ()=>showModalDelete(record.id)
          }
        }
        return obj
      }
    }
  ];

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [idSelectedDelete, setIdSelectedDelete]: any = useState()
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
        username: '',
        role: '',
        
        clinica_data: [],
        clinica_name:'',

        name: '',
        lastname: '',
        telefono: '',
        dni:'',

        email: '',
        password: '',

        fecha_alta: null,
        fecha_baja: '',
        observaciones:  '',
        activo: false
  });
  const [selectedUserName, setSelectedUserName] = useState('');

  const { savingError } = useSelector((state: any) => state.users);

  // Load home only when change idSelected
  useEffect(() => {
    if (idSelected) {
      dispatch(fetchHome(idSelected));
    }
  }, [idSelected]);
  


  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);

  useEffect(() => {
    if (idSelectedDelete) {
      const userr: any = data.find((e: any) => e.id === idSelectedDelete);
      setUser(userr);
    }
  }, [idSelectedDelete]);

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalDelete = (text: any) => {
    setIdSelectedDelete(text)
    setIsModalDeleteVisible(true)
  }

 const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalDeleteVisible(false)
    setIdSelectedEdit(0);
    setIdSelectedDelete(0)
    setUser({
      username: '',
      role: '',
      
      clinica_data: [],
      clinica_name:'',

      name: '',
      lastname: '',
      telefono: '',
      dni:'',

      email: '',
      password: '',

      fecha_alta: null,
      fecha_baja: '',
      observaciones:  '',
      activo: false
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const filters = useSelector((state:any) => state.Filters)
  const { editError, editOk } = useSelector((state: any) => state.users);
  const { Text } = Typography;

  const handleOk = (updatedForm: any) => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(editUser(updatedForm));
  };

  const handleOkDelete = () => {
    dispatch(deleteUser(idSelectedDelete))
    setIsModalDeleteVisible(false)
    setIdSelectedDelete('')
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch, idSelectedEdit, filters]);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch, idSelectedDelete]);


  const { loading, users } = useSelector((state: any) => state.users);

  const [data, setData] = useState(users || [{}]);

  useEffect(() => {
    setData(users);
  }, [users]);

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      users.forEach((user: any) => {
        const { name, lastname, email, phone, role, dni } = user;
        const superString = `${name}, ${lastname},${email},${phone},${role}, ${dni}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(users);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuración / Usuarios',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.users);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const handleSubmit = (submittedData: any)=>{
    dispatch(saveUser(submittedData))
  }

  useEffect(() => {
    if(savingError === 'error'){
        message.warning('Ha ocurrido un error', 2)
    }
    if(savingError === 'saved'){
        message.success('Usuario almacenado correctamente', 2)
        setIsModalAddVisible(false)
        setIsModalEditVisible(false)
    }
    if(savingError === 'form'){
        message.warning('Hubo un error con los datos suministrados')
    }
    if(savingError === 'exist'){
        message.warning('El usuario ya se encuentra registrado', 2)
    }
}, [savingError]);
  
  return (
    <div>
      <PageHeader title="Usuarios" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container" >
        <div className="search">

        <Dropdown overlay={menu}>
          <Button style={{height: '100%'}}>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: '100%' }}
          />
        </div>
        <Space style={{right: '0'}}>

          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : []}
            headers={headers}
            filename={'users.csv'}
            separator={','}
          >
            <DownloadOutlined />
            Exportar
          </CSVLink>
          <CustomButton 
            style={{
                backgroundColor: '#245046',
                width: '97px',
                height: '39px',
                color: '#ffffff',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-around',
                cursor: 'pointer'
            }}
            onClick={()=>setIsModalAddVisible(true)}  
            >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>
        
          <AddUserModal
            type=''
            prevData={false}
            onClose={()=>setIsModalAddVisible(false)}
            onSubmit={handleSubmit}
            isVisible={isModalAddVisible}
          />
          
          <AddUserModal
            type='edit'
            prevData={user}
            onClose={()=>setIsModalEditVisible(false)}
            onSubmit={handleOk}
            isVisible={isModalEditVisible}
          />

        </Space>
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          columns={columns}
          scroll={{x: true}}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
          // onRow={ (record, rowIndex)=>{
          //   return {
          //     onClick: event => {
          //       showModalEdit(record.id)
          //     }
          //   }
          // }}
        />

        <Modal
          visible={isModalDeleteVisible}
          onCancel={handleCancel}
          onOk={handleOkDelete}
          title='Eliminar clínica'
          >
            <h3>¿Estás seguro que deseas eliminar "{user.name} {user.lastname}”?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Users;
