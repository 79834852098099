import { Button } from "antd";

import React from 'react'

interface Props {
    onCancel:any
}

function ButtonSection(props: Props) {
    const {onCancel} = props

    return (
        <div 
            style={{padding: '1em 0'}}>
            <Button
                htmlType="submit"
                style={{
                    width: '89.35px',
                    height: '36.9px',
                    borderRadius: 2,
                    border: '1px solid #377466',
                    backgroundColor: '#377466',
                    color: 'white'
                }}>
                Guardar
            </Button>

            <Button
                style={{
                    width: '89.35px',
                    height: '36.9px',
                    borderRadius: 2,
                    border: '1px solid #377466',
                    backgroundColor: 'white',
                    color: '#245046',
                    marginLeft: 13.57
                }}
                onClick={onCancel}>
                Cancelar
            </Button>
        </div>
    )
}

export default ButtonSection
