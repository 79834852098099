import api from "../../helpers/api";

const parteNoAceptadosServices = {
    fetchAll: (
        date: any,
        filters: any
    )=>{
        return api.post('/admin/resumen/parte_no_aceptados', {
            filters,
            date
        })
    },
}

export default parteNoAceptadosServices