import React, {useState} from 'react'
import {Modal, Form, Row, Col, Input, Select, Checkbox} from 'antd'


interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
}

function AddTipoEmpleado(props: Props) {
    const {isVisible, onClose, onSubmit} = props
    
    const [data, setData] = useState({
        nombre: '',
        tieneEspecialidad: false
    })

    const handleChange=(event: any)=>{
        let {name, value} = event?.target

        if(name === 'tieneEspecialidad'){
            let {checked} = event?.target
            value = checked
        }

        setData({...data, [name]:value})
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        handleClose()
    }    

    const handleClose = ()=>{
        setData({
            nombre:'',
            tieneEspecialidad: false
        })
        onClose()
    }

    return (
        <Modal
            title=" Añadir tipo de empleado"
            visible={isVisible}
            onCancel={handleClose}
            onOk={handleSubmit}
            width={539}
            //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            okText="Guardar"
            cancelText="Cancelar"
            >
                <Form onFinish={()=>onSubmit(data)}>
                    <Col span={24}>
                        <Form.Item label='Nombre' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                            <Input name="nombre" onChange={handleChange} value={data.nombre} placeholder='Escribe el nombre'/>
                        </Form.Item>
                        <Form.Item>
                            <Checkbox name='tieneEspecialidad' checked={data.tieneEspecialidad} onChange={handleChange} style={{color: '#424A59', fontWeight: 400, fontSize: 16}}>
                                ¿Acepta especialidades?
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Form>
        </Modal>
    )
}

export default AddTipoEmpleado


