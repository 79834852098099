import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    PageHeader,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    message,
    Checkbox,
    Space
} from 'antd';
import exportIcon from 'assets/icon/export.svg';
import PlusOutlinedIcon from 'assets/icon/plusOutlined.svg';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import CustomButton from '../../../../../components/custom-button';
import Selector from '../../../../../components/aboveTableSelector'

import ResumenAsesoresTable from './tables/resumenAsesores' 
import IndicadorAsesores from './tables/indicadorAsesores'
import Estadisticas from './tables/estadisticasAsesores';

function EntidadesRouter() {
  const headers = [
    { label: 'CIF', key: 'cif' },
    { label: 'Razón social', key: 'razon_social' },
    { label: 'Nombre comercial', key: 'nombre_comercial' },
    { label: 'Dirección', key: 'direccion' },
    { label: 'Código postal', key: 'codigo_postal' },
    { label: 'Teléfono principal', key: 'numero_telefono' },
    { label: 'Persona de contacto', key: 'contacto_nombre' },
    { label: 'Email ', key: 'contacto_email' },
  ];

  let upperSelectorData = [{nombre: 'RESUMEN ASESORES'}, {nombre: 'indicadores asesores'}, {nombre: 'estadísticas'}]
  const [tableSelect, setTableSelect] = useState(upperSelectorData[0].nombre)


  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

    const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchAll());
  }, [dispatch, idSelectedEdit]);

  
  const { loading, resumenAsesoresData, indicadorAsesoresData, exportAsesores, headerAsesores} = useSelector((state: any) => state.ResumenGlobal);

  const [data, setData] = useState(resumenAsesoresData || [{}]);

  useEffect(() => {
    setData(resumenAsesoresData);
  }, [resumenAsesoresData]);

 

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      resumenAsesoresData.forEach((user: any) => {
        const { nombre } = user;
        const superString = `${nombre}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(resumenAsesoresData);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Resumen / Seguimiento asesores',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.ResumenGlobal);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  return (
    <div>
      <PageHeader title="SEGUIMIENTO ASESORES" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">

        <Dropdown overlay={menu}>
          <Button style={{height: '100%'}}>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
          />
          </div>
        <Space style={{right: '0'}}>

          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={exportAsesores}
            headers={headerAsesores}
            filename={'entidades.csv'}
            separator={','}
          >
            {/* <DownloadOutlined /> */}
            Descargar
          </CSVLink>
            
        </Space>
      </div>

      <div style={{padding: '1em'}}>
        <Selector 
            data={upperSelectorData}
            selected={tableSelect} 
            handleClick={(value: any)=>setTableSelect(value)}/>

        {
        
          tableSelect === 'RESUMEN ASESORES' ? <ResumenAsesoresTable pageSize={pageSize} loading={loading} data={data} /> : '' 
        }
        { 
          tableSelect === 'indicadores asesores' && <IndicadorAsesores pageSize={pageSize} loading={loading} data={indicadorAsesoresData}/>
        }
        {
          tableSelect === 'estadísticas' && <Estadisticas />
        }
      </div>
    </div>
  );
}

export default EntidadesRouter;


