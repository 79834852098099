import React, {useState} from 'react'
import {Modal, Form, Row, Col, Input, Select, Button} from 'antd'


interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
}

function AddClinicaModal(props: Props) {
    const {isVisible, onClose, onSubmit} = props

    const {Option} = Select
    const {TextArea} = Input
    
    const [data, setData] = useState({
        nombre: '',
        origen: ''
    })

    const handleChange=(name: string, value: string)=>{
        setData({...data, [name]:value})
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        onClose()
    }    

    return (
        <Modal
            title="Añadir especialidades"
            visible={isVisible}
            onCancel={onClose}
            onOk={handleSubmit}
            width={539}
            //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            okText="Guardar"
            cancelText="Cancelar"
            >
                <Form onFinish={()=>onSubmit(data)}>
                    <Col span={24}>
                        <Form.Item label='Origen' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select onChange={(e)=>handleChange('origen', e)} value={data.origen} placeholder='Elige una opción'>
                                    <Option value=''>Elige una opción</Option>
                                    <Option value='Fidelización'>Fidelización</Option>
                                    <Option value='Captación'>Captación</Option>
                                </Select>
                        </Form.Item>
                        <Form.Item label='Nombre de especialidad' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                            <Input name="nombre" onChange={(e)=>handleChange('nombre', e.target.value)} value={data.nombre} placeholder='Escribe el nombre'/>
                        </Form.Item>
                    </Col>
                </Form>
        </Modal>
    )
}

export default AddClinicaModal


