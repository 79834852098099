import api from "../../helpers/api";

const globalServices = {
    fetchResumen: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/global/resumen', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchObjetivos: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/objetivos/header', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchResumenAsesores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/resumen/asesores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchIndicadorAsesores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/indicadores/asesores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchResumenDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/resumen/doctores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchTipologiaDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/tipologia/doctores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchDerivacionesDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/derivaciones/doctores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchDerivacionesEfectivasDoctores:  (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/derivaciones-efectivas/doctores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchTotalizadorDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/totalizador/doctores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchEstadisticasDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/estadisticas/doctores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchObjetivoPrevision:  (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/objetivo-prevision', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    fetchEstadisticasAsesores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/estadisticas/asesores', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss'),
            last,
            filters
        })
    },
    getNotifications: (
        dates: any,
        filters: any
    )=>{
        return api.post('/admin/notification/parte_presupuesto', {
        dates:{
            starts : dates.starts.format('YYYY-MM-DDTHH:mm:ss'),
            ends : dates.ends.format('YYYY-MM-DDTHH:mm:ss')
        },
            filters
        })
    }
}

export default globalServices