import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageHeader,
  Table,
  Modal,
  Checkbox,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Space,
} from "antd";
import exportIcon from "../../../assets/icon/export.svg";
import PlusOutlinedIcon from "../../../assets/icon/plusOutlined.svg";

import {
  setNoAceptadosData,
  setSelectedRowKeys,
} from "../../../../../redux/partes_no_aceptados/actions";
import styles from "./styles.module.scss";

import {
  DownloadOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment";

import CustomButton from "../../../../../components/custom-button";
import Filter from "../../../cumplimentacion/pages/parte_presupuestos/components/filter";

import formatToEURO from "../../../../../helpers/formatToEuro";

import { deletePresupuesto } from "../../../../../redux/parte_presupuestos/actions";

export default function Index() {
  const [number, setNumber] = useState(0);
  const dispatch = useDispatch();

  const filterState = useSelector((state: any) => state.Filters);
  const { date } = useSelector((state: any) => state.ResumenGlobal);

  useEffect(() => {
    dispatch(setNoAceptadosData({ ...filterState, ...date }));
    setNumber(number + 1);
  }, [date, filterState]);

  const headers = [
    { label: "Clinica", key: "nombre_comercial" },
    { label: "Paciente", key: "nombre_apellidos" },
    { label: "Telefono del paciente", key: "numero_telefono" },
    { label: "Asesor", key: "name" },
    { label: "Fecha visita", key: "fecha_visita" },
    { label: "Fecha Etto", key: "fecha_etto" },
    { label: "Asistencia", key: "asistencia_paciente" },
    { label: "Especialidades no aceptadas", key: "especialidad_nombre" },
    { label: "Precio no aceptado", key: "presupuesto" },
    { label: "Comentario", key: "parte_presupuesto_observacion" },
  ];

  const columns = [
    {
      title: "Clinica",
      dataIndex: "nombre_comercial",
      Key: "nombre_comercial",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value,
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Paciente",
      dataIndex: "nombre_apellidos",
      key: "nombre_apellidos",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value,
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Telefono del paciente",
      dataIndex: "numero_telefono",
      key: "numero_telefono",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value,
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };
        return obj;
      },
    },
    {
      title: "Asesor",
      dataIndex: "name",
      key: "name",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value,
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Fecha visita",
      dataIndex: "fecha_visita",
      key: "fecha_visita",
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.fecha_visita).unix() - moment(b.fecha_visita).unix(),
        multiple: 3,
      },
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: moment(value).format("DD/MM/YYYY"),
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Fecha Etto",
      dataIndex: "fecha_etto",
      key: "fecha_etto",
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.fecha_etto).unix() - moment(b.fecha_etto).unix(),
        multiple: 3,
      },
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value ? moment(value).format("DD/MM/YYYY") : "-",
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Asistencia",
      dataIndex: "asistencia_paciente",
      key: "asistencia_paciente",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value ? value : "-",
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Especialidades no aceptadas",
      dataIndex: "especialidad_nombre",
      width: 100,
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value,
        };

        return obj;
      },
    },
    {
      title: "Precio no aceptado",
      dataIndex: "presupuesto",
      width: 100,
      sorter: {
        compare: (a: any, b: any) =>
          parseInt(a.presupuesto) - parseInt(b.presupuesto),
        multiple: 3,
      },
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: formatToEURO(value),
        };

        return obj;
      },
    },
    {
      title: "Comentario",
      dataIndex: "parte_presupuesto_observacion",
      key: "parte_presupuesto_observacion",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: value ? value : "-",
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
          },
        };

        return obj;
      },
    },
    {
      title: "Eliminar",
      dataIndex: "asistencia_paciente",
      key: "asistencia_paciente",
      render: (value: any, row: any, index: any) => {
        const obj = {
          children: (
            <DeleteOutlined style={{ fontSize: "20px", color: "#47464E" }} />
          ),
          props: {
            rowSpan: row.rowSpan && row.rowSpan,
            onClick: () => showModalWatch(row.main_id),
          },
        };

        return obj;
      },
    },
  ];
  const { loading, noAceptadosData } = useSelector(
    (state: any) => state.ParteNoAceptados
  );

  const handleClickCheckbox = (filterState: any) => {
    let { rojas, verdes, blancas, amarillo } = filterState;

    let searchedRojas = noAceptadosData.filter((item: any) => {
      if (rojas) {
        return item.asistencia_paciente === "No" && item.fecha_etto;
      }
    });

    let searchedVerdes = noAceptadosData.filter((item: any) => {
      if (verdes) {
        return item.asistencia_paciente === "Si" && item.fecha_etto;
      }
    });

    let searchedBlancas = noAceptadosData.filter((item: any) => {
      if (blancas) {
        return !item.fecha_etto;
      }
    });

    let searchedAmarillo = noAceptadosData.filter((item: any) => {
      if (amarillo) {
        return item.asistencia_paciente === "" && item.fecha_etto;
      }
    });

    setData([
      ...searchedRojas,
      ...searchedAmarillo,
      ...searchedBlancas,
      ...searchedVerdes,
    ]);
  };
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idSelectedDelete, setIdSelectedDelete]: any = useState();
  const [pageSize, setPageSize] = useState(10);

  const [success, setSuccess] = useState("");
  const { Text } = Typography;

  const [data, setData] = useState(noAceptadosData || [{}]);

  useEffect(() => {
    setData(noAceptadosData);
  }, [noAceptadosData]);

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      noAceptadosData.forEach((user: any) => {
        const {
          nombre_comercial,
          nombre_apellidos,
          numero_telefono,
          phone,
          role,
          dni,
        } = user;
        const superString = `${nombre_comercial}, ${nombre_apellidos},${numero_telefono},${phone},${role}, ${dni}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === "") {
      return setData(noAceptadosData);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "index",
      breadcrumbName: "Configuración / Partes no aceptado",
    },
  ];

  const { selectedRowKeys } = useSelector(
    (state: any) => state.ParteNoAceptados
  );
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();

  const showModalWatch = (text: any) => {
    setIdSelected(text);
    setIsModalWatchVisible(true);
  };

  const handleDelete = () => {
    dispatch(deletePresupuesto(idSelected));
    setIsModalWatchVisible(false);
  };

  const handleCancel = () => {
    setIsModalWatchVisible(false);
    setSuccess("");
  };

  return (
    <div>
      <PageHeader
        title="PARTES NO ACEPTADOS"
        ghost={false}
        breadcrumb={{ routes }}
        extra={[
          <span
          style={{
            fontSize: 17,
            paddingRight: "1em", 
          }}
          >
            Total no aceptado: <span
            style={{
              fontWeight: "bold",
            }}
            >{data && data?.map((d: any) => d.presupuesto).reduce((a: any, b: any) => a + b, 0).toLocaleString()} €</span>
          </span>
        ]}
      />

      <div className="top_content_container">
        <div className="search">
          <Dropdown overlay={menu}>
            <Button style={{ height: "100%" }}>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </div>
        <div className="search">
          <Filter handleClick={handleClickCheckbox} withoutOrange={true} />
        </div>

        <CSVLink
          style={{
            color: "#245046",
            width: "113px",
            height: "39px",
            borderRadius: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            cursor: "pointer",
            border: "1px solid #245046",
            backgroundColor: "#FFFFFF",
          }}
          data={data}
          headers={headers}
          filename={"noAceptadosData.csv"}
          separator={","}
        >
          <DownloadOutlined />
          Exportar
        </CSVLink>
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) => {
            switch (record.asistencia_paciente) {
              case "Si":
                return "table-asistencia-success";

              case "No":
                return "table-asistencia-failure";

              default:
                if (!record.fecha_etto) {
                  return "default-table-parte-presupuesto-tiny";
                } else {
                  return "default-table-parte-presupuestos";
                }
            }
          }}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />

        <Modal
          visible={isModalWatchVisible}
          onCancel={handleCancel}
          onOk={handleDelete}
          title="Eliminar Presupuesto"
        >
          <h3>¿Estás seguro que deseas eliminar el registro seleccionado?</h3>
        </Modal>
      </div>
    </div>
  );
}
