import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {Table} from 'antd'
import formatToEUR from '../../../../../../helpers/formatToEuro'

import {
    getDerivacionesEfectivasDoctores
} from '../../../../../../redux/global/actions';


interface Props {
    pageSize: any
    data: any
    loading: any
}

export default function ResumenAsesores(props: Props) {
    let {pageSize, loading, data} = props

    const { especialidadesLists } = useSelector((state: any) => state.admins);
    const [especialidadesColumns, setEspecialidadesColumns] = useState([])
    
    const columns = [
        {
          title: 'Clinica',
          dataIndex: 'clinica',
          Key:'clinica',
          fixed: 'left' as 'left',
          width: 100
        },{
          title: 'Asesor',
          dataIndex: 'asesor',
          Key:'asesor',
          fixed: 'left' as 'left',
          width: 100
        },{
          title: 'Doctor',
          dataIndex: 'user',
          Key:'user',
          fixed: 'left' as 'left',
          width: 100
        },{
          title: 'Presupuestos',
          dataIndex: 'presupuestos',
          Key:'presupuestos',
          fixed: 'left' as 'left',
          width: 100,
          sorter: {
            compare: (a: any, b: any) => parseInt(a.presupuestos) - parseInt(b.presupuestos),
            multiple: 3,
          },
        },
        ...especialidadesColumns
      ];

  const filters = useSelector ( (state: any)=> state.Filters)
  const { date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  const dispatch = useDispatch()
      
    useEffect( ()=>{
        const reformatEspecialidades = ()=>{
            let columnArray = especialidadesLists.map( (item:any)=>{
              return {
                    title: `Derivaciones de ${item.nombre}`,
                    dataIndex: item.nombre,
                    key: item.nombre,
                    width: 100,
                    sorter: {
                      compare: (a: any, b: any) => parseInt(a[item.nombre]) - parseInt(b[item.nombre]),
                      multiple: 3,
                    },
                }
            })
            setEspecialidadesColumns(columnArray)
        }
        reformatEspecialidades()

    },[especialidadesLists])

  useEffect( ()=>{
    dispatch(getDerivacionesEfectivasDoctores({...date, last: lastDates, filters}))
  },[date, lastDates, filters])

  useEffect( ()=>{
    dispatch(getDerivacionesEfectivasDoctores({...date, last: lastDates, filters}))
  },[])
  return (
        <Table
          columns={columns}
          scroll={{ x: true }}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />
    )
}
