import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Checkbox,
  Space
} from 'antd';
import exportIcon from '../../../assets/icon/export.svg';
import PlusOutlinedIcon from '../../../assets/icon/plusOutlined.svg';

import {
  fetchAll,
  editEntidades,
  setSelectedRowKeys,
  deleteEntidad,
  saveEntidades
} from '../../../redux/entidades_financieras/actions';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import CustomButton from '../../../components/custom-button';
import EntidadesModal from './components/addEspecialidadModal'

function EntidadesRouter() {
  const headers = [
    { label: 'CIF', key: 'cif' },
    { label: 'Razón social', key: 'razon_social' },
    { label: 'Nombre comercial', key: 'nombre_comercial' },
    { label: 'Dirección', key: 'direccion' },
    { label: 'Código postal', key: 'codigo_postal' },
    { label: 'Teléfono principal', key: 'numero_telefono' },
    { label: 'Persona de contacto', key: 'contacto_nombre' },
    { label: 'Email ', key: 'contacto_email' },
  ];

  const columns = [
    {
      title: 'CIF',
      dataIndex: 'cif',
      Key:'cif',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Razón social',
      dataIndex: 'razon_social',
      Key:'razon_social',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Nombre comercial',
      dataIndex: 'nombre_comercial',
      Key:'nombre_comercial',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Dirección',
      dataIndex: 'direccion',
      Key:'direccion',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Código postal',
      dataIndex: 'codigo_postal',
      Key:'codigo_postal',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Teléfono principal',
      dataIndex: 'numero_telefono',
      Key:'numero_telefono',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Persona de contacto',
      dataIndex: 'contacto_nombre',
      Key:'contacto_nombre',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Email',
      dataIndex: 'contacto_email',
      Key:'contacto_email',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Activo',
      dataIndex: 'id',
      key: 'activo',
      render: (text: any, record: any) => {
        const obj = {
          children: <Checkbox checked={record.activo} />,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      render: (text: any, record: any) => {
        const obj = {
          children: <DeleteOutlined
          style={{ fontSize: '20px', color: '#47464E'}}
        />,
          props: {
            onClick: ()=>showModalDelete(record.id)
          }
        }
        return obj
      }
    }
  ];

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [idSelectedDelete, setIdSelectedDelete]: any = useState()
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
      id: '',
      cif: '',
      razon_social: '',
      nombre_comercial: '',  
        
      direccion: '',
      codigo_postal: '',
    
      pais: '',
      poblacion: '',
    
      centro: '',
      numero_telefono: '',
    
      contacto_nombre: '',
      contacto_numero: '',
      contacto_email: '',
    
      activo: false,
    
      observaciones: '',
  });
  const [selectedUserName, setSelectedUserName] = useState('');


  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);


  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalDelete = (text: any) => {
    setIdSelectedDelete(text)
    setIsModalDeleteVisible(true)
  }

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.nombre);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalDeleteVisible(false)
    setIdSelectedEdit(0);
    setIdSelectedDelete(0);
    setUser({
      id: '',
      cif: '',
      razon_social: '',
      nombre_comercial: '',  
        
      direccion: '',
      codigo_postal: '',
    
      pais: '',
      poblacion: '',
    
      centro: '',
      numero_telefono: '',
    
      contacto_nombre: '',
      contacto_numero: '',
      contacto_email: '',
    
      activo: false,
    
      observaciones: '',
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleOk = () => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(editEntidades(user));
    handleCancel()
  };

  const handleOkDelete = async () => {
    setIsModalDeleteVisible(false)
    await dispatch(deleteEntidad(idSelectedDelete))
    setIdSelectedDelete('')
  }
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit]);

  
  const { loading, entidades, editError, editOk} = useSelector((state: any) => state.entidades);

  const [data, setData] = useState(entidades || [{}]);

  useEffect(() => {
    setData(entidades);
  }, [entidades]); 

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      entidades.forEach((user: any) => {
        const { nombre, contacto_email, contacto_numero, contacto_nombre, numero_telefono, direccion, nombre_comercial, razon_social, cif, codigo_postal } = user;
        const superString = `${nombre}, ${contacto_email}, ${contacto_numero}, ${contacto_nombre}, ${numero_telefono}, ${direccion}, ${nombre_comercial}, ${razon_social}, ${cif}, ${codigo_postal}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(entidades);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuracion / Entidades',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.entidades);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any)=> {
      dispatch(saveEntidades(form))
  }

  const handleSubmit = (form: any) =>{
      dispatch(editEntidades(form))
  }


  useEffect(() => {
    if(editError === 'error'){
        message.warning('Ha ocurrido un error', 2)
    }
    if(editOk){
        message.success('Usuario almacenado correctamente', 2)
    }
    if(editError === 'form'){
        message.warning('Hubo un error con los datos suministrados')
    }
    if(editError === 'exist'){
        message.warning('El usuario ya se encuentra registrado', 2)
    }
}, [editError, editOk]);

  return (
    <div>
      <PageHeader title="Entidades" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">

        <Dropdown overlay={menu}>
          <Button style={{height: '100%'}}>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
          />
        </div>
        
        <div className="search">


          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={'entidades.csv'}
            separator={','}
            >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          
          <CustomButton 
            style={{
              backgroundColor: '#245046',
              width: '97px',
              height: '39px',
              color: '#ffffff',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer'
            }}
            onClick={()=>setIsModalAddVisible(true)}  
            >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>
            </div>
            <EntidadesModal 
              type={false}
              prevData={false}
              isVisible={isModalAddVisible}
              onSubmit={onsubmit}
              onClose={()=>setIsModalAddVisible(false)}
              />

            <EntidadesModal 
              type='edit'
              prevData={user}
              isVisible={isModalEditVisible}
              onSubmit={handleSubmit}
              onClose={()=>setIsModalEditVisible(false)}
              />
      </div>

      <div className="content_container">
        <Table
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
          
        />
                
        <Modal
          visible={isModalDeleteVisible}
          onCancel={handleCancel}
          onOk={handleOkDelete}
          title='Eliminar entidad financiera'
          >
            <h3>¿Estás seguro que deseas eliminar "{user.nombre_comercial}”?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default EntidadesRouter;

