import parteNoAceptadosServices from "./services";

import globalConstants from "./constants";
import store from "../rootStore";

const {
    SET_LOADING_NO_ACEPTADOS,
    SET_NO_ACEPTADOS,
    SET_SELECTED_ROW_KEYS
} = globalConstants

const setNoAceptados = (data:any)=>({
    type: SET_NO_ACEPTADOS,
    data
})

const setNoAceptadosIsLoading = (isLoading:boolean)=>({
    type: SET_LOADING_NO_ACEPTADOS,
    isLoading
})

export const setNoAceptadosData = (data:any)=>{
    return (dispatch:any) =>{
        dispatch(setNoAceptadosIsLoading(true))
        // let {Filters, ResumenGlobal} = store.getState()
        let {ResumenGlobal, Filters} = store.getState()
        let anyHandler:any = ResumenGlobal
        parteNoAceptadosServices.fetchAll({starts: anyHandler.date.starts, ends: anyHandler.date.ends, last: anyHandler.lastDates}, Filters)
        .then( (res: any)=>{
            dispatch(setNoAceptadosIsLoading(false))
            dispatch(setNoAceptados(res.data))
        })
    }
}

const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
        return dispatch(selecteddRowKeys(selectedRowKeys));
    };
};