import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {Form, Col, Input, Modal} from 'antd'
import { fetchListsEmpleados } from '../../../../../redux/admins/actions'
import ButtonSection from '../../../../../components/button-sections'

interface Props {
    isModalVisible: boolean
    onCancel: any
    onSubmit: any
    formData: any
}


function Index(props: Props) {
    const {isModalVisible, onCancel, onSubmit, formData} = props
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    return (
        <Modal
        title="Añadir país"
        visible={isModalVisible}
        onCancel={onCancel}
        footer={false}
        width={539}
        //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
        bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
        style={{top: 20}}
        okText="Guardar"
        cancelText="Cancelar"
        >
            <Form onFinish={onSubmit} form={form}>
                <Col span={24}>
                    <Form.Item 
                        label='Nombre del país' 
                        name='nombre' 
                        labelCol={{span: 24}} 
                        wrapperCol={{ span: 24}}
                        rules={[{required: true, message: 'Por favor introduzca el nombre del país'}]}
                        >
                        <Input placeholder='Escribe el nombre'/>
                    </Form.Item>
                </Col>
                <ButtonSection onCancel={onCancel}/>
            </Form>
        </Modal>
    )
}

export default Index
