import React from "react";

import formatToPercentage from "../../../../../../../helpers/formatToPercent";
import formatToEUR from "../../../../../../../helpers/formatToEuro";
import { Tooltip } from "antd";
import { time } from "console";
interface Props {
  data: any;
  label: any;
}

let Colors = [
  "#555555",
  "#6F6FCA",
  "#6977A1",
  "#00084F",
  "#1F55B7",
  "#385EA0",
  "#222222",
  "#00569B",
  "#1414D4",
  "#008EB7",
  "#96ADB5",
  "#6AAD96",
  "#90D49C",
  "#5DC771",
];

export default function graphsData(props: Props) {
  let { data, label } = props;
  return (
    <div
      className="progress-bar-wrapper"
      style={{ display: "grid", gridGap: "1em" }}
    >
      {data &&
        data.map((item: any, id: any) => (
          <ProgressWrapper
            parentLabel={label}
            label={item.nombre}
            percent={item.percent}
            amount={item.amount}
            total={item?.total}
            id={id}
            key={id}
          />
        ))}
    </div>
  );
}

interface ProgressProps {
  label: any;
  percent: any;
  amount: any;
  total: any;
  id: any;
  parentLabel: any;
}

function ProgressWrapper(props: ProgressProps) {
  let { label, percent, amount, id, parentLabel } = props;

  const resultPercent: any = (percent * 100) / amount;
  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: `20% 80%`,
        gridGap: "1em",
      }}
    >
      <div style={{ fontSize: 12, textAlign: "right" }}>{label}</div>
      {/* <div style={{display:'flex', width: `${percent === 0 || !percent ? 10 : percent}%`, minWidth:50, maxWidth: 300}}> */}
      <div
        style={{ display: "flex", width: `100%`, minWidth: 50, maxWidth: 300 }}
      >
        <Progress id={id} width={percent === 0 || !percent ? 10 : percent} />
        <span style={{ marginLeft: 10, minWidth: "max-content" }}>
          {parentLabel === "PRESUPUESTOS MEDIO POR TIPOLOGÍA" ||
          parentLabel === "VENTA MEDIA POR TIPOLOGÍA" ? (
            <>
              <Tooltip title={"Media"}>
                <span>{`${formatToEUR(percent)} | `}</span>
              </Tooltip>
              <Tooltip title={"Total"}>
                <span>{`${formatToEUR(amount)} | `}</span>
              </Tooltip>
              <Tooltip title={"%"}>
                <span>{`${formatToPercentage(resultPercent)}`}</span>
              </Tooltip>
            </>
          ) : parentLabel === "ACEPTACIÓN POR ESPECIALIDAD" ||
            parentLabel === "REFERIDOS POR ESPECIALIDAD" ? (
            <span>
              <Tooltip title={"Aceptado"}>
                <span>{`${amount} `}</span>
              </Tooltip>
              <span>de</span>
              <Tooltip title={"Total"}>
                <span>{` ${props?.total} `}</span>
              </Tooltip>
              <Tooltip title={"%"}>
                <span>{`(${formatToPercentage(percent)})`}</span>
              </Tooltip>
            </span>
          ) : parentLabel === "MIX DE VENTA POR ESPECIALIDAD" ? (
            <span>
              <Tooltip title={"Venta total"}>
                <span>{`${formatToEUR(amount)} `}</span>
              </Tooltip>
              <Tooltip title={"%"}>
                <span>{`(${formatToPercentage(percent)})`}</span>
              </Tooltip>
            </span>
          ) : (
            `${formatToPercentage(percent)}(${amount})`
          )}
        </span>
      </div>
    </div>
  );
}

const Progress = (props: any) => {
  let { id, width } = props;
  let style = {
    background: Colors[id],
    borderRadius: 2,
    width: `${width || 10}%`,
  };

  return <div style={style} />;
};
