const formatToPercentage = (value: string) =>{
    let valueInt = parseFloat(value)

    let option = {
        style: 'percent',
        minimumFractionDigits: 2,
    }
    const formatter = new Intl.NumberFormat("en-US",option);
    if(valueInt === 0){
        return formatter.format(valueInt)
    }
    if(valueInt !== 0){
        return formatter.format(valueInt/100);
    }
}

export default formatToPercentage