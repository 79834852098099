import { stat } from "fs";
import centrosConstants from "./constants";

const {
    SET_TIPOS_EMPLEADOS,
    SET_EDIT_ERROR,
    SET_EDITING_TIPOS_EMPLEADOS,
    SET_EDIT_OK,
    SET_LOADING_TIPOS_EMPLEADOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_TIPOS_EMPLEADOS
} = centrosConstants

const initialState = {
    loading: false,
    data: [],
    home: [],
    editOk: false,
    selectedRowKeys: []
}

const EspecialidadesReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_TIPOS_EMPLEADOS:
            return {
                ...state,
                loading: action.isLoadingEspecialidades
            }
        
        case SET_TIPOS_EMPLEADOS:
            return{
                ...state,
                tiposEmpleados: action.tipo
            }
        
        case SET_EDITING_TIPOS_EMPLEADOS:
            return {
                ...state,
                isEditingEspecialidades: action.isEditingEspecialidades
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_TIPOS_EMPLEADOS:
            return {
                ...state
            }
    
    default:
        return state
    }
}

export default EspecialidadesReducer