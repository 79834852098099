import api from '../../helpers/api'

const EspecialidadesServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/tipos_empleados')
    },
    save: (
        nombre: string,
        tieneEspecialidad:boolean
    )=>{
        return api.post('/admin/save/tipos_empleados',{
            nombre,
            tieneEspecialidad
        }).catch(e => console.log(e))
    },
    destroy: (
        id:string,
        )=> {
            return api.get('/admin/destroy/tipos_empleados/'+id)
    }
}

export default EspecialidadesServices