const tiposOrtodonciasConstants = {
    SET_LOADING_TIPOS_ORTODONCIAS: 'SET_LOADING_TIPOS_ORTODONCIAS',
    SET_TIPOS_ORTODONCIAS: 'SET_TIPOS_ORTODONCIAS',
    SET_EDITING_TIPOS_ORTODONCIAS: 'SET_EDITING_TIPOS_ORTODONCIAS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_TIPOS_ORTODONCIAS: 'SET_SAVING_TIPOS_ORTODONCIAS'
  };
  
  export default tiposOrtodonciasConstants;
  