const centrosConstants = {
    SET_LOADING_CENTROS: 'SET_LOADING_CENTROS',
    SET_CENTROS: 'SET_CENTROS',
    SET_EDITING_CENTROS: 'SET_EDITING_CENTROS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_CENTROS: 'SET_SAVING_CENTROS',
    SET_CENTRO_PROFILE: 'SET_CENTRO_PROFILE',
    SET_SAVING_ERROR: 'SET_SAVING_ERROR',
};
  
  export default centrosConstants;
  