import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import Derivada from './derivada'
import Wrapper from './wrapper'

import ButtonSection from '../../../../../../components/button-sections'
import './styles.scss'

import { fetchListsPartePresupuesto } from '../../../../../../redux/admins/actions'
import { fetchUsersLists } from '../../../../../../redux/parte_presupuestos/actions'
import TiposPagos from '../../../../../../pages/dashboard/tipos/pagos'

import formatToEURO from '../../../../../../helpers/formatToEuro'

import {setEspecialidadesArray as setEspecialidadWrapper} from './function'

const {Title} = Typography


interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
    data: any
    setData: any
    especialidadesArrayFetched:any

    setTotal: any
    handleChange: any
    handleChangeDate: any
    handleChangeNameValue: any

    // isDerivada: any
}

function CumplimentacionModal(props: Props) {
    const {
            isVisible, 
            onClose, 
            onSubmit, 
            data, 
            setData,
            handleChange, 
            handleChangeNameValue, 
            // isDerivada, 
            especialidadesArrayFetched, 
            handleChangeDate, 
            setTotal} = props

    const {Option} = Select
    const {TextArea} = Input
    
    const {
        especialidadesLists, 
        tiposPresupuestoLists, 
        tiposPagosLists, 
        entidadesFinancierasLists,
        directorLists,
        doctorLists
    } = useSelector((state:any) => state.admins);
    
    const {usersLists} = useSelector((state: any)=> state.PartePresupuesto)
    const dispatch = useDispatch()
    const format = 'DD/MM/YYYY'

    useEffect( ()=>{
        dispatch(fetchListsPartePresupuesto())
    }, [])

    const [eachRow, setEachRow] = useState(100 / especialidadesLists.length)

    const [form] = Form.useForm()
    
    const [spanIndex, setSpanIndex] = useState(1)

    useEffect(() => {
        if(!especialidadesArrayFetched){
            setEspecialidadesArray(especialidadesLists)
            setEachRow(100 / especialidadesLists.length)
        }

        if(especialidadesArrayFetched){
            setEspecialidadesArray(data.EspecialidadesPresupuestosAceptados)
            setEachRow(100 / data.EspecialidadesPresupuestosAceptados.length)
        }
    }, [especialidadesArrayFetched, especialidadesLists]);
    
    const [especialidadesArray, setEspecialidadesArray] = useState([])
    const [isDerivada, setIsDerivada] = useState('');
    const [assist, setAssist] = useState('')
    const [wrapper, setWrapper]: any = useState([])
    const [tempState, setTempState] = useState([...wrapper]);
    const [total, setTotalA] = useState({
        aceptado: 0,
        presupuestado: 0
    })
    const [fecha_visita, setFecha_visita] = useState(null)
    const [fecha_etto, setFecha_etto] = useState(null)
    const [tiene_ausencias, setTiene_ausencias] = useState(null)
    
    useEffect(() => {
        setWrapper(setEspecialidadWrapper('', especialidadesLists))
    }, [especialidadesLists])
    
    useEffect(() => {
        setWrapper(setEspecialidadWrapper('', especialidadesLists))
    }, [])

    const handleClickSpan = (id:any)=>{
        setSpanIndex(id+1)
    }

    const sortFromList = (name: any, value:any) =>{
        if(name === 'tipos_presupuesto'){
            let sortedValue = tiposPresupuestoLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'director'){
            let sortedValue = directorLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'tipos_pagos'){
            let sortedValue = tiposPagosLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'entidad_financiera'){
            let sortedValue = entidadesFinancierasLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'doctor'){
            let sortedValue = doctorLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }
    }
    
    const handleOk = () =>{
        form
        .validateFields()
        .then( (fields:any)=>{
            onSubmit({...fields,DerivadoEspecialidades: fields.especialidadesArray, EspecialidadesPresupuestosAceptados: tempState, total})
            form.resetFields()
            //onClose()
        })
    }

    useEffect(() => {
        form.resetFields()
        setWrapper([...especialidadesLists.filter((index:any)=> !data.EspecialidadesPresupuestosAceptados.some((item:any)=> item.name === index.name)), ...data.EspecialidadesPresupuestosAceptados])
        setTotalA({
            aceptado: data.total_aceptado,
            presupuestado: data.total_presupuestado
        })
        setFecha_etto(data.fecha_etto)
        setFecha_visita(data.fecha_visita)
        setIsDerivada(data.derivado)
        setAssist(data.asistencia_paciente)
    }, [data]);

    useEffect(() => {
        dispatch(fetchUsersLists())
    }, []);

    const setTotalWrapper = (presupuesto: any, aceptado: any, wrapperData:any)=>{
        setWrapper(wrapperData)
        setTotalA({
                presupuestado: presupuesto, 
                aceptado: parseInt(aceptado)
            
        })
    }

    return (
        <Modal
            title="Añadir presupuesto"
            visible={isVisible}
            onCancel={onClose}
            width={1020}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            footer={null}
            forceRender
        >
                <Form onFinish={handleOk} form={form} initialValues={{...data}}>
                    <Col span={24}>
                        <Title style={{fontSize: 20}}>DATOS DEL PACIENTE</Title>
                    </Col>

                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '60% 38.5%'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Nombre y apellidos' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='nombre_apellidos'
                                rules={[{required: true, message: 'Por favor introduzca el nombre y los apellidos'}]}
                                >
                                <Input 
                                    name="nombre_apellidos" 
                                    // onChange={handleChange} 
                                    // value={data.nombre_apellidos} 
                                    placeholder='Escribir nombre y apellido'/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='Teléfono' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='numero_telefono'
                                rules={[{required: true, message: 'Por favor el numero de telefono'}]}
                                >
                                <InputNumber 
                                    style={{width: '100%'}} 
                                    name="numero_telefono" 
                                    // onChange={(value:any)=>handleChangeNameValue('numero_telefono', value)} 
                                    // value={data.numero_telefono} 
                                    placeholder='Escribir número'
                                    maxLength={11}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Col span={24}>
                        <Title style={{fontSize: 20}}>DATOS DE LA VISITA</Title>
                    </Col>

                    <Row style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '24.5% 24.5% 24.5% 24.5%'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Fecha visita' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='fecha_visita'
                                valuePropName='fecha_visita'
                                rules={[{required: true, message: 'Por favor introduzca la fecha de visita'}]}
                                >
                                <DatePicker 
                                    value={fecha_visita && moment(fecha_visita)}
                                    format={format}
                                    style={{width: '100%'}}
                                    onChange={(value: any)=>setFecha_visita(value)}
                                    />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='Tipo de presupuesto' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='TiposPresupuesto'
                                rules={[{required: true, message: 'Por favor seleccione un tipo de presupuesto'}]}
                                >
                                <Select 
                                    // onChange={(value:any)=>sortFromList('tipos_presupuesto', value)} 
                                    // value={data.TiposPresupuesto && data.TiposPresupuesto}
                                    >
                                    {
                                        tiposPresupuestoLists.map( (item:any, id:any)=>(
                                            <Option key={id} value={item.id}>{item.nombre}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='Director / asesor' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='asesor'
                                rules={[{required: true, message: 'Por favor seleccione un Director / asesor'}]}
                                >
                                <Select
                                    // onChange={(value:any)=>sortFromList('director', value)} 
                                    // value={data.asesor}
                                    >
                                    {
                                        usersLists.map( (item:any, id:any)=>(
                                            <Option key={id} value={item.id}>{item.name} {item.lastname}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='Primer doctor' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='doctor'
                                rules={[{required: true, message: 'Por favor seleccione un doctor'}]}
                                >
                                <Select 
                                    // onChange={(value:any)=>handleChangeNameValue('doctor', value)}
                                    >
                                    {
                                        doctorLists.map( (item:any, id:any)=>(
                                            <Option key={id} value={item.id}>{item.nombre} {item.apellidos}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        
                        <Wrapper 
                            data={data}
                            setData={setData}
                            spanIndex={spanIndex}
                            handleClickSpan={handleClickSpan}
                            especialidadesLists={especialidadesLists}
                            eachRow={eachRow}

                            wrapper={wrapper}
                            setTotal={setTotalWrapper}
                            tempState={tempState}
                            setTempState={setTempState}
                            handleChange={handleChange}
                            handleChangeNameValue={handleChangeNameValue}
                        />


                        <Col span={24}>
                            
                            <Row style={{display:'flex', alignItems:'center', gap: '9em', padding: '1em 0'}}>
                                <div style={{display:'flex', alignItems:'center', gap: '2em', padding: '1em 0'}}>
                                    <Title level={3} style={{margin: 0, color: 'rgba(36, 80, 70, 1)', fontSize: 18}}>
                                        Total Presupuestado  
                                    </Title>

                                    <Title level={3} style={{margin: 0, color: 'rgba(36, 80, 70, 1)', fontSize: 24}}>
                                        {formatToEURO(total.presupuestado)}
                                    </Title>
                                </div>    
                                
                                
                                <div style={{display:'flex', alignItems:'center', gap: '2em', padding: '1em 0'}}>
                                    <Title level={3} style={{margin: 0, color: 'rgba(36, 80, 70, 1)', fontSize: 18}}>
                                        Total Aceptado  
                                    </Title>

                                    <Title level={3} style={{margin: 0, color: 'rgba(36, 80, 70, 1)', fontSize: 24}}>
                                        {formatToEURO(total.aceptado)}
                                    </Title>
                                </div>               
                            </Row>

                            <Row style={{display:'grid', gridTemplateColumns: '49.5% 49.5%', justifyContent: 'space-between'}}>
                                <Col span={24}>
                                    <Form.Item 
                                        label='¿Es derivable a periodoncia?' 
                                        labelCol={{span: 24}} 
                                        wrapperCol={{span: 24}}
                                        name='derivable_periodoncia'
                                        rules={[{required: true, message: 'Por favor seleccione una opción'}]}
                                        >
                                        <Select 
                                            // onChange={(value:any)=>handleChangeNameValue('derivable_periodoncia', value)}
                                            // value={data.derivable_periodoncia}
                                            >
                                                <Option value='Si'>Si</Option>
                                                <Option value='No'>No</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item 
                                        label='¿Es derivable a estética?' 
                                        labelCol={{span: 24}} 
                                        wrapperCol={{ span: 24}}
                                        name='derivable_estetica'
                                        rules={[{required: true, message: 'Por favor seleccione una opción'}]}
                                        >
                                    <Select 
                                        // onChange={(value:any)=>handleChangeNameValue('derivable_estetica', value)}
                                        // value={data.derivable_estetica}
                                        >
                                            <Option value='Si'>Si</Option>
                                            <Option value='No'>No</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item 
                                        label='¿Tiene ausencias?' 
                                        labelCol={{span: 24}} 
                                        wrapperCol={{ span: 24}}
                                        name='tiene_ausencias'
                                        rules={[{required: true, message: 'Por favor seleccione una opción'}]}
                                        >
                                    <Select 
                                        onChange={(value:any)=>setTiene_ausencias(value)}
                                        // value={data.tiene_ausencias}
                                        >
                                            <Option value='Si'>Si</Option>
                                            <Option value='No'>No</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item 
                                        label='Nª ausencias' 
                                        labelCol={{span: 24}} 
                                        wrapperCol={{ span: 24}}
                                        name='numero_ausencias'
                                        rules={[{
                                            required: data.tiene_ausencia === 'Si' && true, 
                                            message: 'Por favor seleccione una opción'
                                            }]
                                        }
                                        >
                                        {
                                            tiene_ausencias === 'Si' ?
                                            <InputNumber 
                                            // onChange={(value: any)=>handleChangeNameValue('numero_ausencias', value)} 
                                            // value={data.numero_ausencias} 
                                            style={{width: '100%'}}
                                            min={0}
                                            placeholder='Escribir cantidad'/>

                                            :

                                            <InputNumber 
                                            // onChange={(value: any)=>handleChangeNameValue('numero_ausencias', value)} 
                                            // value={data.numero_ausencias} 
                                            style={{width: '100%'}}
                                            placeholder='Escribir cantidad'
                                            disabled/>
                                        }
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item 
                                        label='¿Tiene apiñamiento o mala oclusión?' 
                                        labelCol={{span: 24}} 
                                        wrapperCol={{ span: 24}}
                                        name='apinamiento_oclusion'
                                        rules={[{
                                            required: true, 
                                            message: 'Por favor seleccione una opción'
                                            }]
                                        }
                                        >
                                    <Select 
                                        // onChange={(value:any)=>handleChangeNameValue('apinamiento_oclusion', value)}
                                        // value={data.apinamiento_oclusion}
                                        >
                                            <Option value='Si'>Si</Option>
                                            <Option value='No'>No</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item 
                                        label='¿Derivado?' 
                                        labelCol={{span: 24}} 
                                        wrapperCol={{ span: 24}}
                                        name='derivado'
                                        rules={[{
                                            required: true, 
                                            message: 'Por favor seleccione una opción'
                                            }]
                                        }
                                        >
                                        <Select 
                                            onChange={(value: any)=>setIsDerivada(value)}
                                            // value={data.derivado}
                                            >
                                            <Option value='Si'>Si</Option>
                                            <Option value='No'>No</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {
                                isDerivada === 'Si' ? 
                                    <Derivada 
                                        data={data}
                                        especialidadesLists={especialidadesLists} 
                                        eachRow={eachRow}
                                        tiposPagosLists={tiposPagosLists}
                                        entidadesFinancierasLists={entidadesFinancierasLists}
                                        
                                        fecha_etto={fecha_etto}
                                        setFecha_etto={setFecha_etto}
                                        assist={assist}

                                        setAssist={setAssist}
                                        sortFromList={sortFromList}
                                        handleChange={handleChange} 
                                        handleChangeDate={handleChangeDate}
                                        handleChangeNameValue={handleChangeNameValue}
                                    />
                            
                                :                            

                                <Row>
                                    <Title style={{fontSize: 20}}>DATOS DE ASISTENCIA Y MÉTODO DE PAGO</Title>
                                    
                                    <Row style={{width:'100%', display: 'grid', gridGap: '.5em', gridTemplateColumns: '24.5% 24.5% 24.5% 24.5%'}}>
                                        <Col span={24}>
                                            <Form.Item 
                                                label='Fecha ETTO' 
                                                name='fecha_etto' 
                                                valuePropName='fecha_etto' 
                                                labelCol={{span: 24}} 
                                                wrapperCol={{ span: 24}}
                                                rules={
                                                    [{ required: assist === 'Si' || assist === 'No' ? true : false}]
                                                }
                                                >
                                                <DatePicker 
                                                    style={{width: '100%'}}
                                                    value={fecha_etto && moment(fecha_etto)}
                                                    format={format}
                                                    disabledDate={d => !d || d.isBefore(data.fecha_visita)}
                                                    onChange={(e:any)=>setFecha_etto(e)}
                                                    />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item 
                                                label='Asistencia del paciente' name='asistencia_paciente' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                                <Select 
                                                    onChange={(value: any)=>{
                                                        setAssist(value)
                                                    }}
                                                    >
                                                    <Option value=''> - </Option>
                                                    <Option value='Si'>Si</Option>
                                                    <Option value='No'>No</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item label='Tipo de pago' labelCol={{span: 24}} name='tipo_pago_id' wrapperCol={{ span: 24}}>
                                                <Select 
                                                    // onChange={(value)=>sortFromList('tipos_pagos',value)}
                                                    >
                                                    {
                                                        tiposPagosLists.map( (item:any, id: any)=>(
                                                            <Option key={id} value={item.id}>{item.nombre}</Option>
                                                        ))
                                                    } 
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24} style={{display: 'flex', alignItems:'center'}}>
                                            <Form.Item label='Descuento' labelCol={{span: 24}} wrapperCol={{ span: 24}} name='descuento' className="descuento">
                                                <InputNumber 
                                                    min={0} 
                                                    max={100} 
                                                    parser={(value:any) => value.replace('%', '')}/>
                                                <span className="descuento-span">
                                                    % 
                                                </span>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Row>
                            }

                                <Row>
                                    <Col span={24}>
                                        <Form.Item label='Observaciones' name='observaciones'  labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                            <TextArea 
                                                // onChange={handleChange}  
                                                // value={data.observaciones}
                                            />
                                        </Form.Item>
                                    </Col>    
                                </Row>

                        <ButtonSection 
                            onCancel={onClose}
                            />

                        </Col>
                    </Row>
                </Form>
        </Modal>
    )
}

export default CumplimentacionModal


