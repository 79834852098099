import partePresupuestosConstants from "./constants"

import PartePresupuesto from './services'

const {
    SET_LOADING_FINANCIACIONES, 
    SET_FINANCIACIONES,
    SET_SELECTED_ROW_KEYS,
} = partePresupuestosConstants

const setPresupuestos = (partePresupuesto:any)=>({
    type: SET_FINANCIACIONES,
    partePresupuesto
})

const setLoadingPresupuestos = (loading:boolean)=>({
    type: SET_LOADING_FINANCIACIONES,
    loading
})

export const fetchAll = () => {
    return (dispatch: any) => {
        dispatch(setLoadingPresupuestos(true));
        PartePresupuesto.fetchAll().then((res) => {
            dispatch(setLoadingPresupuestos(false));
            return dispatch(setPresupuestos(res.data.response));
        });
    };
};

const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
        return dispatch(selecteddRowKeys(selectedRowKeys));
    };
};

