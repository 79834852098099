// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_img__sU1j1 {\n  padding-right: 5px;\n  line-height: 0;\n  vertical-align: -2px;\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased; }\n", ""]);
// Exports
exports.locals = {
	"img": "styles_img__sU1j1"
};
module.exports = exports;
