import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, message, Checkbox} from 'antd'

import ButtonSection from '../../../../components/button-sections'
import Item from 'antd/lib/list/Item'

interface Props {
    type: string | boolean
    prevData: any
    isVisible: boolean
    onClose?: any
    onSubmit: any
}

function AddClinicaModal(props: Props) {
    const {type, isVisible, onClose, onSubmit, prevData} = props

    const {Option} = Select
    const {TextArea} = Input
    
    const {centrosLists, paisLists} = useSelector((state:any)=> state.admins)

    useEffect(()=>{
        if(type !== 'edit'){
            setData({
                cif: '',
                razon_social: '',
                nombre_comercial: '',
        
                direccion: '',
                codigo_postal: '',
        
                pais: '',
                poblacion: '',
        
                centro: '',
                numero_telefono: '',
        
                contacto_nombre:'',
                contacto_numero:'',
                contacto_email:'',
        
                activo: false,
                observaciones: ''
            })
        }
    },[type])

    useEffect( ()=>{
        if(type === 'edit'){
            setData({...prevData, activo: prevData.activo === 'true' ? true : false})
        }
    },[prevData])

    const [data, setData] = useState({
        cif: '',
        razon_social: '',
        nombre_comercial: '',

        direccion: '',
        codigo_postal: '',

        pais: '',
        poblacion: '',

        centro: '',
        numero_telefono: '',

        contacto_nombre:'',
        contacto_numero:'',
        contacto_email:'',

        activo: false,
        observaciones: ''
    })

    const handleChange=(event: any)=>{
        let {name, value, type, checked} = event?.target
        if(type === 'checkbox'){
            setData({...data, [name]:checked})
        }

        if(type !== 'checkbox'){
            setData({...data, [name]:value})
        }
    }
    
    const handleChangeSelect = (name: string, value: string)=>{
        setData({...data, [name]:value})
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        onClose()
        setData({
            cif: '',
            razon_social: '',
            nombre_comercial: '',
    
            direccion: '',
            codigo_postal: '',
    
            pais: '',
            poblacion: '',
    
            centro: '',
            numero_telefono: '',
    
            contacto_nombre:'',
            contacto_numero:'',
            contacto_email:'',
    
            activo: false,
            observaciones: ''
        })
    }    

    return (
        <Modal
            title="Añadir entidades financieras"
            visible={isVisible}
            onCancel={onClose}
            onOk={handleSubmit}
            width={1020}
            footer={null}
            //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            >
                <Form onFinish={handleSubmit}>
                    
                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='CIF' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="cif" onChange={handleChange} required value={data.cif} placeholder='Escribe el CIF'/>
                            </Form.Item>
                        </Col>
                    
                        <Col span={24}>
                            <Form.Item 
                                label='Razón social' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="razon_social" onChange={handleChange} required value={data.razon_social} placeholder='Escribe la razon social'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Nombre Comercial' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="nombre_comercial" onChange={handleChange} required value={data.nombre_comercial} placeholder='Escribe el nombre'/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Dirección' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="direccion" onChange={handleChange} required value={data.direccion} placeholder='Escribe el telefono'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Código postal' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="codigo_postal" onChange={handleChange} required value={data.codigo_postal} placeholder='Escribe la dirección de correo'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Selecciona un país' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select onChange={(e)=>handleChangeSelect('pais', e)} value={data.pais} placeholder='Elige una opción'>
                                    <Option value=''> ----------</Option>
                                    {
                                        paisLists.map( (item: any)=>(
                                            <Option key={item.id} value={item.id}>{item.nombre}</Option>     
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item label='Selecciona una población' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Input name="poblacion" onChange={handleChange} value={data.poblacion} placeholder='Escriba la población'/>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Selecciona un centro' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select onChange={(e)=>handleChangeSelect('centro', e)} value={data.centro} placeholder='Elige una opción'>
                                    {
                                        centrosLists.map( (item:any)=>(
                                            <Option value={item.id}>{item.clinica_nombre}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Teléfono' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="numero_telefono" onChange={handleChange} required value={data.numero_telefono} placeholder='Escribe el número'/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Persona de contacto' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="contacto_nombre" onChange={handleChange} required value={data.contacto_nombre} placeholder='Escribe el nombre'/>
                            </Form.Item>
                        </Col>
                    
                        <Col span={24}>
                            <Form.Item 
                                label='Teléfono de contacto' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="contacto_numero" onChange={handleChange} required value={data.contacto_numero} placeholder='Escribe el teléfono'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Email de contacto' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'},
                                    {type: 'email', message: 'Introduce un email válido'} 
                                ]}>
                                <Input type='email' name="contacto_email" onChange={handleChange} required value={data.contacto_email} placeholder='Escriba la direccion de correo'/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col span={24}>
                            <Form.Item label='Activo' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Checkbox name="activo" onChange={handleChange} checked={data.activo} />
                            </Form.Item>
                        </Col>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Observaciones'  labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <TextArea name="observaciones" onChange={handleChange} value={data.observaciones} placeholder='Escribir comentario...'/>
                            </Form.Item>
                        </Col>    
                    </Row>
                    <ButtonSection onCancel={onClose}/>  
                </Form>
        </Modal>
    )
}

export default AddClinicaModal


