const authConstants = {
  SET_LOADING_ADMINS: 'SET_LOADING_ADMINS',
  SET_ADMINS: 'SET_ADMINS',
  SET_EDITING_ADMIN: 'SET_EDITING_ADMIN',
  SET_NEW_ADMIN: 'SET_NEW_ADMIN',
  SET_SIGNUP_ERROR: 'SET_SIGNUP_ERROR',
  SET_LISTS: 'SET_LISTS',
  SET_LISTS_PREVISIONES: 'SET_LISTS_PREVISIONES',
  SET_LISTS_CAJA_DIA: 'SET_LISTS_CAJA_DIA', 
  SET_PARTE_PRESUPUESTO: 'SET_PARTE_PRESUPUESTO',
  SET_LISTS_EMPLEADOS: 'SET_LISTS_EMPLEADOS'
};

export default authConstants;
