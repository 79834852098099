import React, {useState} from 'react'

import DobleArrow from '../assets/icon/double_arrow.svg'

interface Props {
    data: any,
    selected: string,
    handleClick: any
}

export default function AboveTableSelector(props: Props) {
    let {data, selected, handleClick} = props
    const [x, setX] = useState(0)
    const goRight = ()=>{
        if(x > -60){
            setX(x-data.length)
        }
    }
    const goLeft = ()=>{
        setX(0)
    }

    return (
        <div className='selector-wrapper-style' style={{backgroundColor: 'white', overflow: 'scroll'}}>
            {data && data.map( (item:any, id: any) =>(
                <OptionText 
                    style={{transform: 'translateX('+x+'vw)'}} 
                    selected={selected} 
                    handleClick={()=>handleClick(item.nombre)} 
                    key={id}>
                        {item.nombre}
                    </OptionText>
            ))}
            {x > -60 && <ScrollButton side='right' handleClick={goRight}/>}
            {x < 0 && <ScrollButton side='left' handleClick={goLeft}/>}
        </div>
    )
}

interface OptionTextProps {
    selected: string,
    children: any,
    handleClick: any
    style: any
}

const OptionText = (props:OptionTextProps) =>{
    let {selected, children, handleClick, style} = props

    return (
        <button 
            className={children === selected ? 'selected-option ' : 'default-option'}
            onClick={handleClick}
            style={style}>
            {children}
        </button>
    )
} 


const ScrollButton = (props: any)=>{
    let {side, handleClick} = props
    return (
        <button className='scroll-button' style={{[side]:0}} onClick={handleClick}> 
            <img src={DobleArrow} style={{ transform: side === 'left' ? 'rotate(180deg)' : ''}}/>
        </button>
    )
}