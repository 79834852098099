import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    PageHeader,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    message,
    Checkbox,
    Space
} from 'antd';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import TableCobrado from './component/table_cobrado' 
import ProgressBarWrapper from './component/wrapper_progress'
import TableMonths from './component/table_months';
import GraphsWrapper from './component/graphs_wrapper';
import UseWidth from '../../../../../helpers/useWidth';


export default function Index() {

    const [pageSize, setPageSize] = useState(10);
    const {resumenData, loading} = useSelector((state:any) => state.ResumenGlobal)
    
    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Resumen / Global',
        },
    ];

    function handleMenuClick(e: any) {
        setPageSize(e.key);
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="10">10</Menu.Item>
            <Menu.Item key="20">20</Menu.Item>
            <Menu.Item key="30">30</Menu.Item>
        </Menu>
    );

    const { Search } = Input;
    
    const onSearch = (value: any) => {
        const results: any = [];
        /*if (value) {
            empleados.forEach((user: any) => {
            const { nombre } = user;
            const superString = `${nombre}`;
            if (superString.includes(value)) {
                results.push(user);
            }
            });
            return setData(results);
        }
        if (value.trim() === '') {
            return setData(empleados);
        }*/
    };
    const filters = useSelector ( (state: any)=> state.Filters)
    const { date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
    const dispatch = useDispatch()
    const width = UseWidth()

    return (
        <div >
            <PageHeader title="GLOBAL" ghost={false}/>
            
            <div style={{padding: '1em', display: 'grid', gap: '1em'}}>
                {/* <div className="">
                    <Dropdown overlay={menu} overlayStyle={{}}>
                        <Button style={{height: '100%'}}>
                            {pageSize} <DownOutlined />
                        </Button>
                        </Dropdown>
                    <Search
                    placeholder="Buscar..."
                    onSearch={onSearch}
                    style={{ width: 200, marginLeft: 10 }}
                    />
                </div> */}
            
            <TableCobrado loading={loading} data={resumenData.tabla1}/>

            <ProgressBarWrapper loading={loading} data={resumenData.tabla2}/>

            <TableMonths loading={loading} data={resumenData.tabla3}/>

            <GraphsWrapper data={resumenData.tabla4}/>
            </div>

        </div>
    )
}
