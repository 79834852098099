// import { stat } from "fs";
import moment from "moment";
import globalConstants from "./constants";

const {
    SET_ASESOR,
    SET_CLINICA,
    SET_CONSULTOR,
    SET_ESPECIALIDAD,
    SET_DOCTOR,
    SET_CENTRO
} = globalConstants

const initialState = {
    especialidad: false,
    consultor: false,
    clinica: false,
    doctor: false,
    centro: false,
    asesor: false, // director / asesor son lo mismo
}

const filterReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_ASESOR:
            return {
                ...state,
                asesor: action.data
            }

        case SET_CLINICA:
            return {
                ...state,
                clinica: action.data
            }

        case SET_CONSULTOR:
            return {
                ...state,
                consultor: action.data
            }
        
        case SET_ESPECIALIDAD:
            return {
                ...state,
                especialidad: action.data
            }

        case SET_DOCTOR:
            return {
                ...state,
                doctor: action.data
            }

        case SET_CENTRO:
            return {
                ...state,
                centro: action.data
            }
        
        // case SET_RESUMEN_DOCTORES:
        //     return {
        //         ...state,
        //         resumenDoctoresData: action.data
        //     }
        
        // case SET_TIPOLOGIA_DOCTORES:
        //     return {
        //         ...state,
        //         tipologiaDoctoresData: action.data
        //     }

        // case SET_DERIVACIONES_DOCTORES:
        //     return {
        //         ...state,
        //         derivacionDoctoresData: action.data
        //     }
        
        // case SET_DERIVACIONES_EFECTIVAS_DOCTORES:
        //     return {
        //         ...state,
        //         derivacionesEfectivasDoctoresData: action.data
        //     }

        // case SET_TOTALIZADOR_DOCTORES:
        //     return {
        //         ...state,
        //         totalizadorDoctoresData: action.data
        //     }

        // case SET_ESTADISTICA_DOCTORES:
        //     return {
        //         ...state,
        //         estadisticaDoctoresData: action.data
        //     }
        //     // objetivo, cajaDia, prevision
        // case SET_OBJETIVO_PREVISIONES:
        //     return {
        //         ...state,
        //         objetivo: action.data.objetivo,
        //         cajaDia: action.data.cajaDia,
        //         prevision: action.data.prevision
        //     }
    default:
        return state
    }
}

export default filterReducer