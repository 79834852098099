import React from 'react'
import moment from 'moment'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import ButtonSection from '../../../../../components/button-sections'
import Form_euro from '../../../../../pages/dashboard/cumplimentacion/pages/parte_presupuestos/components/wrapper-forms/form_euro'

interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
    
    data: any
    users: any

    centrosLists:any

    handleChange: any
    handleChangeDate: any
    handleChangeNameValue: any
}

export default function index(props:Props) {
    const [form] = Form.useForm()
    const {
        isVisible, 
        onClose, 
        onSubmit, 
        
        data, 
        users,

        centrosLists,

        handleChange, 
        handleChangeNameValue, 
        handleChangeDate, 
        } = props

    const {Option} = Select
    const {TextArea} = Input

    const dateFormat = 'DD/MM/YYYY'

    const onSend = ()=>{
        form
        .validateFields()
        .then( (fields:any)=>{
            onSubmit(fields)
            // handleClose()
            form.resetFields()
            // onSubmit({...fields,DerivadoEspecialidades: fields.especialidadesArray, EspecialidadesPresupuestosAceptados: tempState, total})
            //onClose()
        })
    }

    return (
        <Modal
            title="Añadir objetivo"
            visible={isVisible}
            onCancel={onClose}
            width='70%'
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            footer={null}>
                <Form 
                    form={form} 
                    onFinish={onSend} 
                    autoComplete='off'>
                    <Row> 
                        <Col span={24}>
                            <Form.Item 
                                label='Centro' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}} 
                                name='centro' 
                                rules={[{required: true, message: 'Por favor seleccione un centro para poder seguir con el formulario'}]}>
                                <Select 
                                    onChange={(value:any)=>handleChangeNameValue('Centro', value)}
                                    value={data.Centro.clinica_nombre}
                                    placeholder='Selecciona una opción'
                                    style={{width:'100%'}}>
                                        <Option value=''>  </Option>
                                    {
                                        centrosLists && centrosLists.map( (item:any, id:any)=>(
                                            <Option key={id} value={item.id}>{item.clinica_nombre}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        
                    </Row>

                    <Row> 
                        <Col span={24}>
                            <Form.Item 
                            label='Usuario' 
                            labelCol={{span: 24}} 
                            wrapperCol={{ span: 24}}
                            name='user'
                            rules={[
                                {required: true, 
                                message: 'Por favor seleccione un usuario'}
                                ]}>
                            
                                {
                                    data.Centro.id === '' ?
                                        <Select 
                                            onChange={(value:any)=>handleChangeNameValue('Centro', value)}
                                            placeholder='No existen usuarios'
                                            style={{width:'100%'}}
                                            disabled>
                                            <Option value='No existen usuarios'>No existen usuarios</Option>
                                        </Select>
                                    :
                                    <Select 
                                        onChange={(value:any)=>handleChangeNameValue('user', value)}
                                        value={data.user.name}
                                        placeholder='Selecciona una opción'
                                        style={{width:'100%'}}
                                        >
                                        {
                                            users.map( (item:any, id:any)=>(
                                                <Option key={id} value={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                }
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    { data.Centro.id !== '' && 
                    <>
                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 48%'}}> 
                        <Col span={24}>
                            <Form.Item 
                                label='Fecha inicio' labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='fecha_inicio'
                                rules={[{ required: true, message:'Por favor seleccione una fecha'}]}>
                                
                                <DatePicker 
                                    format={dateFormat}
                                    value={data.fecha_inicio && moment(data.fecha_inicio)}
                                    onChange={(value:any)=>handleChangeDate('fecha_inicio', value)}
                                    style={{width:'100%'}}
                                    placeholder='dd/mm/aaaa'
                                    />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Fecha fin' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}} 
                                className="form-wrapper"
                                name='fecha_fin'
                                rules={[{required: true, message:'Por favor seleccione una fecha'}]}>
                                
                                <DatePicker 
                                    format={dateFormat}
                                    onChange={(value:any)=>handleChangeDate('fecha_fin', value)}
                                    value={data.fecha_fin && moment(data.fecha_fin)}
                                    style={{width:'100%'}}
                                    placeholder='dd/mm/aaaa'
                                    />
                                
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 49%'}}> 
                        <Col span={24} style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '95% 5%', alignItems: 'center'}}>
                            <Form.Item 
                                label='Objetivo' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='cantidad'
                                rules={[{ required: true, message: 'Por favor ingrese la cantidad' }]}>
                                <InputNumber 
                                    onChange={(value:any)=>handleChangeNameValue('cantidad', value)} 
                                    style={{width: '100%'}}
                                    className='Escribir cantidad'
                                    name='cantidad'
                                    min={0}
                                    value={data.cantidad}
                                    />
                            </Form.Item>
                            <span className="form-span">
                                    €
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label='OBSERVACIONES'  
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                style={{fontWeight: 'bold', fontSize: 20}}>
                                
                                <TextArea 
                                    name="observaciones" 
                                    onChange={handleChange}  
                                    value={data.observaciones}
                                />
                            </Form.Item>
                        </Col>    
                    </Row>
                    </>
                }
                    <ButtonSection 
                        onCancel={onClose}
                        />
                </Form>
        </Modal>
    )
}
