const paisConstants = {
    SET_LOADING_PAIS: 'SET_LOADING_PAIS',
    SET_PAIS: 'SET_PAIS',
    SET_EDITING_PAIS: 'SET_EDITING_PAIS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_PAIS: 'SET_SAVING_PAIS'
};
  
export default paisConstants;
  