import { stat } from "fs";
import clinicasConstants from "./constants";

const {
    SET_CLINICAS,
    SET_EDIT_ERROR,
    SET_EDITING_CLINICAS,
    SET_EDIT_OK,
    SET_LOADING_CLINICAS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_CLINICA
} = clinicasConstants

const initialState = {
    loading: false,
    data: [],
    home: [],
    editOk: false,
    selectedRowKeys: []
}

const ClinicasReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_CLINICAS:
            return {
                ...state,
                loading: action.isLoadingClinicas
            }
        
        case SET_CLINICAS:
            return{
                ...state,
                clinicas: action.clinicas
            }
        
        case SET_EDITING_CLINICAS:
            return {
                ...state,
                isEditingClinicas: action.isEditingClinicas
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_CLINICA:
            return {
                ...state
            }
    
    default:
        return state
    }
}

export default ClinicasReducer