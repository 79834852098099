import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Space,
  Checkbox,
} from "antd";
import exportIcon from "../../../assets/icon/export.svg";
import PlusOutlinedIcon from "../../../assets/icon/plusOutlined.svg";

import {
  fetchAll,
  editCentro,
  setSelectedRowKeys,
  deleteCentro,
  saveCentro,
  setEditError,
  setEditOk,
} from "../../../redux/centros/actions";

import styles from "./styles.module.scss";

import {
  DownloadOutlined,
  EditOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { CSVLink } from "react-csv";
import moment from "moment";

import CustomButton from "../../../components/custom-button";
import CentroModal from "./components/centrosModal";

function Centros() {
  const headers = [
    { label: "Nombre Clinica", key: "clinica_nombre" },
    { label: "Direccion", key: "direccion" },
    { label: "Codigo postal", key: "codigo_postal" },
    { label: "Telefono Principal", key: "numero_telefono" },
    { label: "Persona de contacto", key: "contacto_nombre" },
    { label: "Email de contacto", key: "email" },
  ];

  const columns = [
    {
      title: "Nombre clinica",
      dataIndex: "clinica_nombre",
      Key: "clinica_nombre",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Direccion",
      dataIndex: "direccion",
      key: "direccion",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Codigo postal",
      dataIndex: "codigo_postal",
      key: "codigo_postal",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Telefono principal",
      dataIndex: "numero_telefono",
      key: "numero_telefono",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Persona de contacto",
      dataIndex: "contacto_nombre",
      key: "contacto_nombre",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Email de contacto",
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Activo",
      dataIndex: "id",
      key: "activo",
      render: (text: any, record: any) => {
        const obj = {
          children: <Checkbox checked={record.activo} />,
          props: {
            onClick: () => showModalEdit(record.id),
          },
        };
        return obj;
      },
    },
    {
      title: "Eliminar",
      dataIndex: "id",
      key: "eliminar",
      render: (text: any, record: any) => {
        const obj = {
          children: (
            <DeleteOutlined style={{ fontSize: "20px", color: "#47464E" }} />
          ),
          props: {
            onClick: () => showModalDelete(record.id),
          },
        };
        return obj;
      },
    },
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [idSelectedDelete, setIdSelectedDelete]: any = useState();
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
    id: "",
    clinica_nombre: "",
    clinica_id: "",
    clinica_select_nombre: "",

    direccion: "",
    codigo_postal: "",

    pais: "Elige una opción",
    poblacion: "Elige una opción",

    consultor: "Elige una opción",
    consultor_nombre: "",

    numero_telefono: "",
    email: "",

    contacto_nombre: "",
    contacto_numero: "",

    especialidades_data: [],
    especialidades_nombre: "Elige una opción",

    observaciones: "",
  });
  const [selectedUserName, setSelectedUserName] = useState("");

  const { home } = useSelector((state: any) => state.centros);

  const handleDelete = () => {
    dispatch(deleteCentro(idSelectedDelete));
    setIsModalDeleteVisible(false);
  };

  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalDelete = (text: any) => {
    setIdSelectedDelete(text);
    setIsModalDeleteVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.nombre_clinica);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalWatchVisible(false);
    setIsModalEditVisible(false);
    setIsModalDeleteVisible(false);
    setIdSelectedEdit(0);
    setIdSelectedDelete(0);
    setUser({
      id: "",
      clinica_nombre: "",
      clinica_id: "",
      clinica_select_nombre: "",

      direccion: "",
      codigo_postal: "",

      pais: "Elige una opción",
      poblacion: "Elige una opción",

      consultor: "Elige una opción",
      consultor_nombre: "",

      numero_telefono: "",
      email: "",

      contacto_nombre: "",
      contacto_numero: "",

      especialidades_data: [],
      especialidades_nombre: "Elige una opción",

      observaciones: "",
    });
    setSuccess("");
  };
  const [success, setSuccess] = useState("");
  const { editError, editOk } = useSelector((state: any) => state.centros);

  const { Text } = Typography;

  const handleOk = (submittedData: any) => {
    setSuccess("Usuario actualizado correctamente");
    dispatch(editCentro(submittedData));
  };

  const handleOkDelete = () => {
    setIsModalDeleteVisible(false);
    dispatch(deleteCentro(idSelectedDelete));
    setIdSelectedDelete("");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit]);

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedDelete]);

  const { loading, centros, savingError } = useSelector(
    (state: any) => state.centros
  );

  const [data, setData] = useState(centros || [{}]);

  useEffect(() => {
    setData(centros);
  }, [centros]);

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      centros.forEach((user: any) => {
        const { clinica_nombre, email, phone } = user;
        const superString = `${clinica_nombre},${email},${phone}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === "") {
      return setData(centros);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "index",
      breadcrumbName: "Configuracion / Centros",
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.centros);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any) => {
    dispatch(saveCentro(form));
  };

  useEffect(() => {
    if (editError) {
      message.warning(editError, 2);
    }
    if (editOk) {
      message.success("Centro almacenado correctamente", 2);
    }
    /*if(editError === 'form'){
        message.warning(editErrorMessage, 2)
    }*/
    if (editError === "exist") {
      message.warning("El centro ya se encuentra registrado", 2);
    }
  }, [editError, editOk]);

  useEffect(() => {
    if (savingError === "error") {
      message.warning("Ha ocurrido un error", 2);
    }
    if (savingError === "saved") {
      message.success("Centro almacenado correctamente", 2);
      setIsModalAddVisible(false);
      setIsModalEditVisible(false);
    }
    if (savingError === "form") {
      message.warning("Hubo un error con los datos suministrados");
    }
    if (savingError === "exist") {
      message.warning("El centro ya se encuentra registrado", 2);
    }
  }, [savingError]);

  return (
    <div>
      <PageHeader title="Centros" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">
          <Dropdown overlay={menu}>
            <Button>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </div>
        <Space style={{ right: "0" }}>
          <CSVLink
            style={{
              color: "#245046",
              width: "113px",
              height: "39px",
              borderRadius: "2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
              border: "1px solid #245046",
              backgroundColor: "#FFFFFF",
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={"centros.csv"}
            separator={","}
          >
            <DownloadOutlined />
            Descargar
          </CSVLink>

          <CustomButton
            style={{
              backgroundColor: "#245046",
              width: "97px",
              height: "39px",
              color: "#ffffff",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
            }}
            onClick={() => setIsModalAddVisible(true)}
          >
            <img src={PlusOutlinedIcon} alt="" style={{ margin: "5px" }} />
            Añadir
          </CustomButton>

          <CentroModal
            type="add"
            isVisible={isModalAddVisible}
            prevData={false}
            onClose={() => setIsModalAddVisible(false)}
            onSubmit={onsubmit}
          />
        </Space>
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={data}
          scroll={{ x: true }}
          pagination={{ pageSize }}
          loading={loading}
        />

        <CentroModal
          type="edit"
          isVisible={isModalEditVisible}
          onClose={handleCancel}
          onSubmit={handleOk}
          prevData={user}
        />

        <Modal
          visible={isModalDeleteVisible}
          onCancel={handleCancel}
          onOk={handleDelete}
          title="Eliminar centro"
        >
          <h3>¿Estás seguro que deseas eliminar "{user.clinica_nombre}"?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Centros;
