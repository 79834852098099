import React from 'react'
import {
    PageHeader,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    message,
    Checkbox,
    Space
} from 'antd';

import formatToEUR from '../../../../../../helpers/formatToEuro'
import formatToPercentage from '../../../../../../helpers/formatPercentNo100';

interface Props {
    data?: any
    loading?: any
}

export default function TableMonths(props: Props) {
    const {Column, ColumnGroup} =  Table
    let {data, loading} = props

    const columns = [
        {
            title: '',
            dataIndex: 'nombre',
            key: 'nombre',
            width: '10em',
            render: (text: any, record: any) => {
                const obj = {
                    children: text,
                    props: {
                        style: {fontWeight: 'bold', color: '#245046'}
                    }
                }
                return obj
            }
        },{
            title: 'Enero',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.enero.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Febrero',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.febrero.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Marzo',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.marzo.actual),
            
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Abril',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.abril.actual),
            
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Mayo',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.mayo.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Junio',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.junio.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Julio',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.julio.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Agosto',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.agosto.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Septiembre',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.septiembre.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Octubre',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.octubre.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Noviembre',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.noviembre.actual),
                props: {
                 
                }
                }
                return obj
            }
        },{
            title: 'Diciembre',
            width: '5em',
            render: (text: any, record: any) => {
              const obj = {
                children: handleFormat(record.format, record.diciembre.actual),
                props: {
                 
                    }
                }
                return obj
            }
        }
    ]
    
    return (
        <div style={{overflow: 'scroll'}}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                loading={loading}
                dataSource={data}
                columns={columns}
                scroll={{ x: true }}
                pagination={false}
            />
        </div>
    )
}

function handleFormat (formatName: string, value: any){
    if(formatName === 'num'){
        return value
    }

    if(formatName === 'eur'){
        return formatToEUR(value)
    }

    if(formatName === 'perc'){
        return formatToPercentage(value)
    }
}