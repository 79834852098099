const globalConstants = {
    SET_OBJETIVO_PREVISIONES: 'SET_OBJETIVO_PREVISIONES',

    SET_RESUMEN_GLOBAL: 'SET_RESUMEN_GLOBAL',
    SET_RESUMEN_GLOBAL_LOADING: 'SET_RESUMEN_GLOBAL_LOADING',
    SET_DATE: 'SET_DATE',
    SET_OLD_DATES: 'SET_OLD_DATES',
    SET_RESUMEN_ASESORES: 'SET_RESUMEN_ASESORES',
    SET_INDICADOR_ASESORES: 'SET_INDICADOR_ASESORES',
    SET_ESTADISTICA_ASESORES: 'SET_ESTADISTICA_ASESORES',
    
    SET_RESUMEN_DOCTORES: 'SET_RESUMEN_DOCTORES',
    SET_TIPOLOGIA_DOCTORES: 'SET_TIPOLOGIA_DOCTORES',
    SET_DERIVACIONES_DOCTORES: 'SET_DERIVACIONES_DOCTORES',
    SET_DERIVACIONES_EFECTIVAS_DOCTORES: 'SET_DERIVACIONES_EFECTIVAS_DOCTORES',
    SET_TOTALIZADOR_DOCTORES: 'SET_TOTALIZADOR_DOCTORES',
    SET_ESTADISTICA_DOCTORES: 'SET_ESTADISTICA_DOCTORES',

    SET_NOTIFICATION: 'SET_NOTIFICATION'
}

export default globalConstants