import React, {useState, useEffect} from 'react'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import General from './wrapper-forms/general'
import OrtodonciaForm from './wrapper-forms/ortodoncia'
import ImplatologiaForm from './wrapper-forms/implatologia'
import { debounce } from 'lodash'
import { parse } from 'dotenv/types'

interface Props {
    data: any
    setData: any
    especialidadesLists: any
    eachRow: any
    spanIndex: any

    tempState:any
    setTempState: any

    wrapper:any
    setTotal: any
    handleClickSpan: any
    handleChange: any
    handleChangeNameValue: any
}

function Wrapper(props: Props) {
    const {
        data, 
        setData,
        especialidadesLists, 
        eachRow, 
        spanIndex, 
        
        wrapper,
        tempState,
        setTempState,
        setTotal,
        handleClickSpan, 
        handleChange
    } = props
    
    const [headerIndex, setHeaderIndex] = useState('')

    const handleClick = (id: any, item: any)=>{
        setHeaderIndex(item.nombre)
        handleClickSpan(id)
    }

    const handleChangeName = debounce( (inputName: any, formName:any, formValue:any)=>{ 
            let indexedArray = wrapper.filter((item:any)=>item.nombre !== inputName)
            let selectedOne = wrapper.filter((item:any)=>item.nombre === inputName)
            setTempState([...indexedArray, {...selectedOne[0], [formName]:formValue}])
    }, 200)
    
    
    const syncArray = ()=>{     
        if(data.EspecialidadesPresupuestosAceptados[0]){
            setData({...data, EspecialidadesPresupuestosAceptados: especialidadesLists})

            if(especialidadesLists.length > 0){
                setHeaderIndex(especialidadesLists[0].nombre)
            }
        }

        if(!data.EspecialidadesPresupuestosAceptados[0]){
            if(data.EspecialidadesPresupuestosAceptados[0]){
            setHeaderIndex(data.EspecialidadesPresupuestosAceptados[0].nombre)
            }
        }
    }

    useEffect(() => {
        syncArray()
    }, [especialidadesLists])

    useEffect(() => {
        syncArray()
    }, [])

    useEffect(() => {
        if(tempState.length > 0){
            let forReducePresupuesto = tempState.map((item:any)=>{
                return parseInt(!item.presupuesto ? 0 : item.presupuesto)
            });
            
            let forReduceAceptado = tempState.map((item:any)=>{
                return parseInt(!item.aceptado ? 0 : item.aceptado) 
            });
            let presupuestoReduced = forReducePresupuesto.reduce((presupuesto:any, item:any)=>
            parseInt(presupuesto) + item
            , 0)
            
            let aceptadoReduced =  forReduceAceptado.reduce((aceptado:any, item:any)=>
            parseInt(aceptado) + item
            , 0)
            
            setTotal(presupuestoReduced, aceptadoReduced, tempState)
        }
    }, [tempState]);

    return (
        <Col span={24}>
            <div className="header-modal">
            {
                especialidadesLists.map( (item:any, id: any)=>((
                    <span 
                        onClick={ ()=>handleClick(id, item)} 
                        key={id} 
                        style={{
                            height: '100%',
                            width: `${eachRow}%`, 
                            borderBottom: `3px solid ${spanIndex && spanIndex === id + 1 ? 'rgba(36, 80, 70, 1)' : 'rgba(151, 151, 151, 1)'}`,
                            color: spanIndex && spanIndex === id + 1 ? 'rgba(36, 80, 70, 1)' : 'rgba(151, 151, 151, 1)' 
                        }}
                        >{item.nombre}</span>
                )))
            }
            </div>

            {
                wrapper.map( (item:any, id:any)=>{
                    if(item.nombre === headerIndex && headerIndex === 'Ortodoncia') return (
                        <OrtodonciaForm 
                            key={id}
                            inputName={item.nombre}
                            handleChangeNameValue={handleChangeName}
                            data={item}
                        />
                    )

                    if(item.nombre === headerIndex && 
                    headerIndex === 'Implantología') return (
                        <ImplatologiaForm
                            key={id}
                            inputName={item.nombre}
                            handleChangeNameValue={handleChangeName}
                            handleChange={handleChange}
                            data={item}
                        />
                    )

                    if (item.nombre === headerIndex) return  (
                        <General 
                            key={id}
                            handleChangeNameValue={handleChangeName}
                            data={item}
                            inputName={item.nombre}
                        />
                    )
                })
            }
        </Col>
    )
}

export default Wrapper
