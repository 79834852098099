import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {debounce} from 'lodash'
import {Modal, Input, Select, message} from 'antd'

import MainForm from './Forms'
import ConsultorForm from './Forms/consultorForm'
import DirectorForm from './Forms/directorForm'

import {getUser} from '../../../../../redux/users/actions'

interface Props {
    type: string 
    isVisible: boolean
    onClose?: any
    onSubmit: any
    prevData: any
}

function AddClinicaModal(props: Props) {
    const {type, isVisible, onClose, onSubmit, prevData} = props

    const {Option} = Select
    const {TextArea} = Input
    
    const {clinicaLists, centrosLists} = useSelector( (state:any)=> state.admins)
    const {savingError, userArray} = useSelector( (state:any)=> state.users)
    const dispatch = useDispatch()
    
    const [clinicasArray, setClinicasArray] = useState(clinicaLists || [])
    
    useEffect(() => {
        setClinicasArray(clinicaLists)
        
    }, [clinicaLists]);
    
    useEffect(()=>{
        if(type === 'edit'){
            syncArray(userArray)
    }},[userArray])

    useEffect(() => {
        if(type !== "edit"){
            setData({
                username: '',
                role: '',
                
                clinica_data: [],
                clinica_name:'',

                name: '',
                lastname: '',
                telefono: '',
                dni:'',

                email: '',
                password: '',

                centros_id: '',
                centro_name: '',

                fecha_alta: null,
                fecha_baja: '',
                observaciones:  '',
                activo: false
            })
        }
    }, [type]);

    useEffect(() => {
        setData(prevData)
        if(type==="edit"){  
            dispatch(getUser(prevData.id))      
            syncArray(userArray)        
        }
    }, [prevData, type]);

    const [data, setData] = useState({
        username: '',
        role: '',
        
        clinica_data: [],
        clinica_name:'',

        name: '',
        lastname: '',
        telefono: '',
        dni:'',

        email: '',
        password: '',

        centros_id: '',
        centro_name: '',

        fecha_alta: null,
        fecha_baja: '',

        observaciones:  '',
        activo: false
    })
    const syncArray = (oldArray: any)=>{
        let newArray:any = []
        if(oldArray && oldArray.clinicas){
        for (var i = 0; i < clinicaLists.length; i++) {
            var igual=false;
                for (var j = 0; j < oldArray.clinicas.length && !igual; j++) {
                    if(clinicaLists[i]['id'] == oldArray.clinicas[j]['id'] && 
                    clinicaLists[i]['nombre'] == oldArray.clinicas[j]['nombre']) 
                            igual=true;
                }
            if(!igual) newArray.push(clinicaLists[i]);
        }
        setClinicasArray(newArray)
        setData({...oldArray,
            centro_name: oldArray.centros ? oldArray.centros.clinica_nombre : 'Elige una opcion',
            clinica_data: oldArray.clinicas ? oldArray.clinicas : [],
            clinica_name: oldArray.clinicas[0] ? oldArray.clinicas[0].nombre_comercial: 'Elige una opcion'})
        }
    }

    const handleChange=(event: any)=>{
        let {name, value, type, checked} = event?.target
        if(type === 'checkbox'){
            setData({...data, [name]:checked})
        }
        
        if(type !== 'checkbox'){
            setData({...data, [name]:value})
        }
    }

    const handleChangeSelect = (name: string, value: any)=>{
        if(name === 'clinica_id'){
            let newValue = clinicaLists.filter( (item:any)=> item.id === value)
            setData({...data, clinica_data: newValue, clinica_name: newValue.nombre_comercial})
        }

        if(name === 'centros_id'){
            console.log(centrosLists)
            setData({...data, [name]: value})
        }

        if(name === 'role'){
            setData({...data, [name]:value})
        }

        if(name === 'clinica_array'){
            if(clinicasArray.length >= 0){
                setClinicasArray(clinicasArray.filter( (clinica:any)=>{
                    return clinica.id !== value
                }))
            }
            
            value = clinicaLists.find( (clinica:any)=>{
                return clinica.id == value
            })
            let wrappedClinica:any = []
            
            if(data.clinica_data){
                wrappedClinica = [...data.clinica_data, value]
            }

            if(!data.clinica_data){
                wrappedClinica = [value]
            }
            let length = wrappedClinica.length
            setData({...data, clinica_data: wrappedClinica, clinica_name: wrappedClinica[length-1].nombre_comercial})
        }
    }
    
    const handleChangeDate = (e:any, name: string)=>{
        if(e){
        let date = e.format()
        let newDate = date
        setData({...data, [name]:newDate})

        }else{
            setData({...data, [name]: ''})
        }    
    }
    
    const handleSubmit = ()=>{
            onSubmit(data)
            handleClose()
    }

        
    const handleClose = ()=>{
        onClose()
        setData({
            username: '',
            role: 'Admin',
            
            clinica_data: [],
            clinica_name:'',

            name: '',
            lastname: '',
            telefono: '',
            dni:'',

            email: '',
            password: '',

            centros_id: '',
            centro_name: '',

            fecha_alta: null,
            fecha_baja: '',
            observaciones:  '',
            activo: false
        })
    }

    const handleCloseTag =(tag: any) =>{
        let {id} = tag

        setClinicasArray([...clinicasArray, tag])

        setData({...data, clinica_data: data.clinica_data.filter( (clinica:any)=>{
            return clinica.id != id
        })})
    }

    return (
        <Modal
            title="Añadir usuario"
            visible={isVisible}
            onCancel={handleClose}
            onOk={handleSubmit}
            width={1020}
            footer={null}
            //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid', transform: '1s'}}
            style={{borderRadius:'2px', top: 20}}
            okText="Guardar"
            cancelText="Cancelar"
            >
                {
                    data.role == "" || !data.role
                    && <MainForm 
                    type={type}
                    data={data}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeDate={handleChangeDate}
                    clinicaLists={clinicaLists}
                    /> 
                }
                {
                    data.role == 'Admin' 
                    && <MainForm 
                    type={type}
                    data={data}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeDate={handleChangeDate}
                    clinicaLists={clinicaLists}
                    /> 
                }
                {
                    data.role == 'Gerente' 
                    && <MainForm 
                    type={type}
                    data={data}
                    onSubmit={handleSubmit}
                    handleChange={handleChange}
                    onClose={onClose}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeDate={handleChangeDate}
                    clinicaLists={clinicaLists}
                    /> 
                }
                {
                    data.role == 'Consultor' 
                    && <ConsultorForm 
                    type={type}
                    data={data}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeDate={handleChangeDate}
                    clinicaLists={clinicasArray}
                    handleCloseTag={handleCloseTag}
                    /> 
                }
                {
                    data.role == 'Director' 
                    && <DirectorForm 
                    type={type}
                    data={data}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    centrosLists={centrosLists}
                    handleChange={handleChange}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeDate={handleChangeDate}
                    clinicaLists={clinicaLists}
                    /> 
                }
        </Modal>
    )
}

export default AddClinicaModal


