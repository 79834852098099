import api from "../../helpers/api";
import { identity } from "lodash";

const partePresupuestoServices = {
    fetchAll: (
            filters: any,
            dates: any
        )=>{
        return api.post('/admin/list/parte_presupuesto',{
            filters, dates
        })
    },
    fetch: ()=>{
        return api.get('/admin/list/parte_presupuesto/users')
    },
    save: (
        nombre_apellidos: any,
        numero_telefono: any,
        fecha_visita: any,
        
        presupuesto: any,
        aceptado: any,

        TiposPresupuesto: any,

        asesor: any,

        doctor: any,

        total: any,
        entidad_financiera: any,
        EspecialidadesPresupuestosAceptados: any,

        derivable_periodoncia: any,
        derivable_estetica: any,
        tiene_ausencias: any,
        numero_ausencias: any,
        apinamiento_oclusion: any,
        derivado: any,
        DerivadoEspecialidades: any,

        fecha_etto: any,
        asistencia_paciente: any,
        
        tipo_pago_id: any,

        observaciones: any,
        cantidad_financiada: any,
        costes_financieros: any,

        total_financiacion: any,
    )=>{

        return api.post('/admin/save/parte_presupuesto',{
            nombre_apellidos,
            numero_telefono,
            fecha_visita,
            
            presupuesto,
            aceptado,
    
            TiposPresupuesto,
    
            asesor,
    
            doctor,
    
            total,
            entidad_financiera,
            EspecialidadesPresupuestosAceptados,
    
            derivable_periodoncia,
            derivable_estetica,
            tiene_ausencias,
            numero_ausencias,
            apinamiento_oclusion,
            derivado,
            DerivadoEspecialidades,
    
            fecha_etto,
            asistencia_paciente,
            
            tipo_pago_id,
    
            observaciones,
            cantidad_financiada,
            costes_financieros,
    
            total_financiacion,
        })
    },
    edit: (
        id: any,
        nombre_apellidos: any,
        numero_telefono: any,
        fecha_visita: any,
        
        presupuesto: any,
        aceptado: any,

        TiposPresupuesto: any,

        asesor: any,

        doctor: any,

        total: any,
        entidad_financiera: any,
        EspecialidadesPresupuestosAceptados: any,

        derivable_periodoncia: any,
        derivable_estetica: any,
        tiene_ausencias: any,
        numero_ausencias: any,
        apinamiento_oclusion: any,
        derivado: any,
        DerivadoEspecialidades: any,

        fecha_etto: any,
        asistencia_paciente: any,
        
        tipo_pago_id: any,

        observaciones: any,
        cantidad_financiada: any,
        costes_financieros: any,

        total_financiacion: any,
    )=>{
        return api.put('/admin/update/parte_presupuesto/'+id,{
            nombre_apellidos,
            numero_telefono,
            fecha_visita,
            
            presupuesto,
            aceptado,
    
            TiposPresupuesto,
    
            asesor,
    
            doctor,
    
            total,
            entidad_financiera,

            EspecialidadesPresupuestosAceptados,
    
            derivable_periodoncia,
            derivable_estetica,
            tiene_ausencias,
            numero_ausencias,
            apinamiento_oclusion,
            derivado,
            DerivadoEspecialidades,
    
            fecha_etto,
            asistencia_paciente,
            
            tipo_pago_id,
    
            observaciones,
            cantidad_financiada,
            costes_financieros,
    
            total_financiacion,
        })
    },
    delete: (
        id: any
    )=>{
        return api.get('/admin/destroy/parte_presupuesto/'+id)
    }
}

export default partePresupuestoServices