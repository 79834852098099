const objetivoConstants = {
    SET_LOADING_OBJETIVOS: 'SET_LOADING_OBJETIVOS',
    SET_OBJETIVOS: 'SET_OBJETIVOS',
    SET_EDITING_OBJETIVOS: 'SET_EDITING_OBJETIVOS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_OBJETIVOS: 'SET_SAVING_OBJETIVOS',
    SET_FETCHED_USER: 'SET_FETCHED_USER'
  };
  
  export default objetivoConstants;
  