import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PercentageNumber from './percentageNumber'
import styles from './styles.module.scss'

import {
    getEstadisticasAsesores
} from '../../../../../../redux/global/actions';

export default function Estadisticas() {
    const dispatch = useDispatch()
    const filters = useSelector ( (state: any)=> state.Filters)
    const {date, lastDates, estadisticaAsesoresData} = useSelector((state: any) => state.ResumenGlobal);

    useEffect(() => {
        dispatch(getEstadisticasAsesores())
    }, []);
    
    useEffect(() => {
        dispatch(getEstadisticasAsesores())
    }, [date, lastDates, filters]);

    return (
        <div className={styles.wrapper}>
            <PercentageNumber 
                label='CIERRE DE CITA'
                value={estadisticaAsesoresData.cierreCita}
            />
            <PercentageNumber 
                label='RATIO DE ASISTENCIA'
                value={estadisticaAsesoresData.ratioAsistencia}
            />
            <PercentageNumber 
                label='VENTA MEDIA'
                value={estadisticaAsesoresData.ventaMedia}
            />
            <PercentageNumber 
                label='PRESUPUESTO MEDIO'
                value={estadisticaAsesoresData.presupuestoMedio}
            />
            <PercentageNumber 
                label='ACEPTACIÓN PERSONAS'
                value={estadisticaAsesoresData.aceptacionPersonas}
            />
            <PercentageNumber 
                label='ACEPTACIÓN EUROS'
                value={estadisticaAsesoresData.aceptacionEuros}
            />
            <PercentageNumber 
                label='FINANCIADO'
                value={estadisticaAsesoresData.financiado}
            />
        </div>
    )
}
