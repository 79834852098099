import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import FormEuro from './form_euro'

interface Props {
    handleChangeNameValue: any
    data: any
    inputName: any
}

function OrtodonciaForm(props: Props) {
    const {
        handleChangeNameValue,
        inputName,
        data
    } = props

    const {tiposOrtodonciasLists, doctorLists} = useSelector( (state:any)=>state.admins)

    const handleChange = (inputCamp:any, value:any) => {
        // if(inputCamp === 'tipo_ortodoncia'){
        //     let ortodoncia = tiposOrtodonciasLists.filter( (item:any)=>item.id === value)
        //     handleChangeNameValue(inputName, inputCamp, ortodoncia[0])
        // }   

        // if(inputCamp === 'doctor'){
        //     let doctor = doctorLists.filter( (item:any)=>item.id === value)
        //     handleChangeNameValue(inputName, inputCamp, doctor[0])
        // }

        // if(inputCamp !== 'tipo_ortodoncia' && inputCamp !== 'doctor') 
        
        
        handleChangeNameValue(inputName, inputCamp, value)
    }

    const {Option} = Select

    return (
        <div className="wrapper">
            <Row style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '50% 50%'}}>

                <FormEuro 
                    label='Presupuesto'
                    placeholder='Escribir cantidad'
                    value={data.presupuesto}
                    name='presupuesto'
                    handleChange={(value:any)=>handleChange('presupuesto', value)}
                />

                <FormEuro 
                    label='Aceptado'
                    placeholder='Escribir cantidad'
                    value={data.aceptado}
                    name='aceptado'
                    handleChange={(value:any)=>handleChange('aceptado', value)}
                    />

                <Col span={24}>
                    <Form.Item label='Doctor' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            onChange={(value:any)=>handleChange('empleado_id', value)}
                            value={data.empleado_id}
                            style={{width: '100%'}} >
                            {
                                doctorLists.map( (item:any, id:any)=>(
                                    <Option key={id} value={item.id}>{item.nombre} {item.apellidos}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label='Tipo de ortodoncia' name='tipo_ortodoncia' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            onChange={(value:any)=>handleChange('tipo_ortodoncia', value)} 
                            style={{width: '100%'}}
                            value={data.tipo_ortodoncia ? data.tipo_ortodoncia.nombre : ''}>
                            {
                                tiposOrtodonciasLists.map( (item:any, id:any)=>(
                                    <Option key={id} value={item.id}>{item.nombre}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
    </div>
    )
}

export default OrtodonciaForm
