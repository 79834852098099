const cajaDiaConstants = {
    SET_LOADING: 'SET_LOADING',
    SET_PPTOS_TIPOLOGIA: 'SET_PPTOS_TIPOLOGIA',
    SET_PPTOS_TIPOS_PAGOS: 'SET_PPTOS_TIPOS_PAGOS',
    SET_COBROS: 'SET_COBROS',
    SET_ESPECIALIDAD_TABLE: 'SET_ESPECIALIDAD_TABLE',
    SET_SELECTED: 'SET_SELECTED'
  };
  
  export default cajaDiaConstants;
  