import partePresupuestosConstants from "./constants"

import PartePresupuesto from './services'
import { getResumen } from "../global/actions"
import store from "../rootStore"
import { Console } from "console"
import { setLoadingObjetivos } from "../objetivos/actions"

const {
    SET_LOADING_PARTE_PRESUPUESTOS, 
    SET_PARTE_PRESUPUESTOS,
    SET_SELECTED_ROW_KEYS,
    SET_LOADING_PROFILE,
    SET_PROFILE,
    SET_USERS_LISTS_PRESUPUESTOS
} = partePresupuestosConstants

const setPresupuestos = (partePresupuesto:any)=>({
    type: SET_PARTE_PRESUPUESTOS,
    partePresupuesto
})

const setLoadingPresupuestos = (loading:boolean)=>({
    type: SET_LOADING_PARTE_PRESUPUESTOS,
    loading
})

export const fetchAll = () => {
    return (dispatch: any) => {
        let {ResumenGlobal, Filters} = store.getState()
        let anyHandler:any = ResumenGlobal
        let {date, lastDates, etto} = anyHandler
        dispatch(setLoadingPresupuestos(true));
        PartePresupuesto.fetchAll(Filters, {date, last: lastDates, etto: etto}).then((res) => {
            dispatch(setLoadingPresupuestos(false));
            
            return dispatch(setPresupuestos(res.data.response));
        });
    };
};

const setUserLists = (data: any)=>({
    type: SET_USERS_LISTS_PRESUPUESTOS,
    data
})

export const fetchUsersLists = () =>{
    return (dispatch: any) => {
        PartePresupuesto.fetch()
        .then( (res: any)=>{
            dispatch(setUserLists(res.data))
        })
    }
}

const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
        return dispatch(selecteddRowKeys(selectedRowKeys));
    };
};


export const setProfile = (profile: any) =>({
    type: SET_PROFILE,
    profile
})

export const savePartePresupuestos = (data: any) =>{
    return (dispatch:any) => {
        dispatch(setLoadingObjetivos(true))
        PartePresupuesto.save(
                data.nombre_apellidos,
                data.numero_telefono,
                data.fecha_visita,
                
                data.presupuesto,
                data.aceptado,
    
                data.TiposPresupuesto,
    
                data.asesor,
    
                data.doctor,
    
                data.total,
                data.entidad_financiera_id,
    
                data.EspecialidadesPresupuestosAceptados,
    
                data.derivable_periodoncia,
                data.derivable_estetica,
                data.tiene_ausencias,
                data.numero_ausencias,
                data.apinamiento_oclusion,
                data.derivado,
                data.DerivadoEspecialidades,
    
                data.fecha_etto,
                data.asistencia_paciente,
                
                data.tipo_pago_id,
    
                data.observaciones,
                data.cantidad_financiada,
                data.costes_financieros,
    
                data.total_financiacion,
        ).then( ()=>{
        dispatch(setLoadingObjetivos(false))
        dispatch(fetchAll())
        let {ResumenGlobal, Filters} = store.getState()
        let anyHandler:any = ResumenGlobal
        let {date, lastDates} = anyHandler
        dispatch(getResumen({...date, last: lastDates, filters: Filters}))
        })
    }
}

export const edit = (data: any) =>{
    return (dispatch:any) => {
        dispatch(setLoadingObjetivos(true))
        PartePresupuesto.edit(
                data.id,
                data.nombre_apellidos,
                data.numero_telefono,
                data.fecha_visita,
                
                data.presupuesto,
                data.aceptado,
    
                data.TiposPresupuesto,
    
                data.asesor,
    
                data.doctor,
    
                data.total,
                data.entidad_financiera_id,

                data.EspecialidadesPresupuestosAceptados,
    
                data.derivable_periodoncia,
                data.derivable_estetica,
                data.tiene_ausencias,
                data.numero_ausencias,
                data.apinamiento_oclusion,
                data.derivado,
                data.DerivadoEspecialidades,
    
                data.fecha_etto,
                data.asistencia_paciente,
                
                data.tipo_pago_id,
    
                data.observaciones,
                data.cantidad_financiada,
                data.costes_financieros,
    
                data.total_financiacion,
        ).then( ()=>{
        dispatch(setLoadingObjetivos(false))
        dispatch(fetchAll())
        let {ResumenGlobal, Filters} = store.getState()
        let anyHandler:any = ResumenGlobal
        let {date, lastDates} = anyHandler
        dispatch(getResumen({...date, last: lastDates, filters: Filters}))    
        })
    }
}


export const deletePresupuesto = (data: any) => {
    return (dispatch: any) =>{
    PartePresupuesto.delete(data)
    .then(res => {
        let {message} = res.data

        if(message === "deleted succesfully") {
        dispatch(fetchAll())
        let {ResumenGlobal, Filters} = store.getState()
        let anyHandler:any = ResumenGlobal
        let {date, lastDates} = anyHandler
        dispatch(getResumen({...date, last: lastDates, filters: Filters}))
        // dispatch(getObjetivoPrevision())
        //   dispatch(fetchObjetivos())
        }
    })
    .catch(e => console.log(e))
    }
}