// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body {\n  background: #FFFFFF; }\n\n.styles_login_form_container__1kycZ {\n  margin: 0 auto !important;\n  margin-top: 150px !important;\n  max-width: 300px;\n  text-align: center; }\n\n.styles_login_form_logo__36uhQ {\n  margin-bottom: 40px;\n  width: 252px;\n  height: 87px; }\n\n.styles_login_form_forgot__22AD- {\n  float: left;\n  color: #199479; }\n\n.styles_space__1Ota1 {\n  margin-bottom: 10px !important; }\n", ""]);
// Exports
exports.locals = {
	"login_form_container": "styles_login_form_container__1kycZ",
	"login_form_logo": "styles_login_form_logo__36uhQ",
	"login_form_forgot": "styles_login_form_forgot__22AD-",
	"space": "styles_space__1Ota1"
};
module.exports = exports;
