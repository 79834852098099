import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CumplimentacionModal from './cumplimentacionModal'
import {setEspecialidadesArray} from './function'

import { identity, wrap } from 'lodash'

interface Props {
    isModalVisible: boolean
    onCancel: any
    onSubmit: any
    formData: any
}


function Index(props: Props) {
    const {isModalVisible, onCancel, onSubmit, formData} = props
    const dispatch = useDispatch()

    let {profile, isLoading} = useSelector( (state:any)=>state.PartePresupuesto)
    let {especialidadesLists} = useSelector((state:any) => state.admins);

    const [data, setData] = useState({
        id: false,
        nombre_apellidos: '',
        numero_telefono: '',
        fecha_visita: null,

        TiposPresupuesto: null,

        asesor: null,

        doctor: null,

        entidad_financiera: null,

        total_aceptado: 0,
        total_presupuestado: 0,
        total:{
            presupuestado: 0,
            aceptado: 0
        },
        EspecialidadesPresupuestosAceptados: [{}],
        SelectedEspecialidadesAceptados: [],

        derivable_periodoncia: '',
        derivable_estetica: '',
        tiene_ausencias: '',
        numero_ausencias: '',
        apinamiento_oclusion: '',
        derivado: '',
        DerivadoEspecialidades: null,

        fecha_etto: null,
        asistencia_paciente: '',
        
        TiposPagos: null,

        observaciones: '',
        cantidad_financiada: '',
        costes_financieros: '',

        total_financiacion: 0
    })

    useEffect(() => {
        if(formData){
            syncData(formData)
        }
    }, [formData])

    useEffect(() => {
        setData({...data, EspecialidadesPresupuestosAceptados: setEspecialidadesArray(data, especialidadesLists)})
    }, [especialidadesLists])

    const [fetchedEspecialidadesAceptados, setFetchedEspecialidadesAceptados] = useState(null)

    const syncData = (newData: any)=>{
        let array:any = []
        array = setEspecialidadesArray(newData, especialidadesLists)
        // console.log(newData)
        setData({
            ...newData,
            id: newData.main_id,
            asesor: newData.user_id,
            observaciones: newData.parte_presupuesto_observaciones,
            TiposPresupuesto: newData.tipos_presupuesto_id,
            tipo_pago_id: newData.tipo_pago_id,
            doctor: newData.empleado_id,
            DerivadoEspecialidades: newData.derivadoEspecialidades.length > 0 ?   newData.derivadoEspecialidades : null,
            SelectedEspecialidadesAceptados: newData.especialidadesPresupuestoAceptado,
            EspecialidadesPresupuestosAceptados: newData.especialidadesPresupuestoAceptado.length > 0 ? newData.especialidadesPresupuestoAceptado : setEspecialidadesArray(newData, especialidadesLists)
        })
        if(newData.especialidadesPresupuestoAceptado.length > 0){
            setFetchedEspecialidadesAceptados(newData.especialidadesPresupuestoAceptado)
        } else {
            setFetchedEspecialidadesAceptados(array)
        }
    }
    
    const handleChange=(event: any)=>{
        if(event.target){
            let {name, value} = event?.target
            setData({...data, [name]:value})
        }
    }

    const handleChangeNameValue = (name:any, value:any)=>{
        if(name === 'numero_telefono') setData({...data, [name]:value})

        if(name === 'tipos_presupuesto') {
            setData(
                {...data, TiposPresupuesto: value[0].id})
        }

        if(name === 'tipos_pagos'){
            setData(
                {...data,
                    TiposPagos: value[0].id})
        }

        if(name === 'doctor'){
                setData({
                ...data,
                doctor: value
            })
        }

        if(name === 'director'){
            setData({
                ...data,
                asesor: value[0].id
            })
        }

        if(name === 'entidad_financiera'){
            setData({
                ...data,
                entidad_financiera: value[0].id
            })
        }

        if(name === 'cantidad_financiada'){
            setData({...data, [name]:value, total_financiacion: value})
        }

        if(name === 'costes_financieros'){
            setData({...data, [name]:value, total_financiacion: value})
        }
        
        if(
            name === 'derivable_periodoncia' ||
            name === 'derivable_estetica' ||
            name === 'apinamiento_oclusion' ||
            name === 'asistencia_paciente' ||
            name === 'presupuesto' ||
            name === 'DerivadoEspecialidades' ||
            name === 'derivado' ||
            name === 'tiene_ausencias' ||
            name === 'numero_ausencias'
        ){
            setData({...data, [name]:value})
        }
    }

    const setTotal = (presupuesto: any, aceptado: any, wrapperData:any)=>{
        setData({...data, 
            EspecialidadesPresupuestosAceptados: wrapperData,
            total: {
                presupuestado: presupuesto, 
                aceptado: parseInt(aceptado)
                }
            })
    }

    const handleChangeDate = (e:any, name: string)=>{
        if(e){
        let date = e.format()
        let newDate = date
        setData({...data, [name]:newDate})

        }else{
            setData({...data, [name]: ''})
        }    
    }

    const sortTotalFinanciacion = (cantidad_financiada: any, costes_financieros: any)=>{
        return cantidad_financiada - (cantidad_financiada * (costes_financieros / 100))
    }
    
    useEffect(() => {
        let {cantidad_financiada, costes_financieros} = data
        setData({...data, total_financiacion: sortTotalFinanciacion(cantidad_financiada, costes_financieros)})
    }, [data.cantidad_financiada, data.costes_financieros])

    const handleSubmit = (fields: any)=>{
        onSubmit({id: data.id, ...fields})
        handleClose()
    }

    const resetData = ()=>{
        setData({
            ...data,
            fecha_visita: null,
            fecha_etto: null,
        })
    }

    const handleClose = ()=>{
        resetData()
        onCancel()
    }


    if(isLoading) return (
        <div>
            loading
        </div>
    ) 

    return (
        <CumplimentacionModal 
            especialidadesArrayFetched={fetchedEspecialidadesAceptados}
            isVisible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            data={data}
            setData={setData}
            // isDerivada={data.derivado}
            
            setTotal={setTotal}
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleChangeNameValue={handleChangeNameValue}
        />
    )
}

export default Index
