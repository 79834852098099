import { act } from 'react-dom/test-utils';
import usersConstants from './constants';

const {
  SET_LOADING_USERS,
  SET_USERS,
  SET_HOME,
  CHECKING_HOME,
  SET_EDITING_USER,
  SET_EDIT_ERROR,
  SET_EDIT_OK,
  SET_SELECTED_ROW_KEYS,
  SET_SAVING_ERROR,
  SET_USER,
  SET_UPDATED,
  SET_USER_ARRAY
} = usersConstants;

const initialState = {
  loading: false,
  data: [],
  home: [],
  editOk: false,
  selectedRowKeys: [],

  userArray: [],
  updated: false,
  savingError: ''
};

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_USERS: {
      return {
        ...state,
        loading: action.isLoadingFeedbacks,
      };
    }
    case SET_USERS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case SET_HOME: {
      return {
        ...state,
        home: action.home,
      };
    }
    case CHECKING_HOME: {
      return {
        ...state,
        checkingHome: action.isChecking,
      };
    }
    case SET_EDITING_USER: {
      return {
        ...state,
        isEditingUser: action.isEditingUser,
      };
    }
    case SET_EDIT_ERROR: {
      return {
        ...state,
        editError: action.editError,
      };
    }
    case SET_EDIT_OK: {
      return {
        ...state,
        editOk: action.editOk,
      };
    }
    case SET_SELECTED_ROW_KEYS: {
      return {
        ...state,
        selectedRowKeys: action.selectedRowKeys,
      };
    }
    case SET_SAVING_ERROR:{
      return {
        ...state,
        savingError: action.savingError
      }
    }
    case SET_USER_ARRAY: {
      return {
        ...state,
        userArray: action.userArray
      }
    }
    case SET_UPDATED: {
      return {
        ...state,
        updated: action.updated
      }
    }
    default: {
      return state;
    }
  }
};

export default usersReducer;
