import api from '../../helpers/api'

const TiposOrtodonciasServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/tipos_ortodoncias')
    },
    save: (
        nombre: string
    )=>{
        return api.post('/admin/save/tipos_ortodoncias',{
            nombre
        }).catch(e => console.log(e))
    },
    destroy: (
        id:string,
        )=> {
            return api.get('/admin/destroy/tipos_ortodoncias/'+id)
    }
}

export default TiposOrtodonciasServices