import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAllAdmins } from '../../../redux/admins/actions';
import AdminModalForm from '../../../components/AdminModalForm';
import { EditOutlined } from '@ant-design/icons';

import { editAdmin, newAdmin } from '../../../redux/admins/actions';

function Users() {
  const dispatch = useDispatch();

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllAdmins());
  }, [dispatch]);

  const { loading, data, creating } = useSelector((state: any) => state.admins);
  const { user } = useSelector((state: any) => state.auth);
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.super_admin === true) {
        setSuperAdmin(true);
      }
    }
  });

  const createItem = (data: any, callback: any) => {
    dispatch(newAdmin(data, callback));
  };

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelectedEdit, setIdSelectedEdit]: any = useState();
  const [form] = Form.useForm();

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const [fields, setFields]: any = useState([
    {
      name: ['email'],
      value: '',
    },
    {
      name: ['name'],
      value: '',
    },
  ]);

  useEffect(() => {
    if (idSelectedEdit) {
      const info: any = data.find((e: any) => e.id === idSelectedEdit);
      setFields([
        {
          name: ['email'],
          value: info.email,
        },
        {
          name: ['name'],
          value: info.name,
        },
      ]);
    }
  }, [idSelectedEdit]);

  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setSuccess('');
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(editAdmin(idSelectedEdit, values));
        setSuccess('Cambio realizado con exito!');
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const routes = [
    {
      path: '',
      breadcrumbName: 'Administradores',
    },
  ];

  return (
    <div>
      {superAdmin && (
        <PageHeader
          title="Administradores"
          ghost={false}
          breadcrumb={{ routes }}
          extra={[
            <AdminModalForm
              openButtonText="Añadir"
              modalTitle="Crear cuenta de administrador"
              successMessage="Administrador creado correctamente"
              onConfirm={createItem}
              confirmLoading={creating}
            />,
          ]}
        />
      )}

      <div className="content_container">
        {!superAdmin && (
          <Table
            onChange={data}
            dataSource={data}
            loading={loading}
            rowKey="id"
            columns={[
              {
                title: 'Administradores',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
            ]}
          />
        )}
        {superAdmin && (
          <div>
            <Table
              dataSource={data}
              loading={loading}
              rowKey="id"
              columns={[
                {
                  title: 'Administradores',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Email',
                  dataIndex: 'email',
                  key: 'email',
                },
                {
                  title: 'Editar',
                  dataIndex: 'id',
                  key: 'editar',
                  render: (text: any, record: any) => (
                    <>
                      <EditOutlined
                        style={{ fontSize: '20px', color: '#199479' }}
                        onClick={() => showModalEdit(text)}
                      />
                    </>
                  ),
                },
              ]}
            />
            <Modal
              title="Edit"
              visible={isModalEditVisible}
              onCancel={handleCancel}
              onOk={handleOk}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancelar
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                >
                  Crear
                </Button>,
              ]}
            >
              <Form layout="vertical" form={form} fields={fields}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { type: 'email', message: 'Introduce un email válido' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Contraseña" name="password">
                  <Input type="password" />
                </Form.Item>

                <Form.Item label="Nombre" name="name">
                  <Input />
                </Form.Item>
                <Text type="success">{success}</Text>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default Users;
