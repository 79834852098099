import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {Table} from 'antd'
import formatToEUR from '../../../../../../helpers/formatToEuro'
import formatToPercent from '../../../../../../helpers/formatToPercent'

import {
    getTotalizadorDoctores
} from '../../../../../../redux/global/actions';


interface Props {
    pageSize: any
    data: any
    loading: any
}

export default function ResumenAsesores(props: Props) {
    let {pageSize, loading, data} = props

    const columns = [
      {
        title: 'Clinica',
        dataIndex: 'clinica',
        Key:'clinica'
      },{
        title: 'Doctor',
        dataIndex: 'user',
        Key:'user'
      },{
        title: 'Presupuestos',
        dataIndex: 'presupuestos',
        Key:'presupuestos'
      },{
        title: 'Aceptados',
        dataIndex: 'aceptados',
        Key:'aceptados'
      },{
        title: 'Presupuestado',
        dataIndex: 'presupuestado',
        Key:'presupuestado',
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.presupuestado)
            }
          </>
        )
      },{
        title: 'Aceptado',
        dataIndex: 'aceptado',
        Key:'aceptado',
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.aceptado)
            }
          </>
        )
      },{
        title: 'Presupuesto medio',
        dataIndex: 'presupuestoMedio',
        Key:'presupuestoMedio',
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.presupuestoMedio)
            }
          </>
        )
      },{
        title: 'Aceptado medio',
        dataIndex: 'aceptadoMedio',
        Key:'aceptadoMedio',
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.aceptadoMedio)
            }
          </>
        )
      }
    ];

  const filters = useSelector ( (state: any)=> state.Filters)
  const { date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  const dispatch = useDispatch()  

  useEffect( ()=>{
    dispatch(getTotalizadorDoctores(({...date, last: lastDates, filters})))
  },[date, lastDates, filters])

  useEffect( ()=>{
    dispatch(getTotalizadorDoctores(({...date, last: lastDates, filters})))
  },[])

  return (
        <Table
            columns={columns}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            dataSource={data}
            pagination={{ pageSize }}
            loading={loading}
        />
    )
}
