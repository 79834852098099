import { stat } from "fs";
import clinicasConstants from "./constants";

const {
    SET_OBJETIVOS,
    SET_EDIT_ERROR,
    SET_EDITING_OBJETIVOS,
    SET_EDIT_OK,
    SET_LOADING_OBJETIVOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_OBJETIVOS,
    SET_FETCHED_USER
} = clinicasConstants

const initialState = {
    loading: false,
    objetivos: false,
    anualData: false,
    editOk: false,
    fetchedUser: [],
    selectedRowKeys: []
}

const ObjetivosReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_OBJETIVOS:
            return {
                ...state,
                loading: action.isLoadingObjetivos
            }
        
        case SET_OBJETIVOS:
            return{
                ...state,
                objetivos: action.objetivos.response,
                anualData: action.objetivos.anualData
            }
        
        case SET_EDITING_OBJETIVOS:
            return {
                ...state,
                isEditingObjetivos: action.isEditingObjetivos
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_OBJETIVOS:
            return {
                ...state
            }
    
        case SET_FETCHED_USER:
            return {
                ...state,
                fetchedUser: action.fetchedUser
            }
    default:
        return state
    }
}

export default ObjetivosReducer