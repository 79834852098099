const partePresupuestosConstants = {
    SET_LOADING_PARTE_PRESUPUESTOS: 'SET_LOADING_PARTE_PRESUPUESTOS',
    SET_PARTE_PRESUPUESTOS: 'SET_PARTE_PRESUPUESTOS',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_LOADING_PROFILE: 'SET_LOADING_PROFILE',
    SET_PROFILE: 'SET_PROFILE',
    SET_USERS_LISTS_PRESUPUESTOS: 'SET_USERS_LISTS_PRESUPUESTOS'
  };
  
  export default partePresupuestosConstants;
  