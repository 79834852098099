import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Layout, Menu, Row, Col, message, notification, Button } from 'antd';

import styles from './styles.module.scss';
import logo from '../../assets/Recurso-2.svg';
import logoutIcon from '../../assets/icon/logout.svg';
import estadisticasIcon from '../../assets/icon/cumplimentacion.svg';

import administradoresIcon from '../../assets/icon/administradores.png';
import resumenIcon from '../../assets/icon/resumen.svg';
import retosIcon from '../../assets/icon/retos.png';

import Icono from '../../components/MenuItemImg';

import Users from './users/';
import Admins from './admins/';
import Clinicas from './clinicas/';
import Centros from './centros/'
import Entidades from './entidades-financieros';
import Especialidades from './especialidades';
import Empleados from './configuracion/empleados'
import Pais from './configuracion/pais'

import Objetivos from './objetivos'

import Cumplimentacion from './cumplimentacion';
import Resumen from './resumen'

import TiposPagos from './tipos/pagos';
import TiposEmpleados from './tipos/empleados';
import TiposPresupuestos from './tipos/presupuesto';
import TiposOrtodoncias from './tipos/ortodoncias';

import FilterSection from './filter-section/'
import ProgressBar from '../../components/progress-bar';

import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/auth/actions';
import { getObjetivoPrevision, getNotifications } from '../../redux/global/actions'
import NotificationCard from './notification-card';

import UseWidth from '../../helpers/useWidth';
import SwitchSelector from './switchSelector';
import AsideSelector from './asideSelector';

import { ExclamationCircleOutlined } from '@ant-design/icons'


const { Header, Sider, Content } = Layout;



function Dashboard() {
  const { SubMenu } = Menu;

  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    history.push('/login');
    message.success('Has cerrado sesión');
  };

  const { user, checkingSession } = useSelector((state: any) => state.auth);
  const { date, lastdates, objetivo, cajaDia, prevision } = useSelector((state: any) => state.ResumenGlobal)

  

  const filters = useSelector((state: any) => state.Filters)
  const [email, setEmail] = useState();
  const [role, setRole] = useState();

  const { notificationNumber } = useSelector((state: any) => state.ResumenGlobal)

  useEffect(() => {
    if (parseInt(notificationNumber) > 0) {
      notification.info({
        message: `Citas por confirmar asistencia`,
        description:
          `Actualmente hay ${parseInt(notificationNumber)} citas por confirmar su asistencia`,
        className: 'notification_card',
        icon: <ExclamationCircleOutlined style={{ color: '#47464E' }} />,
        style: {
          top: 70
        }
      });
    }
  }, [notificationNumber]);

  useEffect(() => {
    dispatch(getObjetivoPrevision())
  }, [date, lastdates, filters])

  useEffect(() => {
    dispatch(getNotifications())
  }, [date]);

  useEffect(() => {
    history.push('/resumen/global')
  }, []);

  useEffect(() => {
    if (checkingSession === false) {
      if (user) {
        setEmail(user.email);
        setRole(user.role)
      }
    }
  });

  const logout = () => {
    dispatch(logoutUser(onLogout));
  };

  const width = UseWidth()

  const redirection = (route: string) => {
    window.location.href = `${route}`
  }

  console.log("🚀 ~ file: index.tsx:123 ~ Dashboard ~ {!cajaDia ? 0 : cajaDia}:", !cajaDia ? 0 : cajaDia)
  console.log("🚀 ~ file: index.tsx:123 ~ Dashboard ~ {!objetivo ? 0 : objetivo}:", !objetivo ? 0 : objetivo)

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#F9F9F9, 100%' }}>
      <Header className={styles.header} style={{ height: responsiveWidth(width) }}>
        <img src={logo} alt="Logo" className={styles.logo} onClick={() => redirection('/resumen/global')} />

        <ProgressBar
          title="Objetivos"
          firstValue={!cajaDia ? 0 : cajaDia}
          secondValue={!objetivo ? 0 : objetivo}
          percent={!(cajaDia / objetivo) ? 0 : cajaDia / objetivo} />

        <ProgressBar
          title="Previsiones"
          firstValue={!prevision ? 0 : prevision}
          secondValue={!objetivo ? 0 : objetivo}
          percent={!(prevision / objetivo) ? 0 : prevision / objetivo}
        />

        <div className={styles.grid_container}>
          <div className={styles.grid_item}>
            <span className={styles.grid_text}>{email}</span>
            <span className={styles.secundary_text}>{role}</span>
          </div>

          <div className={styles.grid_icon} onClick={logout}>
            <span className={styles.icon}>
              <img src={logoutIcon} />
            </span>
          </div>
        </div>
      </Header>

      <Layout>
        <AsideSelector />

        <Content className={styles.content}>

          <FilterSection />

          <SwitchSelector />

        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;

const responsiveWidth = (width: any) => {
  if (width >= 1000) {
    return 85
  }

  if (width < 600) {
    return 300
  }
  if (width < 1000) {
    return 200
  }
}
