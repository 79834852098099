import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
    PageHeader,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    message,
    Checkbox,
    Space
} from 'antd';

import { setSelected } from '../../../../../redux/acumulado/actions';

import { CSVLink } from 'react-csv';
import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import PptosTipologia from './tables/pptosTipologia';
import PptosTiposPagos from './tables/pptosTiposPagos';
import Cobros from './tables/cobros';

import AboveTableSelector from '../../../../../components/aboveTableSelectorEspecialidades';
import Especialidad from './tables/especialidad';

export default function Index() {

  const [pageSize, setPageSize] = useState(10);
  const {especialidadesLists} = useSelector((state:any) => state.admins)
    
  const [upperSelectorData, setUpperSelectorData] = useState([
    {nombre: 'pptos por tipología'}, 
    {nombre: 'pptos por tipo de pago'}, 
    {nombre: 'cobros'}
  ])

  const dispatch = useDispatch()

    useEffect(() => {
        setUpperSelectorData([...upperSelectorData, ...especialidadesLists])
    }, [especialidadesLists]);
    
    const {selectedRowKeys, selected} = useSelector((state:any) => state.Acumulados);

    const [tableSelect, setTableSelect] = useState(upperSelectorData[0].nombre)
    const [headers, setHeaders] = useState(
        [
            {label: 'Nombre', key: 'nombre'},
            {label: 'Fechas filtradas '+ moment().format('yyyy'), key:'fecha2021'},
            {label: 'Fechas filtradas '+moment().subtract(12, 'month').format('yyyy'), key: 'fecha2020'},
            {label: 'Diferencia', key: 'diferencia'},
            {label: 'Acumulado '+ moment().format('yyyy'), key: 'acumulado2021'}, 
            {label: 'Acumulado '+moment().subtract(12, 'month').format('yyyy'), key: 'acumulado2020'},
            {label: 'Diferencia acumulada', key: 'diferenciaAcumulada'}
        ]
    )
    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Resumen / Acumulado',
        },
    ];

    function handleMenuClick(e: any) {
        setPageSize(e.key);
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="10">10</Menu.Item>
            <Menu.Item key="20">20</Menu.Item>
            <Menu.Item key="30">30</Menu.Item>
        </Menu>
    );

    const { Search } = Input;
    
    const onSearch = (value: any) => {
        const results: any = [];
        if (value) {
            selectedRowKeys.forEach((user: any) => {
            const { nombre } = user;
            const superString = `${nombre}`;
            if (superString.includes(value)) {
                results.push(user);
            }
            });
            return dispatch(setSelected(results));
        }
        if (value.trim() === '') {
            return dispatch(setSelected(selectedRowKeys));
        }
    };
    return (
        <div>
            <PageHeader title="ACUMULADO" ghost={false} breadcrumb={{ routes }} />
            
            <div style={{padding: '1em', display: 'grid'}}>
                <div style={{marginBottom: '1em'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>

                    <div className="search">

                    <Dropdown overlay={menu} overlayStyle={{}}>
                        <Button style={{height: '100%'}}>
                            {pageSize} <DownOutlined />
                        </Button>
                        </Dropdown>
                    <Search
                    placeholder="Buscar..."
                    onSearch={onSearch}
                    style={{ width: 200, marginLeft: 10 }}
                    />
                    </div>
                    <div className="search">

                        <CSVLink
                            style={{
                                color: '#245046',
                                width: '113px',
                                height: '39px',
                                borderRadius: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent:'space-around',
                                cursor: 'pointer',
                                border: '1px solid #245046',
                                backgroundColor: '#FFFFFF'
                            }}
                            data={selectedRowKeys}
                            headers={headers}
                            filename={'noAceptadosData.csv'}
                            separator={','}
                            >
                        <DownloadOutlined />
                        Exportar
                    </CSVLink>
                        </div>
                </div>
                </div>
            

                <AboveTableSelector 
                    data={upperSelectorData}
                    selected={tableSelect}
                    handleClick={(value: any)=>setTableSelect(value)}
                    />

            {tableSelect === 'pptos por tipología' && <PptosTipologia />}
            
            {tableSelect === 'pptos por tipo de pago' && <PptosTiposPagos />}

            {tableSelect === 'cobros' && <Cobros />}

            {
                especialidadesLists.map( (item: any)=>{
                    return (
                        <>
                        {tableSelect === item.nombre && <Especialidad especialidad={item.nombre}/>}
                        </>
                    )
                })
            }
            </div>

        </div>
    )
}
