// eslint @typescript-eslint/no-use-before-define]
import React, { useEffect, useState } from "react";
import moment from "moment";

import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";

import {
  DownloadOutlined,
  EditOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Space,
} from "antd";
import exportIcon from "../../../assets/icon/export.svg";
import PlusOutlinedIcon from "../../../../../assets/icon/plusOutlined.svg";

import CumplimentacionModal from "./components";
import CustomButton from "../../../../../components/custom-button";

import {
  fetchAll,
  edit,
  setSelectedRowKeys,
  deleteCajaDia,
  saveCajaDia,
} from "../../../../../redux/caja_dia/actions";

import formatToEUR from "../../../../../helpers/formatToEuro";
import ButtonSection from "../../../../../components/button-sections";

function Especialidades() {
  const headers = [
    { label: "Clínica", key: "nombre_clinica" },
    { label: "Fecha pago", key: "fecha_pago" },
    { label: "Tipo de pago", key: "nombre" },
    { label: "Número de pagos", key: "numero_pagos" },
    { label: "Cantidad", key: "cantidad" },
  ];

  const columns = [
    {
      title: "Clínica",
      dataIndex: "nombre_clinica",
      Key: "nombre_clinica",
      width: "10vw",
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.main_id),
          },
        };
        return obj;
      },
    },
    {
      title: "Fecha pago",
      dataIndex: "fecha_pago",
      Key: "fecha_pago",
      width: "10vw",
      style: "background-color: red;",
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.fecha_pago).unix() - moment(b.fecha_pago).unix(),
        multiple: 3,
      },
      render: (text: any, record: any) => {
        const obj = {
          children: moment(text).format("DD/MM/YYYY"),
          props: {
            onClick: () => showModalEdit(record.main_id),
          },
        };
        return obj;
      },
    },
    {
      title: "Tipo de pago",
      dataIndex: "nombre",
      Key: "",
      width: "10vw",
      ellipsis: true,
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.main_id),
          },
        };
        return obj;
      },
    },
    {
      title: "Número de pagos",
      dataIndex: "numero_pagos",
      Key: "numero_pagos",
      width: "10vw",
      sorter: {
        compare: (a: any, b: any) =>
          parseInt(a.numero_pagos) - parseInt(b.numero_pagos),
        multiple: 3,
      },
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: () => showModalEdit(record.main_id),
          },
        };
        return obj;
      },
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      Key: "cantidad",
      width: "38vw",
      align: "left" as "left",
      sorter: {
        compare: (a: any, b: any) =>
          parseInt(a.cantidad) - parseInt(b.cantidad),
        multiple: 3,
      },
      render: (text: any, record: any) => {
        const obj = {
          children: formatToEUR(text),
          props: {
            onClick: () => showModalEdit(record.main_id),
          },
        };
        return obj;
      },
    },
    {
      title: "Eliminar",
      dataIndex: "id",
      key: "eliminar",
      render: (text: any, record: any) => {
        const obj = {
          children: (
            <DeleteOutlined style={{ fontSize: "20px", color: "#47464E" }} />
          ),
          props: {
            onClick: () => showModalWatch(record.main_id),
          },
        };
        return obj;
      },
    },
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false);
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
    id: false,

    fecha_pago: false,
    cantidad: "",
    numero_pagos: "",
    observaciones: "",

    Centro: {
      id: "",
      clinica_nombre: "",
    },
    tiposPagos: {
      id: "",
      nombre: "",
    },
  });
  const [selectedUserName, setSelectedUserName] = useState("");
  const { loading, cajaDia } = useSelector((state: any) => state.CajaDia);
  const [data, setData] = useState(cajaDia || [{}]);

  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.main_id === idSelectedEdit);
      setUser(userr);
    }
  }, [data, idSelectedEdit]);

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const handleDelete = () => {
    dispatch(deleteCajaDia(idSelected));
    setIsModalWatchVisible(false);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    const user: any = data.find((e: any) => e.main_id === text);
    setSelectedUserName(user.nombre);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalAddVisible(false);
    setIsModalWatchVisible(false);
    setIdSelectedEdit(0);
    setUser({
      id: false,

      fecha_pago: false,
      cantidad: "",
      numero_pagos: "",
      observaciones: "",

      Centro: {
        id: "",
        clinica_nombre: "",
      },
      tiposPagos: {
        id: "",
        nombre: "",
      },
    });
    setSuccess("");
  };
  const [success, setSuccess] = useState("");
  const { Text } = Typography;

  const handleOk = (form: any) => {
    setSuccess("Usuario actualizado correctamente");
    dispatch(edit(form));
    handleCancel();
  };

  const dispatch = useDispatch();
  const filterState = useSelector((state: any) => state.Filters);
  const { date, lastDates } = useSelector((state: any) => state.ResumenGlobal);

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit, filterState, date, lastDates]);

  useEffect(() => {
    setData(cajaDia);
  }, [cajaDia]);

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      cajaDia.forEach((user: any) => {
        const { nombre, cantidad, nombre_clinica, numero_pagos } = user;
        const superString = `${nombre}, ${cantidad}, ${nombre_clinica} ${numero_pagos}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === "") {
      return setData(cajaDia);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "index",
      breadcrumbName: "Configuracion / Caja del día",
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.Previsiones);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any) => {
    dispatch(saveCajaDia(form));
  };

  return (
    <div>
      <PageHeader title="CAJA DEL DÍA" ghost={false} breadcrumb={{ routes }} />

      <div
        className="top_content_container"
        style={{ gridTemplateColumns: "5% auto 17%" }}
      >
        <div className="search">
          <Dropdown overlay={menu}>
            <Button style={{ height: "100%" }}>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </div>

        <div className="search">
          <CSVLink
            style={{
              color: "#245046",
              width: "113px",
              height: "39px",
              borderRadius: "2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
              border: "1px solid #245046",
              backgroundColor: "#FFFFFF",
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={"cajaDia.csv"}
            separator={","}
          >
            <DownloadOutlined />
            Descargar
          </CSVLink>

          <CustomButton
            style={{
              backgroundColor: "#245046",
              width: "97px",
              height: "39px",
              color: "#ffffff",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
            }}
            onClick={() => setIsModalAddVisible(true)}
          >
            <img src={PlusOutlinedIcon} alt="" style={{ margin: "5px" }} />
            Añadir
          </CustomButton>
        </div>

        <CumplimentacionModal
          isModalVisible={isModalAddVisible}
          onCancel={handleCancel}
          formData={false}
          onSubmit={onsubmit}
        />

        <CumplimentacionModal
          formData={user}
          isModalVisible={isModalEditVisible}
          onCancel={handleCancel}
          onSubmit={handleOk}
        />
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />

        <Modal
          visible={isModalWatchVisible}
          onCancel={handleCancel}
          onOk={handleDelete}
          title="Eliminar caja dia"
        >
          <h3>¿Estás seguro que deseas eliminar el registro seleccionado?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Especialidades;

