import { stat } from "fs";
import centrosConstants from "./constants";

const {
    SET_CENTROS,
    SET_EDIT_ERROR,
    SET_EDITING_CENTROS,
    SET_EDIT_OK,
    SET_LOADING_CENTROS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_CENTROS,
    SET_SAVING_ERROR,
    SET_CENTRO_PROFILE
} = centrosConstants

const initialState = {
    loading: false,
    centros: [],
    editOk: false,
    centroProfile: false,
    selectedRowKeys: [],
    savingError: false
}

const CentrosReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_CENTROS:
            return {
                ...state,
                loading: action.isLoadingCentros
            }
        
        case SET_CENTROS:
            return{
                ...state,
                centros: action.centros
            }
        
        case SET_EDITING_CENTROS:
            return {
                ...state,
                isEditingcentros: action.isEditingCentros
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_CENTROS:
            return {
                ...state
            }
        
        case SET_CENTRO_PROFILE:
            return {
                ...state,
                centroProfile: action.data
            }

        case SET_SAVING_ERROR:
            return {
                ...state,
                savingError: action.savingError
            }

    default:
        return state
    }
}

export default CentrosReducer