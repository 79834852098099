import React from 'react'
import styles from './styles.module.scss'
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Checkbox,
  Space,
} from 'antd'

import formatToEUR from '../../../../../../helpers/formatToEuro'
import formatToPercentage from '../../../../../../helpers/formatPercentNo100'

interface Props {
  data: any
  loading: any
}

export default function TableCobrado(props: Props) {
  const { Column, ColumnGroup } = Table
  let { data, loading } = props

  const columns = [
    {
      title: 'Cobrado',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.cobrado.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.cobrado.prevYear)}</>
          },
        },
      ],
    },
    {
      title: 'Presupuestado',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.presupuestado.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.presupuestado.prevYear)}</>
          },
        },
      ],
    },
    {
      title: 'Presupuestos',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && record.presupuestos.actual}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && record.presupuestos.prevYear}</>
          },
        },
      ],
    },
    {
      title: 'ETTO',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && record.etto.actual}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && record.etto.prevYear}</>
          },
        },
      ],
    },
    {
      title: 'No aceptado',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.noAceptado.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>{record && formatToPercentage(record.noAceptado.prevYear)}</>
            )
          },
        },
      ],
    },
    {
      title: 'Fidelización',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>{record && formatToPercentage(record.fidelizacion.actual)}</>
            )
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>{record && formatToPercentage(record.fidelizacion.prevYear)}</>
            )
          },
        },
      ],
    },
  ]
  const columns2 = [
    {
      title: 'Venta media',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.VentaMedia.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.VentaMedia.prevYear)}</>
          },
        },
      ],
    },
    {
      title: 'Presupuesto medio',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.presupuestoMedio.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>{record && formatToEUR(record.presupuestoMedio.prevYear)}</>
            )
          },
        },
      ],
    },
    {
      title: 'Aceptación (pacientes)',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>
                {record && formatToPercentage(record.aceptacionPaciente.actual)}
              </>
            )
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>
                {record &&
                  formatToPercentage(record.aceptacionPaciente.prevYear)}
              </>
            )
          },
        },
      ],
    },
    {
      title: 'ETTO Media',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && record.ettoMedia.actual}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && record.ettoMedia.prevYear}</>
          },
        },
      ],
    },
    {
      title: 'Aceptación €',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          key: 'actual',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToPercentage(record.aceptacion.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          className: 'subHeader',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return (
              <>{record && formatToPercentage(record.aceptacion.prevYear)}</>
            )
          },
        },
      ],
    },
    {
      title: 'Aceptado total',
      children: [
        {
          title: 'Actual',
          dataIndex: 'actual',
          className: 'subHeader',
          key: 'actual',
          style: 'background: red',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.aceptacionTotal.actual)}</>
          },
        },
        {
          title: 'Año ant.',
          dataIndex: 'prevYear',
          className: 'subHeader',
          key: 'prevYear',
          width: 100,
          render: (text: any, record: any) => {
            return <>{record && formatToEUR(record.aceptacionTotal.prevYear)}</>
          },
        },
      ],
    },
  ]

  return (
    <div style={{ overflow: 'scroll' }}>
      <div className={styles.tableReceive}>
        <Table
          loading={loading}
          dataSource={[data]}
          scroll={{ x: true }}
          columns={columns}
          pagination={false}
          size={'small'}
          summary={(pageData: any) => {
            if (!pageData) return null
            const data = pageData[0]

            const total = (data: { actual: number; prevYear: number }) => {
              if (data) {
                const { actual, prevYear } = data
                if(Number(actual) === 0 || Number(prevYear) === 0) return formatToPercentage('0')

                const res = (actual - prevYear) / prevYear
                if(res) return formatToPercentage(`${res}`)
              } else {
                return formatToPercentage('0')
              }
            }

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    {total(data?.cobrado)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.presupuestado)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.presupuestos)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.etto)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.noAceptado)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.fidelizacion)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </div>
      
      <br />

      <div className={styles.tableReceive}>
        <Table
          loading={loading}
          rowClassName={(record, index) => (!index ? 'subHeader' : '')}
          scroll={{ x: true }}
          dataSource={[data]}
          columns={columns2}
          pagination={false}
          size={'small'}
          summary={(pageData: any) => {
            if (!pageData) return null
            const data = pageData[0]

            const total = (data: { actual: number; prevYear: number }) => {
              if (data) {
                const { actual, prevYear } = data
                if(Number(actual) === 0 || Number(prevYear) === 0) return formatToPercentage('0')

                const res = (actual - prevYear) / prevYear
                if(res) return formatToPercentage(`${res}`)
              } else {
                return formatToPercentage('0')
              }
            }

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    {total(data?.VentaMedia)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.presupuestoMedio)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.aceptacionPaciente)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.ettoMedia)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.aceptacion)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    {total(data?.aceptacionTotal)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </div>
    </div>
  )
}
