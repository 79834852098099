const centrosConstants = {
    SET_LOADING_PRESUPUESTOS: 'SET_LOADING_PRESUPUESTOS',
    SET_PRESUPUESTOS: 'SET_PRESUPUESTOS',
    SET_EDITING_PRESUPUESTOS: 'SET_EDITING_PRESUPUESTOS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_PRESUPUESTOS: 'SET_SAVING_PRESUPUESTOS'
  };
  
  export default centrosConstants;
  