import api from '../../helpers/api'

const EspecialidadesServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/tipos_presupuestos')
    },
    save: (
        nombre: string,
        origen: string
    )=>{
        return api.post('/admin/save/tipos_presupuestos',{
            nombre,
            origen
        }).catch(e => console.log(e))
    },
    destroy: (
        id:string,
        )=> {
            return api.get('/admin/destroy/tipos_presupuestos/'+id)
    }
}

export default EspecialidadesServices