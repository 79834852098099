import adminConstants from './constants';

const {
  SET_LOADING_ADMINS,
  SET_ADMINS,
  SET_EDITING_ADMIN,
  SET_NEW_ADMIN,
  SET_SIGNUP_ERROR,
  SET_LISTS,
  SET_LISTS_CAJA_DIA,
  SET_LISTS_PREVISIONES,
  SET_PARTE_PRESUPUESTO,
  SET_LISTS_EMPLEADOS
} = adminConstants;

const initialState = {
  loading: false,
  data: [],

  clinicaLists: [],
  centrosLists: [],
  especialidadesLists: [],
  consultorLists: [],
  paisLists: [],

  tiposPresupuestoLists: [],
  tiposPagosLists: [],
  tiposOrtodonciasLists: [],
  tiposEmpleadosLists: [],

  directorLists: [],
  pacienteLists: [],
  doctorLists: [],

  
  entidadesFinancierasLists: []  
};

const adminsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_ADMINS: {
      return {
        ...state,
        loading: action.isLoadingAdmins,
      };
    }
    case SET_ADMINS: {
      return {
        ...state,
        data: action.admins,
      };
    }
    case SET_EDITING_ADMIN: {
      return {
        ...state,
        isEditingAdmin: action.isEditingAdmin,
      };
    }
    case SET_NEW_ADMIN: {
      return {
        ...state,
        isNewAdmin: action.isNewAdmin,
      };
    }
    case SET_SIGNUP_ERROR: {
      return {
        ...state,
        logUpError: action.logUpError,
      };
    }
    case SET_LISTS:{
      return {
        ...state,
        directorLists: action.lists.director,
        clinicaLists: action.lists.clinicas,
        centrosLists: action.lists.centros,
        especialidadesLists: action.lists.especialidades,
        consultorLists: action.lists.consultor,
        doctorLists: action.lists.doctor,
        paisLists: action.lists.pais
      }
    }
    
    case SET_PARTE_PRESUPUESTO: {
      return {
        ...state,
        tiposPresupuestoLists: action.lists.tiposPresupuesto,
        tiposPagosLists: action.lists.tiposPagos,
        tiposOrtodonciasLists: action.lists.tiposOrtodoncias,
        entidadesFinancierasLists: action.lists.entidadesFinancieras,
        // directorLists: action.lists.directorLists,
        // doctorLists: action.lists.doctorLists
      }
    }

    case SET_LISTS_PREVISIONES: {
      return {
        ...state,
        directorLists: action.lists.directorLists,
        pacienteLists: action.lists.pacienteLists
      }
    }

    case SET_LISTS_CAJA_DIA: {
      return {
        ...state,
        tiposPagosLists: action.lists.tiposPagosLists
      }
    }

    case SET_LISTS_EMPLEADOS: {
      return {
        ...state,
        tiposEmpleadosLists: action.lists.tiposEmpleadosLists
      }
    }

    default: {
      return state;
    }
  }
};

export default adminsReducer;
