import api from '../../helpers/api'
import moment from "moment/moment";

const PrevisionesServices = {
    fetchAll: (
        date: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/list/previsiones',{
            date,
            last,
            filters
        })
    },
    save: (
        Centro:any,
        Paciente:any,
        User:any,
        cantidad:any,
        fecha_prevision:any,
        observaciones:any,
    )=>{
        return api.post('/admin/save/previsiones',{
            Centro,
            Paciente,
            User,
            cantidad,
            fecha_prevision: fecha_prevision?moment(fecha_prevision).format('YYYY-MM-DDT00:00:00[Z]'):'',
            observaciones,
        })
    },
    edit: (
        id: string,
        Centro:any,
        Paciente:any,
        User:any,
        cantidad:any,
        fecha_prevision:any,
        observaciones:any,
        )=>{
        return api.put('/admin/update/previsiones/'+id,{
            Centro,
            Paciente,
            User,
            cantidad,
            fecha_prevision: fecha_prevision?moment(fecha_prevision).format('YYYY-MM-DDT00:00:00[Z]'):'',
            observaciones,
        })
    },
    delete: (
        id: any
    )=>{
        return api.get('/admin/destroy/previsiones/'+id)
    }
}

export default PrevisionesServices