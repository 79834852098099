import { stat } from "fs";
import partePresupuestosConstants from "./constants";

const {
    SET_PARTE_PRESUPUESTOS,
    SET_LOADING_PARTE_PRESUPUESTOS,
    SET_SELECTED_ROW_KEYS,
    SET_LOADING_PROFILE,
    SET_PROFILE,
    SET_USERS_LISTS_PRESUPUESTOS
} = partePresupuestosConstants

const initialState = {
    loading: false,
    partePresupuestoArray: [],
    usersLists: [],
    selectedRowKeys: [],
    isLoadingProfile: false,
    profile: false
}

const EspecialidadesReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_PARTE_PRESUPUESTOS:
            return {
                ...state,
                loading: action.loading
            }
        
        case SET_PARTE_PRESUPUESTOS:
            return{
                ...state,
                partePresupuestoArray: action.partePresupuesto
            }
        
        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_LOADING_PROFILE:
            return {
                ...state,
                isLoadingProfile: action.isLoading
            }

        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile
            }

        case SET_USERS_LISTS_PRESUPUESTOS:
            return {
                ...state,
                usersLists: action.data.usersLists
            }
    default:
        return state
    }
}

export default EspecialidadesReducer