// import { stat } from "fs";
import moment from "moment";
import globalConstants from "./constants";

const {
    SET_LOADING_NO_ACEPTADOS,
    SET_NO_ACEPTADOS,
    SET_SELECTED_ROW_KEYS
} = globalConstants

const initialState = {
    noAceptadosData: [],
    loading: false, 
    selectedRowKeys: []
}

const filterReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_NO_ACEPTADOS:
            return {
                ...state,
                noAceptadosData: action.data
            }

        case SET_LOADING_NO_ACEPTADOS:
            return {
                ...state,
                loading: action.isLoading
            }

        case SET_SELECTED_ROW_KEYS:
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys
            }

    default:
        return state
    }
}

export default filterReducer