import api from '../../helpers/api'

const CajaDiaServices = {
    fetchAll: (
        date: any,
        filters: any,
    )=>{
        return api.post('/admin/list/objetivos', {
            date,
            filters
        })
    },
    fetchUserLists: ()=>{
        return api.get('/admin/list/users/objetivos')
    },
    fetchUsersWhereCentro: (
        centroid:any
    )=>{
        return api.get('/admin/list/users_centro/'+centroid)
    },
    save: (
        centro:any,
        usuario:any,
        fecha_fin:any,
        fecha_inicio:any,
        cantidad:any,
        observaciones:any,
        
    )=>{
        return api.post('/admin/save/objetivos',{
            centro,
            usuario,
            fecha_fin,
            fecha_inicio,
            cantidad,
            observaciones,
        })
    },
    edit: (
        id: string,
        centro:any,
        usuario:any,
        fecha_fin:any,
        fecha_inicio:any,
        cantidad:any,
        observaciones:any,
        )=>{
        return api.put('/admin/update/objetivos/'+id,{
            centro,
            usuario,
            fecha_fin,
            fecha_inicio,
            cantidad,
            observaciones,
        })
    },
    delete:(
        id: any
    )=>{
        return api.get('/admin/destroy/objetivos/'+id)
    }
}

export default CajaDiaServices