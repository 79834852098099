import React from 'react'
import {Progress, Typography} from 'antd'

import formatToPercentage from '../../helpers/formatPercentNo100'
import formatToEUR from '../../helpers/formatToEuro'

import './styles.scss'
const {Title} = Typography


interface Props {
    title: any
    firstValue: any
    secondValue: any
    percent: any
}

function ProgressBar(props: Props) {
    const {title, percent, firstValue, secondValue} = props
    return (
        <div className="progress-wrapper">
            <div className="title-wrapper" style={{display:'grid', gridTemplateColumns: '20% 25% 55%'}}>
                <Title level={5} style={{margin: 0}}>{title}</Title>
                <Title level={5} style={{margin: 0, textAlign:'right', color: '#424A59', fontWeight: 500}}>{formatToPercentage(percent)}</Title>
                <Title level={5} style={{margin: 0, textAlign:'right', fontWeight: 400}}> {formatToEUR(firstValue)} / {formatToEUR(secondValue)}</Title>
                
            </div>
            <Progress 
                percent={percent*100}
                type='line'
                strokeColor={colorChooser(percent)}
                strokeWidth={20}
                showInfo={false}/>
        </div>
    )
}

export default ProgressBar


export function colorChooser(percent:any){
    if(percent > .95) return '#58D47B'
    
    if(percent <= .45) return '#FF5A5A'

    if(percent <= .94) return '#FFD100'

}