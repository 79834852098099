import ClinicasServices from "./services";

import clinicasConstants from "./constants";

import {fetchLists} from '../admins/actions'

const {
    SET_CLINICAS,
    SET_LOADING_CLINICAS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_CLINICA
} = clinicasConstants

export const setLoadingClinicas = (isLoadingClinicas: boolean) => ({
    type: SET_LOADING_CLINICAS,
    isLoadingClinicas,
  });
  
  const setClinicas = (clinicas: any) => ({
    type: SET_CLINICAS,
    clinicas,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingClinicas(true));
      ClinicasServices.fetchAll().then((res) => {
        dispatch(setLoadingClinicas(false));
        return dispatch(setClinicas(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_CLINICAS, SET_EDIT_ERROR, SET_EDIT_OK } = clinicasConstants;
  const setEditingClinica = (isEditingClinicas: boolean) => ({
    type: SET_EDITING_CLINICAS,
    isEditingClinicas,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const editClinica = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingClinica(true));
      dispatch(setEditError(null));
      ClinicasServices
        .edit(
          data.id,
          data.cif,
          data.razon_social,
          data.nombre_comercial,
          data.numero_telefono,
          data.email,
          data.direccion,
          data.codigo_postal,
          data.pais,
          data.poblacion,
          data.contacto_nombre,
          data.contacto_numero,
          data.fecha_alta,
          data.fecha_baja,
          data.observaciones
        )
        .then((res: any) => {
          dispatch(setEditingClinica(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
          dispatch(fetchLists())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingClinica(false));
        });
    };
  };
  
  export const deleteClinica = (data: any) => {
    return (dispatch: any) =>{
      ClinicasServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") dispatch(fetchAll())
      })
      .catch(e => console.log(e))
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingClinica = (saving: boolean | null)=>({
    type: SET_SAVING_CLINICA,
    saving
  })

  export const saveClinica = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingClinicas)
      dispatch(savingClinica(true))
      ClinicasServices.save(
          data.cif,
          data.razon_social,
          data.nombre_comercial,
          data.telefono,
          data.email,
          data.direccion,
          data.codigo_postal,
          data.pais,
          data.poblacion,
          data.contacto_persona,
          data.contacto_numero,
          data.fecha_alta,
          data.fecha_baja,
          data.observaciones
        ).then(res => {
            dispatch(fetchLists())
            dispatch(fetchAll())
        })
      }catch(e){
        console.log(e)
      }
    }
  }