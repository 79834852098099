const clinicasConstants = {
    SET_LOADING_CLINICAS: 'SET_LOADING_CLINICAS',
    SET_CLINICAS: 'SET_CLINICAS',
    SET_EDITING_CLINICAS: 'SET_EDITING_CLINICAS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_CLINICA: 'SET_SAVING_CLINICA'
  };
  
  export default clinicasConstants;
  