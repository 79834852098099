import PresupuestosServices from "./services";

import centrosConstants from "./constants";

const {
    SET_PRESUPUESTOS,
    SET_LOADING_PRESUPUESTOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_PRESUPUESTOS
} = centrosConstants

export const setLoadingPresupuesto = (isLoadingPresupuesto: boolean) => ({
    type: SET_LOADING_PRESUPUESTOS,
    isLoadingPresupuesto,
  });
  
  const setPresupuesto = (presupuesto: any) => ({
    type: SET_PRESUPUESTOS,
    presupuesto,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingPresupuesto(true));
      PresupuestosServices.fetchAll().then((res) => {
        dispatch(setLoadingPresupuesto(false));
        return dispatch(setPresupuesto(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_PRESUPUESTOS, SET_EDIT_ERROR, SET_EDIT_OK } = centrosConstants;
  const setEditingPresupuestos = (isEditingEspecialidades: boolean) => ({
    type: SET_EDITING_PRESUPUESTOS,
    isEditingEspecialidades,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const deletePresupuesto = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingPresupuestos(true));
      dispatch(setEditError(null));
      PresupuestosServices
        .destroy(
          data.id
        )
        .then((res: any) => {
          dispatch(setEditingPresupuestos(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingPresupuestos(false));
        });
    };
  };
  
  export const deleteCentro = (data: any) => {
    return (dispatch: any) =>{
      console.log('=D')
      /*PresupuestosServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") fetchAll()
      })
      .catch(e => console.log(e))*/
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingEspecialidades = (saving: boolean | null)=>({
    type: SET_SAVING_PRESUPUESTOS,
    saving
  })

  export const savePresupuesto = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingPresupuesto)
      dispatch(savingEspecialidades(true))
      PresupuestosServices.save(
        data.nombre,
        data.origen
        ).then(res =>
            dispatch(fetchAll())  
          )
      }catch(e){
        console.log(e)
      }
    }
  }