import React from 'react'
import {Switch, Route} from 'react-router-dom'

import Global from './pages/global'
import SegAsesores from './pages/seguimiento-asesores/'
import SegDoctores from './pages/seguimiento-doctores'
import ParteNoAceptados from './pages/parte-no-aceptados'
import Acumulado from './pages/acumulado'

interface Props {}

function CumplimentacionIndex(props: Props) {
    const {} = props

    return (
        <>
            <Switch>
                <Route path='/resumen/global' exact component={Global}/>
                <Route path='/resumen/seguimiento/asesores' exact component={SegAsesores} />
                <Route path='/resumen/seguimiento/doctores' exact component={SegDoctores} />
                <Route path='/resumen/partes_no_aceptados' exact component={ParteNoAceptados} />
                <Route path='/resumen/acumulado' exact component={Acumulado} />
            </Switch>
        </>   
    )
}

export default CumplimentacionIndex
