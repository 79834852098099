import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import {Table} from 'antd'

import {fetchPptosTipologia} from '../../../../../../redux/acumulado/actions'

import formatToPercentage from '../../../../../../helpers/formatPercentNo100'

export default function PptosTipologia() {
    const dispatch = useDispatch()
    const { date } = useSelector((state: any) => state.ResumenGlobal);
    const filters = useSelector((state: any) => state.Filters);

    const {selected, loading} = useSelector((state:any) => state.Acumulados);
    
    useEffect(() => {
        dispatch(fetchPptosTipologia(date, filters))
    }, [date, filters]);

    useEffect(() => {
        dispatch(fetchPptosTipologia(date, filters))
    }, []);
    
    let columns = [{
        title: 'Tipo de presupuesto',
        key: 'nombre',
        dataIndex: 'nombre'
    },{
        title: 'Fechas filtradas '+ moment().format('yyyy'),
        key: 'fecha2021',
        dataIndex: 'fecha2021',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.fecha2021) - parseInt(b.fecha2021),
          multiple: 3,
        },
    },{
        title: 'Fechas filtradas '+moment().subtract(12, 'month').format('yyyy'),
        key: 'fecha2020',
        dataIndex: 'fecha2020',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.fecha2020) - parseInt(b.fecha2020),
          multiple: 3,
        },
    },{
        title: 'Diferencia',
        key: 'diferencia',
        dataIndex: 'diferencia',
        render: (text: any, record: any) => {
            return (
            <>
            {
                record && formatToPercentage(record.diferencia)
            }
            </>
        )}
    },{
        title: 'Acumulado '+ moment().format('yyyy'),
        key: 'acumulado2021',
        dataIndex: 'acumulado2021',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.acumulado2021) - parseInt(b.acumulado2021),
          multiple: 3,
        },
    },{
        title: 'Acumulado '+moment().subtract(12, 'month').format('yyyy'),
        key: 'acumulado2020',
        dataIndex: 'acumulado2020',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.acumulado2020) - parseInt(b.acumulado2020),
          multiple: 3,
        },
    },{
        title: 'Diferencia acumulada',
        key: 'diferenciaAcumulada',
        dataIndex: 'diferenciaAcumulada',
        render: (text: any, record: any) => {
        return (
            <>
            {
                record && formatToPercentage(record.diferenciaAcumulada)
            }
            </>
        )}
    }]
    
    return (
        <div style={{overflow: 'scroll'}}>
            <Table 
                columns={columns}
                scroll={{x: true}}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                dataSource={selected}
                loading={loading}
            />
        </div>
    )
}
