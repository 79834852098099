import api from "../../helpers/api";

const CajaDiaServices = {
  fetchAll: (date: any, filters: any) => {
    const { starts, ends, last } = date;
    return api.post("/admin/list/caja_dia", {
      date: {
        starts: starts.format("YYYY-MM-DDTHH:mm:ss"),
        ends: ends.format("YYYY-MM-DDTHH:mm:ss"),
        last,
      },
      filters,
    });
  },
  fetchUsersWhereCentro: (centroid: any) => {
    return api.get("/admin/list/users/" + centroid);
  },
  save: (
    Centro: any,
    tiposPagos: any,
    numero_pagos: any,
    cantidad: any,
    fecha_pago: any,
    observaciones: any
  ) => {
    return api.post("/admin/save/caja_dia", {
      Centro,
      tiposPagos,
      numero_pagos,
      cantidad,
      fecha_pago: fecha_pago,
      observaciones,
    });
  },
  edit: (
    id: string,
    Centro: any,
    tiposPagos: any,
    numero_pagos: any,
    cantidad: any,
    fecha_pago: any,
    observaciones: any
  ) => {
    return api.put("/admin/update/caja_dia/" + id, {
      Centro,
      tiposPagos,
      numero_pagos,
      cantidad,
      fecha_pago: fecha_pago,
      observaciones,
    });
  },
  delete: (id: any) => {
    return api.get("/admin/destroy/caja_dia/" + id);
  },
};

export default CajaDiaServices;

