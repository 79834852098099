import tiposOrtodonciasConstants from "./constants";
import TiposOrtodonciasServices from "./services";

const {
    SET_TIPOS_ORTODONCIAS,
    SET_LOADING_TIPOS_ORTODONCIAS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_TIPOS_ORTODONCIAS
} = tiposOrtodonciasConstants

export const setLoadingTiposOrtodoncias = (isLoadingTiposOrtodoncias: boolean) => ({
    type: SET_LOADING_TIPOS_ORTODONCIAS,
    isLoadingTiposOrtodoncias,
  });
  
  const setTiposOrtodoncias = (tiposOrtodoncias: any) => ({
    type: SET_TIPOS_ORTODONCIAS,
    tiposOrtodoncias,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingTiposOrtodoncias(true));
      TiposOrtodonciasServices.fetchAll().then((res) => {
        dispatch(setLoadingTiposOrtodoncias(false));
        return dispatch(setTiposOrtodoncias(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_TIPOS_ORTODONCIAS, SET_EDIT_ERROR, SET_EDIT_OK } = tiposOrtodonciasConstants;
  const setEditingTiposOrtodoncias = (isEditingTiposOrtodoncias: boolean) => ({
    type: SET_EDITING_TIPOS_ORTODONCIAS,
    isEditingTiposOrtodoncias,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const deleteOrtodoncias = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingTiposOrtodoncias(true));
      dispatch(setEditError(null));
      TiposOrtodonciasServices
        .destroy(
          data.id
        )
        .then((res: any) => {
          dispatch(setEditingTiposOrtodoncias(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingTiposOrtodoncias(false));
        });
    };
  };
  
  export const deleteCentro = (data: any) => {
    return (dispatch: any) =>{
      console.log('=D')
      /*TiposOrtodonciasServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") fetchAll()
      })
      .catch(e => console.log(e))*/
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingTiposOrtodoncias = (saving: boolean | null)=>({
    type: SET_SAVING_TIPOS_ORTODONCIAS,
    saving
  })

  export const saveTiposOrtodoncias = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingTiposOrtodoncias)
      dispatch(savingTiposOrtodoncias(true))
      TiposOrtodonciasServices.save(
        data.nombre,
        ).then(res =>
            dispatch(fetchAll())
          )
      }catch(e){
        console.log(e)
      }
    }
  }