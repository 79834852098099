import React from 'react'
import { useSelector } from 'react-redux'
import {Table} from 'antd'

import formatToEUR from '../../../../helpers/formatToEuro'
import formatToPercentage from '../../../../helpers/formatToPercent'
interface Props {
    
}

const YearTable = (props: Props) => {

    const {anualData, loading} = useSelector((state: any) => state.Objetivos);

    const columns = [
        {
            title: '',
            fixed: true,
            width: '8vw',
            key: 'nombre',
            dataIndex: 'nombre',
            align: 'right' as 'right',
            render: (text: any, record: any) => {
                return {
                    props:{
                        style: {fontWeight: 'bold', color: '#245046'}
                    },
                    children:
                        <text>{record.nombre}</text>
                }
            }
        },{
            title: 'Enero',
            dataIndex: 'enero',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.enero, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.enero, record.nombre)}</text>
                    }
                }

        },{
            title: 'Febrero',
            dataIndex: 'febrero',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.febrero, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.febrero, record.nombre)}</text>
                    }
                }
        },{
            title: 'Marzo',
            dataIndex: 'marzo',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.marzo, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.marzo, record.nombre)}</text>
                    }
                }
        },{
            title: 'Abril',
            dataIndex: 'abril',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.abril, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.abril, record.nombre)}</text>
                    }
                }
        },{
            title: 'Mayo',
            dataIndex: 'mayo',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.mayo, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.mayo, record.nombre)}</text>
                    }
                }
        },{
            title: 'Junio',
            dataIndex: 'junio',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.junio, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.junio, record.nombre)}</text>
                    }
                }
        },{
            title: 'Julio',
            dataIndex: 'julio',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.julio, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.julio, record.nombre)}</text>
                    }
                }
        },{
            title: 'Agosto',
            dataIndex: 'agosto',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.agosto, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.agosto, record.nombre)}</text>
                    }
                }
        },{
            title: 'Septiembre',
            dataIndex: 'septiembre',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.septiembre, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.septiembre, record.nombre)}</text>
                    }
                }
        },{
            title: 'Octubre',
            dataIndex: 'octubre',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.octubre, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.octubre, record.nombre)}</text>
                    }
                }
        },{
            title: 'Noviembre',
            dataIndex: 'noviembre',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.noviembre, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.noviembre, record.nombre)}</text>
                    }
                }
        },{
            title: 'Diciembre',
            dataIndex: 'diciembre',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.diciembre, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.diciembre, record.nombre)}</text>
                    }
                }
        },{
            title: 'Total',
            dataIndex: 'total',
            outerWidth: '10vw',
            render: (text: any, record: any) => {
                let background = percentageValidation(record.total, record.nombre)
                return {
                    props:{
                        style: {background: background}
                    },
                    children: 
                        <text>{record && setFormat(record.total, record.nombre)}</text>
                    }
                }
        }]

    return (
        <div style={{padding: '1em'}}>
            <Table
                rowClassName='year-table'
                pagination={false}
                columns={columns}
                dataSource={anualData}
                scroll={{x: 1500}}
                />
        </div>
    )
}

export default YearTable

const setFormat = (value: any, indexName: string)=>{
    if(indexName === 'Cumplimiento'){
        return formatToPercentage(value)
    }

    if(indexName !== 'Cumplimiento'){
        return formatToEUR(value)
    }
}

const percentageValidation = (percentage:any, indexName: string)=>{
    if(percentage > 97 && indexName === 'Cumplimiento'){
        return '#58D47B'
    }

    if(percentage < 37 && indexName === 'Cumplimiento'){
        return '#FF5A5A'
    }

    if(percentage < 98 && indexName === 'Cumplimiento'){
        return '#FFD100'
    }
    return '#ffffff'
}