import React, {useState} from 'react'

import GraphList from './graphsData'
import Lists from './LineChart'
import PieChart from './pieChart'

import GraphDefault from '../../../../../../../assets/icon/graph/default.svg'
import GraphPressed from '../../../../../../../assets/icon/graph/pressed.svg'

import ListsDefault from '../../../../../../../assets/icon/graph/list-default.svg'
import Listspressed from '../../../../../../../assets/icon/graph/list-pressed.svg'


interface Props {
    label: string
    data: any,
    circular: boolean
}

export default function Index(props: Props) {
    let {label, data, circular} = props

    const [graphList, setGraphList] = useState(circular ? 'circular' : 'graph')

    const handleClick = (selector: string)=>{
        setGraphList(selector)
    }

    if(!circular) return (
        <div style={{height: 500}}>
            <header 
                style={{
                    padding:'1em 0', 
                    color:'rgba(36, 80, 70, 1)', 
                    fontWeight: 'bold', 
                    borderBottom: '2px solid rgba(140, 140, 140, 1)',
                    }}
                    >
                    {label}
            </header>
            <div>
                <div style={{margin: 0}}>
                    <GraphListSelector selectorName={graphList} handleClick={handleClick}/>
                </div>
                <div style={{marginTop: 10, maxHeight: 420, justifyContent: 'center', alignItems: 'center', gridGap: 10, width: '100%', overflowY: 'scroll'}}>
                {
                        graphList === 'graph' && <GraphList label={label} data={data}/>
                    }
                    {
                        graphList === 'lists' && <Lists label={label} data={data}/>
                    }
                </div>
            </div>
        </div>
    )

    if(circular) return (
        <div style={{height: 500}}>
            <header 
                style={{
                    padding:'1em 0', 
                    color:'rgba(36, 80, 70, 1)', 
                    fontWeight: 'bold', 
                    borderBottom: '2px solid rgba(140, 140, 140, 1)'}}
                    >
                    {label}
            </header>
            <div>
                <div style={{margin: 0}}>
                    <GraphListSelector selectorName={graphList} handleClick={handleClick} type={'circular'}/>
                </div>
                <div style={{marginTop: 10, justifyContent: 'center', alignItems: 'center', gridGap: 10, width: '100%'}}>
                    {
                        graphList === 'circular' && <PieChart label={label} data={data}/>
                    }
                    {
                        graphList === 'lists' && <Lists label={label} data={data}/>
                    }
                </div>
            </div>
        </div>
    )
    
    return (<div></div>)
}

interface GraphqlListsProps {
    selectorName: any,
    handleClick: any,
    type?: any
}

function GraphListSelector (props:GraphqlListsProps){
    let {selectorName, handleClick} = props
    return (
        <div style={{marginTop: 10}}>
            <button
                style={{
                    width: 30,
                    height: 30,
                    border:'none',
                    cursor: 'pointer',
                    backgroundColor: 'white'}} 
                    onClick={()=>{
                        if(props?.type !== 'circular') handleClick('graph')
                        if(props?.type === 'circular') handleClick('circular')
                    }}
                >
                <img
                    src={selectorName === 'circular' || selectorName === 'graph' ? GraphPressed : GraphDefault }
                    style={{transition: '.2s'}}
                />    
            </button>
            
            
            <button
                style={{
                    width: 30,
                    height: 30,
                    border:'none',
                    cursor: 'pointer',
                    borderLeft: '1px solid #D9D9D9',
                    backgroundColor: 'white'}} 
                onClick={()=>handleClick('lists')}
                >
                <img
                    style={{transition: '.2s'}}
                    src={selectorName === 'lists' ? Listspressed : ListsDefault}
                />    
            </button>
        </div>
    )
}