import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'

import { fetchListsEmpleados } from '../../../../../redux/admins/actions'

interface Props {
    isModalVisible: boolean
    onCancel: any
    onSubmit: any
    formData: any
}


function Index(props: Props) {
    const {isModalVisible, onCancel, onSubmit, formData} = props
    const dispatch = useDispatch()

    let {profile, isLoading} = useSelector( (state:any)=>state.PartePresupuesto)
    let {centrosLists, especialidadesLists, tiposEmpleadosLists} = useSelector( (state:any)=>state.admins)

    const [data, setData] = useState({
        id: false,
        
        nombre: '',
        apellidos: '',
        dni: '',
        email: '',
        telefono: '',
        observaciones: '',

        activo: false,
        centro: '',
        empleado: '',
        especialidad: false,
        especialidadChecked: []
        
    })

    useEffect(() => {
        if(formData){
            syncData(formData)
        }

        if(!formData){
            resetData()
        }
    }, [formData])

    useEffect(() => {
        dispatch(fetchListsEmpleados())
    }, [])

    const syncData = (newData: any)=>{
        let especialidadChecked:any = [] 
        
        newData.especialidad && newData.especialidad.map( (item: any)=>{
            especialidadChecked.push(item.especialidad_id)
        })
        setData({
            id: newData.empleado_id,
        
            nombre: newData && newData.empleado_nombre,
            apellidos: newData && newData.apellidos,
            dni: newData && newData.dni,

            email: newData && newData.email,
            telefono: newData && newData.telefono,
            observaciones: newData && newData.empleado_observaciones,
            
            especialidad: syncEspecialidadesArray(newData.especialidad),
            especialidadChecked,
            centro: newData && newData.centro_id,
            empleado: newData && newData.tipo_empleado_id,
            activo: newData && newData.activo,  
        })
    }

    const syncEspecialidadesArray = (oldArray:any)=>{
        if(oldArray){
            if(oldArray.length === 0) {
                return especialidadesLists.map( (item:any)=>{
                    let {id, nombre} = item
                    return {value: id, label: nombre}
                })
            }

            if(oldArray.length > 0){
                let newArray:any = []
                for (var i = 0; i < especialidadesLists.length; i++) {
                    var igual=false;
                        for (var j = 0; j < oldArray.length && !igual; j++) {
                            if(especialidadesLists[i]['id'] == oldArray[j]['especialidad_id'] && 
                            especialidadesLists[i]['nombre'] == oldArray[j]['nombre']) 
                            igual=true;
                        }
                    if(!igual) newArray.push(especialidadesLists[i]);
                }

                let arrayToSync = [...newArray, ...oldArray]
                return arrayToSync.map( (item:any)=>{
                    let {id, nombre, checked, especialidad_id} = item
                    return {value: especialidad_id ? especialidad_id : id, label: nombre}
                })  
            }
        }
    }

    const handleChange=(event: any)=>{
        if(event.target){
            let {name, value} = event?.target
            setData({...data, [name]:value})
        }
    }

    const sortFromList = (name: any, value:any) =>{
        if(name === 'tiposEmpleado'){
            let sortedValue = tiposEmpleadosLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'centro'){
            let sortedValue = centrosLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if( 
            name === 'telefono' 
        ){
            handleChangeNameValue(name, value)
        }

        if(name === 'fecha_pago'){
            handleChangeNameValue(name, value.format())
        }
    }

    const handleChangeNameValue = (name:any, value:any)=>{
        if(
            name === 'telefono'
        ){ 
            setData({...data, [name]: value})
        }
        
        if(
            name === 'centro' ||
            name === 'empleado' 
            ){
            setData({
                ...data,
                [name]: {...value[0]}
            })
        }
    }

    const handleChangeDate = (name: string, e:any)=>{
        if(e){
        let date = e.format()
        let newDate = date
        setData({...data, [name]:newDate})

        }else{
            setData({...data, [name]: ''})
        }    
    }

    const handleSubmit = (submittedData:any)=>{
        onSubmit(submittedData)
    }

    const resetData = ()=>{
        setData({
            id: false,
        
            nombre: '',
            apellidos: '',
            dni: '',
            email: '',
            telefono: '',
            observaciones: '',

            activo: false,
            centro: '',
            // centro: {
            //     id: '',
            //     clinica_nombre: ''
            // },
            empleado: '',
            especialidad: false,
        especialidadChecked: []
        // empleado: {
            //     id: '',
            //     nombre: '',
            //     tieneEspecialidad: false
            // }
        })
    }

    const handleClose = ()=>{
        resetData()
        onCancel()
    }


    if(isLoading) return (
        <div>
            loading
        </div>
    ) 

    return (
        <Form 
            isVisible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            data={data}
            
            centrosLists={centrosLists}
            tiposEmpleadosLists={tiposEmpleadosLists}
            especialidadesLists={especialidadesLists}

            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleChangeNameValue={sortFromList}
        />
    )
}

export default Index
