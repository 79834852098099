import React from 'react'

import ProgressBar from '../../../../../../components/progress-bar-percentage-global'

import styles from './styles.module.scss'
interface Props {
    loading: boolean
    data: any
}

export default function Wrapper(props: Props) {
    let {data} = props

    return (
        <div 
            className={styles.percentage_group}
            // style={{padding:'1em 0' ,display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 23%)', gridGap: '2em'}}
        >
            <ProgressBar 
                label="DERIVACIONES DE ORTODONCIA"
                percent={data && data.ortodoncia.derivable}
                />
            
            <ProgressBar 
                label="ACEPTACIÓN DE ORTODONCIA"
                percent={data && data.ortodoncia.aceptacion}
                />

            <ProgressBar 
                label="DERIVACIONES DE IMPLANTOLOGÍA"
                percent={data && data.implantologia.derivable}
                />
            
            <ProgressBar 
                label="ACEPTACIÓN DE IMPLANTOLOGÍA"
                percent={data && data.implantologia.aceptacion}
                />

            <ProgressBar 
                label="DERIVACIONES DE PERIODONCIA"
                percent={data && data.periodoncia.derivable}
                />

            <ProgressBar 
                label="ACEPTACIÓN DE PERIODONCIA"
                percent={data && data.periodoncia.aceptacion}
                />

            <ProgressBar 
                label="DERIVACIONES DE ESTÉTICA"
                percent={data && data.estetica.derivable}
                />

            <ProgressBar 
                label="ACEPTACIÓN DE ESTÉTICA"
                percent={data && data.estetica.aceptacion}
                />
        </div>
    )
}
