// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_wrapper__2TqaT {\n  background-color: white;\n  padding: 2em;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, 300px);\n  grid-gap: 1em; }\n\n.styles_percentage_wrapper__2QVP_ {\n  padding: 1em;\n  display: grid; }\n\n.styles_label___81-f {\n  color: #245046;\n  font-weight: bold; }\n\n.styles_value__3M-1O {\n  display: flex;\n  justify-content: center;\n  font-size: 25px;\n  font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"wrapper": "styles_wrapper__2TqaT",
	"percentage_wrapper": "styles_percentage_wrapper__2QVP_",
	"label": "styles_label___81-f",
	"value": "styles_value__3M-1O"
};
module.exports = exports;
