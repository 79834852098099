import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import FormEuro from './form_euro'

interface Props {
    handleChangeNameValue: any
    data: any
    handleChange: any
    inputName: any
}

function ImplatologiaForm(props: Props) {
    const {
        data,
        handleChangeNameValue,
        inputName
    } = props

    const {Option} = Select
    const {doctorLists} = useSelector( (state:any)=>state.admins)

    const handleChange = (inputCamp:any, value:any) => {
        // if(inputCamp === 'doctor'){
        //     let doctor = doctorLists.filter( (item:any)=>item.id === value)
        //     handleChangeNameValue(inputName, inputCamp, doctor[0])
        // }

        // if(inputCamp !== 'tipo_ortodoncia' && inputCamp !== 'doctor') 
        handleChangeNameValue(inputName, inputCamp, value)
    }

    return (
        <div className="wrapper">
            <Row style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '33% 33% 32.5%'}}>

                <FormEuro 
                    label='Presupuesto'
                    placeholder='Escribir cantidad'
                    value={data.presupuesto}
                    name='presupuesto'
                    handleChange={(value:any)=>handleChangeNameValue(inputName, 'presupuesto', value)}
                />

                <FormEuro 
                    label='Aceptado'
                    placeholder='Escribir cantidad'
                    value={data.aceptado}
                    name='aceptado'
                    handleChange={(value:any)=>handleChangeNameValue(inputName, 'aceptado', value)}
                    />

                <Col span={24}>
                    <Form.Item label='Doctor' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            onChange={(value:any)=>handleChange('empleado_id', value)}
                            value={data.empleado_id}
                            style={{width: '100%'}}>
                            {
                                doctorLists.map( (item:any, id:any)=>(
                                    <Option key={id} value={item.id}>{item.nombre} {item.apellidos}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>  

                <Col span={24}>
                    <Form.Item  label='Implantes presupuestados' name='implantes_presupuestados' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <InputNumber
                            onChange={(value:any)=>handleChangeNameValue(inputName, 'implantes_presupuestados', value)}
                            placeholder='Escribir cantidad'
                            value={data.implantes_presupuestados}
                            style={{width: '100%'}}
                            defaultValue={data.implantes_presupuestado} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item  label='Implantes presupuestados' name='implantes_aceptados' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <InputNumber
                            name='implantes_aceptados'
                            value={data.implantes_aceptados}
                            onChange={(value:any)=>handleChangeNameValue(inputName, 'implantes_aceptados', value)}
                            placeholder='Escribir cantidad'
                            style={{width: '100%'}}
                            defaultValue={data.implantes_aceptados} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default ImplatologiaForm
