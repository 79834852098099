import React, {useState} from 'react'

let selectorWrapperStyle = {
    display:'flex',
    width: '100%',
    background: 'white',
    overflow: 'scroll'
}

interface Props {
    data: any,
    selected: string,
    handleClick: any
}

export default function AboveTableSelector(props: Props) {
    let {data, selected, handleClick} = props

    return (
        <div style={selectorWrapperStyle}>
            {data && data.map( (item:any, id: any) =>(
                <OptionText selected={selected} handleClick={()=>handleClick(item.nombre)} key={id}>{item.nombre}</OptionText>
            ))}
        </div>
    )
}

interface OptionTextProps {
    selected: string,
    children: any,
    handleClick: any
}

const OptionText = (props:OptionTextProps) =>{
    let {selected, children, handleClick} = props

    return (
        <button 
            className={children === selected ? 'selected-option' : 'default-option'}
            onClick={handleClick}>
            {children}
        </button>
    )
} 