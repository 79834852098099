import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageHeader,
  Table,
  Modal,
  Checkbox,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Space
} from 'antd';
import exportIcon from '../../../assets/icon/export.svg';
import PlusOutlinedIcon from '../../../assets/icon/plusOutlined.svg';

import {
  fetchAll,
  editClinica,
  setSelectedRowKeys,
  deleteClinica,
  saveClinica
} from '../../../redux/clinicas/actions';

import styles from './styles.module.scss';

import { DownloadOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import CustomButton from '../../../components/custom-button';
import AddClinicaModal from './addClinicaModal';
import EditClinicaModal from './editClinicaModal';

function Clinica() {
  const headers = [
    { label: 'CIF', key: 'cif' },
    { label: 'Razon social', key: 'razon_social' },
    { label: 'Nombre comercial', key: 'nombre_comercial' },
    { label: 'Observaciones', key: 'observaciones' },
    { label: 'Fecha alta', key: 'fecha_alta' },
    { label: 'Activo', key: 'email' },
  ];

  const columns = [
    {
      title: 'CIF',
      dataIndex: 'cif',
      Key:'cif',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'Razon social',
      dataIndex: 'razon_social',
      key: 'razon_social',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'Nombre comercial',
      dataIndex: 'nombre_comercial',
      key: 'nombre_comercial',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },
    {
      title: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      render: (text: any, record: any) => {
        const obj = {
          children: text ? text : '-',
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Fecha alta',
      dataIndex: 'fecha_alta',
      key: 'fecha_alta',
      render: (text: any, record: any) => {
        const obj = {
          children: moment(text).format('DD/MM/YYYY'),
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Activo',
      dataIndex: 'id',
      key: 'activo',
      render: (text: any, record: any) => {
        const obj = {
          children: <Checkbox checked={record.activo} />,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      render: (text: any, record: any) => {
        const obj = {
          children: <DeleteOutlined
          style={{ fontSize: '20px', color: '#47464E'}}
        />,
          props: {
            onClick: ()=>showModalDelete(record.id)
          }
        }
        return obj
      }
    }
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [idSelectedDelete, setIdSelectedDelete]:any = useState()
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
    cif: '',
    razon_social: '',
    nombre_comercial: '',
    numero_telefono: '',
    email: '',
    direccion: '',
    codigo_postal: '',
    pais: '',
    poblacion: '',
    contacto_nombre: '',
    contacto_numero: '',
    fecha_alta: '',
    fecha_baja: '',
    observaciones: ''
  });
  const [selectedUserName, setSelectedUserName] = useState('');

  const { home } = useSelector((state: any) => state.clinicas);

  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);

  useEffect(() => {
    if (idSelectedDelete) {
      const userr: any = data.find((e: any) => e.id === idSelectedDelete);
      setUser(userr);
    }
  }, [idSelectedDelete]);

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalDelete = (text: any) => {
    setIdSelectedDelete(text)
    setIsModalDeleteVisible(true)
  }

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.cif);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalDeleteVisible(false)
    setIsModalWatchVisible(false);
    setIsModalEditVisible(false);
    setIdSelectedEdit(0);
    setIdSelectedDelete(0)
    setUser({
      cif: '',
      razon_social: '',
      nombre_comercial: '',
      numero_telefono: '',
      email: '',
      direccion: '',
      codigo_postal: '',
      pais: '',
      poblacion: '',
      contacto_nombre: '',
      contacto_numero: '',
      fecha_alta: '',
      fecha_baja: '',
      observaciones: ''
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { editError, editOk } = useSelector((state: any) => state.clinicas);
  const { Text } = Typography;

  const handleOk = (submittedForm: any) => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(editClinica(submittedForm));
  };

  const handleOkDelete = async () => {
    setIsModalDeleteVisible(false)
    await dispatch(deleteClinica(idSelectedDelete))
    setIdSelectedDelete('')
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit]);

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedDelete]);


  const { loading, clinicas } = useSelector((state: any) => state.clinicas);

  const [data, setData] = useState(clinicas || [{}]);

  useEffect(() => {
    setData(clinicas);
  }, [clinicas]);

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      clinicas.forEach((user: any) => {
        const { cif, email, phone } = user;
        const superString = `${cif},${email},${phone}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(clinicas);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuracion / Clínicas',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.clinicas);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const onsubmit = (form: any)=> {
      dispatch(saveClinica(form))
  }

  return (
    <div>
      <PageHeader title="Clínicas" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">
          <Dropdown overlay={menu}>
            <Button>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
            />
          </div>
        <Space style={{right: '0'}}>

          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={'clinicas.csv'}
            separator={','}
          >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          
          <CustomButton 
            style={{
                backgroundColor: '#245046',
                width: '97px',
                height: '39px',
                color: '#ffffff',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-around',
                cursor: 'pointer'
            }}
            onClick={()=>setIsModalAddVisible(true)}  
            >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>

          <AddClinicaModal isVisible={isModalAddVisible} onClose={()=>setIsModalAddVisible(false)} onSubmit={onsubmit}/>

        </Space>
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
          scroll={{x: true}}
        />

        <EditClinicaModal 
          isVisible={isModalEditVisible}
          onClose={handleCancel}
          onSubmit={handleOk}
          clinica={user}
        />

        
        <Modal
          visible={isModalDeleteVisible}
          onCancel={handleCancel}
          onOk={handleOkDelete}
          title='Eliminar clínica'
          >
            <h3>¿Estás seguro que deseas eliminar "{user.nombre_comercial}”?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Clinica;
