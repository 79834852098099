import CajaDiaServices from "./services";

import acumuladoConstants from "./constants";

const {
    SET_LOADING,
    SET_PPTOS_TIPOLOGIA,
    SET_PPTOS_TIPOS_PAGOS,
    SET_COBROS,
    SET_ESPECIALIDAD_TABLE,
    SET_SELECTED
} = acumuladoConstants

export const setLoadingPptos = (loading: boolean) => ({
    type: SET_LOADING,
    loading,
  });
  
  const setPPtosTipologia = (data: any) => ({
    type: SET_PPTOS_TIPOLOGIA,
    data,
  });
  
  export const fetchPptosTipologia = (date: any, filters: any) => {
    return (dispatch: any) => {
      dispatch(setLoadingPptos(true));
      CajaDiaServices.fetchPptosTipologia(date, filters).then((res) => {
        dispatch(setLoadingPptos(false));
        return dispatch(setPPtosTipologia(res.data));
      });
    };
  };
  
  const setPptosTiposPagos = (data:any)=>({
    type: SET_PPTOS_TIPOS_PAGOS,
    data
  })

  export const fetchPptosTiposPagos = (date:any, filters:any)=>{
    return (dispatch: any)=>{
        dispatch(setLoadingPptos(true));
        CajaDiaServices.fetchPptosTiposPagos(date, filters).then((res) => {
          dispatch(setLoadingPptos(false));
          return dispatch(setPptosTiposPagos(res.data));
      })
    }
  }


  const setCobros = (data:any)=>({
    type: SET_COBROS,
    data
  })

  export const fetchCobros = (date:any, filters:any)=>{
    return (dispatch: any)=>{
        dispatch(setLoadingPptos(true));
        CajaDiaServices.fetchCobros(date, filters).then( (res)=>{
          dispatch(setLoadingPptos(false))
          return dispatch(setCobros(res.data))
        })
    }
  }
  
  const setEspecialidad = (data:any) =>({
    type: SET_ESPECIALIDAD_TABLE,
    data
  })

  export const fetchEspecialidad = (date:any, filters: any, especialidadName: string)=>{
    return (dispatch: any)=>{
        dispatch(setLoadingPptos(true));
        CajaDiaServices.fetchEspecialidad(date, filters, especialidadName).then( (res:any)=>{
        dispatch(setLoadingPptos(false));
        return dispatch(setEspecialidad(res.data))
      })
    }
  }

  export const setSelected = (data: any)=>({
    type: SET_SELECTED,
    data
  })