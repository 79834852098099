import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {Table} from 'antd'
import formatToEUR from '../../../../../../helpers/formatToEuro'
import formatToPercent from '../../../../../../helpers/formatToPercent'

import {
  getTipologiaDoctores,
} from '../../../../../../redux/global/actions';


interface Props {
    pageSize: any
    data: any
    loading: any
}

export default function ResumenAsesores(props: Props) {
    let {pageSize, loading, data} = props

    const columns = [
      {
        title: 'Clinica',
        dataIndex: 'clinica',
        Key:'clinica'
      },{
        title: 'Asesor',
        dataIndex: 'asesor',
        Key:'asesor'
      },{
        title: 'Doctor',
        dataIndex: 'user',
        Key:'user'
      },{
        title: 'Tipología',
        dataIndex: 'tipologia',
        Key:'tipologia'
      },{
        title: 'Presupuestos',
        dataIndex: 'presupuestos',
        Key:'presupuestos',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.presupuestos) - parseInt(b.presupuestos),
          multiple: 3,
        },
      },{
        title: 'Aceptados',
        dataIndex: 'aceptados',
        Key:'aceptados',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.aceptados) - parseInt(b.aceptados),
          multiple: 3,
        },
      },{
        title: 'Presupuestado',
        dataIndex: 'presupuestado',
        Key:'presupuestado',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.presupuestado) - parseInt(b.presupuestado),
          multiple: 3,
        },
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.presupuestado)
            }
          </>
        )
      },{
        title: 'Aceptado',
        dataIndex: 'aceptado',
        Key:'aceptado',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.aceptado) - parseInt(b.aceptado),
          multiple: 3,
        },
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.aceptado)
            }
          </>
        )
      },{
        title: 'Presupuesto medio',
        dataIndex: 'presupuestoMedio',
        Key:'presupuestoMedio',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.presupuestoMedio) - parseInt(b.presupuestoMedio),
          multiple: 3,
        },
        render: (index: any, record: any)=>(
          <>
            {
              record && formatToEUR(record.presupuestoMedio)
            }
          </>
        )
      }
    ];

    const filters = useSelector ( (state: any)=> state.Filters)
    const { date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
    const dispatch = useDispatch()  

  useEffect( ()=>{
    dispatch(getTipologiaDoctores({...date, last: lastDates, filters}))
  },[date, lastDates, filters])

  useEffect( ()=>{
    dispatch(getTipologiaDoctores({...date, last: lastDates, filters}))
  },[])

  return (
        <Table
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />
    )
}
