import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";

import formatToPercentage from "../../../../../../../helpers/formatToPercent";
import formatToEUR from "../../../../../../../helpers/formatToEuro";

let styleLight = {
  paddding: "2em",
  display: "grid",
  gridTemplateColumns: "50% 50%",
  justifyItems: "center",
};

let styleDark = {
  paddding: "2em",
  background: "rgba(143, 192, 170, 0.25)",
  display: "grid",
  gridTemplateColumns: "50% 50%",
  justifyItems: "center",
};

interface Props {
  data: any;
  label: any;
}

function ApexChart(props: Props) {
  let { data, label } = props;

  return (
    <div id="chart" style={{ padding: "1em", display: "grid", gridGap: "1em" }}>
      {data.map((item: any, index: any) => {
        const resultPercent: any = (item.percent * 100) / item.amount;

        return (
          <div key={index} style={index % 2 === 0 ? styleLight : styleDark}>
            <div className="">
              <span>{item.nombre}</span>
            </div>
            <div className="">
              <span>
                {label === "PRESUPUESTOS MEDIO POR TIPOLOGÍA" ||
                label === "VENTA MEDIA POR TIPOLOGÍA" ? (
                  <>
                    <Tooltip title={"Media"}>
                      <span>{`${formatToEUR(item.percent)} | `}</span>
                    </Tooltip>
                    <Tooltip title={"Total"}>
                      <span>{`${formatToEUR(item.amount)} | `}</span>
                    </Tooltip>
                    <Tooltip title={"%"}>
                      <span>{`${formatToPercentage(resultPercent)}`}</span>
                    </Tooltip>
                  </>
                ) : label === "ACEPTACIÓN POR ESPECIALIDAD" ||
                  label === "REFERIDOS POR ESPECIALIDAD" ? (
                  <span>
                    <Tooltip title={"Aceptado"}>
                      <span>{`${item.amount} `}</span>
                    </Tooltip>
                    <span>de</span>
                    <Tooltip title={"Total"}>
                      <span>{` ${item?.total} `}</span>
                    </Tooltip>
                    <Tooltip title={"%"}>
                      <span>{`(${formatToPercentage(resultPercent)})`}</span>
                    </Tooltip>
                  </span>
                ) : label === "MIX DE VENTA POR ESPECIALIDAD" ? (
                  <span>
                    <Tooltip title={"Venta total"}>
                      <span>{`${formatToEUR(item.amount)} `}</span>
                    </Tooltip>
                    <Tooltip title={"%"}>
                      <span>{`(${formatToPercentage(item.percent)})`}</span>
                    </Tooltip>
                  </span>
                ) : (
                  `${formatToPercentage(item.percent)}(${item.amount})`
                )}

                {/* {`${formatToPercentage(item.percent)}(${item?.amount})`} */}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ApexChart;
