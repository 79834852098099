import EntidadesServices from "./services";

import centrosConstants from "./constants";

const {
    SET_ENTIDADES,
    SET_LOADING_ENTIDADES,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_ENTIDADES
} = centrosConstants

export const setLoadingEntidades = (isLoadingEntidades: boolean) => ({
    type: SET_LOADING_ENTIDADES,
    isLoadingEntidades,
  });
  
  const setEntidades = (entidades: any) => ({
    type: SET_ENTIDADES,
    entidades,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingEntidades(true));
      EntidadesServices.fetchAll().then((res) => {
        dispatch(setLoadingEntidades(false));
        return dispatch(setEntidades(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_ENTIDADES, SET_EDIT_ERROR, SET_EDIT_OK } = centrosConstants;
  const setEditingEntidades = (isEditingEntidades: boolean) => ({
    type: SET_EDITING_ENTIDADES,
    isEditingEntidades,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const editEntidades = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingEntidades(true));
      dispatch(setEditError(null));
      dispatch(setEditOk(false))
      EntidadesServices
        .update(
          data.id,
          data.cif,
          data.razon_social,
          data.nombre_comercial,  
          
          data.direccion,
          data.codigo_postal,
      
          data.pais,
          data.poblacion,
      
          data.centro,
          data.numero_telefono,
      
          data.contacto_nombre,
          data.contacto_numero,
          data.contacto_email,
      
          data.activo,
      
          data.observaciones
        )
        .then((res: any) => {
          dispatch(setEditingEntidades(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
        })
        .catch((e) => {
          if (e.response.status === 409){
            dispatch(setEditError('exist'))
          } else if (e.response.status === 422) {
            dispatch(setEditError('form'))
          }else {
            dispatch(setEditError('error'))
          }
          dispatch(setEditingEntidades(false));
        });
    };
  };
  
  export const deleteEntidad = (data: any) => {
    return (dispatch: any) =>{
      EntidadesServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") dispatch(fetchAll())
      })
      .catch(e => console.log(e))
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingEntidades = (saving: boolean | null)=>({
    type: SET_SAVING_ENTIDADES,
    saving
  })

  export const saveEntidades = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingEntidades)
      dispatch(savingEntidades(true))
      EntidadesServices.save(
        data.cif,
        data.razon_social,
        data.nombre_comercial,  
        
        data.direccion,
        data.codigo_postal,
    
        data.pais,
        data.poblacion,
    
        data.centro,
        data.numero_telefono,
    
        data.contacto_nombre,
        data.contacto_numero,
        data.contacto_email,
    
        data.activo,
    
        data.observaciones,
        ).then(res =>{
            dispatch(setEditOk(true))
            dispatch(fetchAll())
            setTimeout(()=>dispatch(setEditOk(false)), 200)
        })
        .catch( (e)=>{
          if (e.response.status === 409){
            dispatch(setEditError('exist'))
            setTimeout(()=>dispatch(setEditError(null)), 200)
          } else if (e.response.status === 422) {
            alert('asdasdnaksdn')
            dispatch(setEditError('form'))
            setTimeout(()=>dispatch(setEditError(null)), 200)
          }else {
            dispatch(setEditError('error'))
            setTimeout(()=>dispatch(setEditError(null)), 200)
          }
          dispatch(savingEntidades(false));
        })
    }catch(e){

    }
  }
}