import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Checkbox} from 'antd'
import { RuleObject } from 'rc-field-form/lib/interface'

import ButtonSection from '../../../../../../components/button-sections'
import Item from 'antd/lib/list/Item'

interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
    data: any

    tiposEmpleadosLists: any
    centrosLists:any
    especialidadesLists: any

    handleChange: any
    handleChangeDate: any
    handleChangeNameValue: any
}

export default function Index(props:Props) {
    const {
        isVisible, 
        onClose, 
        onSubmit, 
        data, 

        centrosLists,
        tiposEmpleadosLists,
        especialidadesLists, 
        } = props

    const {Option} = Select
    const {TextArea} = Input
    const [form] = Form.useForm()

    const handleOk = () =>{
        form
            .validateFields()
            .then( (fields:any)=>{
                onSubmit(fields)
                onClose()
            })
    }
    
    const [formData, setFormData] = useState(
        data ? data : {}
    )
    const [checked, setchecked] = useState([]);
    const [hasEspeciality, setHasEspeciality] = useState(false)
    const [especialidadesArray, setEspecialidadesArray] = useState([])

    const sortFromList = (name: any, value:any) =>{
        if(name === 'empleado'){
            let sortedValue = tiposEmpleadosLists.filter( (index:any)=> index.id === value)
            if(sortedValue){
                if(sortedValue[0].tieneEspecialidad === 'Si') 
                    if(!hasEspeciality) setHasEspeciality(true)
                if(sortedValue[0].tieneEspecialidad === 'No') {
                    if(hasEspeciality) setHasEspeciality(false)
                    
                }
            }
        }
    }

    
    useEffect(() => {
        if(data.especialidad){
            setEspecialidadesArray(data.especialidad)
        }

        if(!data.especialidad){
            setEspecialidadesArray( especialidadesLists.map( (item:any)=>{
                let {id, nombre} = item
                return {value: id, label: nombre, checked: false}
            }))
        }
    }, [especialidadesLists, data]);
    
    useEffect(() => {
        setFormData(data)
        form.resetFields()
        data.empleado && sortFromList('empleado', data.empleado)
    }, [data]);
    
    useEffect(() => {
        form.resetFields()
    }, [formData]); 

    return (
        <Modal
            title="Añadir empleado"
            visible={isVisible}
            onCancel={onClose}
            width={1020}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            footer={null}>
                
                <Form onFinish={handleOk} form={form} initialValues={{...formData, especialidadesArray: data.especialidadChecked}}>
                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '33% 33% 32%'}}> 
                        <Col span={24}>
                            <Form.Item 
                                label='Nombre' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='nombre'
                                rules={[{required: true, message: 'Por favor introduzca el nombre'}]}>
                            
                                <Input 
                                    // name='nombre'
                                    // value={data.nombre}
                                    // onChange={handleChange}
                                    />
                            
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Apellidos' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='apellidos'
                                rules={[{required: true, message: 'Por favor introduzca el apellido'}]}>
                                
                                <Input 
                                    // name='apellidos'
                                    // value={data.apellidos}
                                    // onChange={handleChange}
                                    />

                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='DNI' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='dni'
                                rules={[{required: true, message: 'Por favor introduzca el dni'}]}>
                                
                                <Input />

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '33% 33% 32%'}}> 
                        <Col span={24}>
                            <Form.Item 
                                label='Email' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='email'
                                rules={
                                    [
                                        { required: true, message: 'Por favor introduce tu email' },
                                        { type: 'email', message: 'Introduce un email válido' },
                                    ]}>
                            
                                <Input />
                            
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Teléfono' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='telefono'
                                rules={[{required: true, message: 'Por favor introduzca el telefono'}]}>
                                
                                <InputNumber 
                                    min={0}
                                    style={{width: '100%'}}    
                                    />

                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='Tipo de empleado' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='empleado'
                                rules={[{required: true, message: 'Por favor introduzca el dni'}]}>
                                
                                <Select
                                    //placeholder='Elige una opción'
                                    onChange={(value:any)=>sortFromList('empleado', value)}
                                    //value={data.empleado.nombre}
                                    >
                                    {
                                        tiposEmpleadosLists.map( (item:any, id:any)=>(
                                            <Option key={id} value={item.id}>{item.nombre}</Option>
                                        ))
                                    }
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        hasEspeciality && 
                            <Row>
                                <Form.Item
                                    labelCol={{span: 24}} 
                                    wrapperCol={{ span: 24}}
                                    label='Especialidad (puedes seleccionar más de una opción): '
                                    name='especialidadesArray'
                                    initialValue={data.especialidadChecked}
                                    valuePropName='especialidadesArray'
                                    rules={
                                        [{required: true, message: 'Por favor seleccione al menos una (1) opción'}]
                                    }
                                >
                                <Checkbox.Group
                                    options={especialidadesArray}
                                    defaultValue={data.especialidadChecked}
                                    />  
                                </Form.Item>
                            </Row>
                    }
                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '33% 33% 33%'}}> 
                        <Form.Item 
                            label='Centro' 
                            labelCol={{span: 24}} 
                            wrapperCol={{ span: 24}}
                            name='centro'
                            rules={[{required: true, message: 'Por favor introduzca el centro'}]}>
                            
                            <Select 
                                //onChange={(value:any)=>handleChangeNameValue('centro', value)}
                                //value={data.centro.clinica_nombre}
                                placeholder='Elige una opción'
                                style={{width:'100%'}}>
                                {
                                    centrosLists.map( (item:any, id:any)=>(
                                        <Option key={id} value={item.id}>{item.clinica_nombre}</Option>
                                    ))
                                }
                            </Select>

                        </Form.Item>
                        
                        <Form.Item 
                            label='Activo' 
                            labelCol={{span: 24}} 
                            wrapperCol={{ span: 24}}
                            name='activo'
                            valuePropName='checked'
                            >
                            <Checkbox 
                                />
                            
                        </Form.Item>
                    </Row>

                    
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label='OBSERVACIONES'  
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                style={{fontWeight: 'bold', fontSize: 20}}
                                name='observaciones'>
                                
                                <TextArea 
                                    // name="observaciones" 
                                    // onChange={handleChange}  
                                    // value={data.observaciones}
                                />
                            </Form.Item>
                        </Col>    
                    </Row>
                    <ButtonSection 
                        onCancel={onClose}
                        />
                </Form>
        </Modal>
    )
}
