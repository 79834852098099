import React from 'react'
import moment from 'moment'
import { Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker } from 'antd'

import ButtonSection from '../../../../../../../components/button-sections'
import FormEuro from '../../../parte_presupuestos/components/wrapper-forms/form_euro'

interface Props {
  isVisible: boolean
  onClose?: any
  onSubmit: any
  data: any

  tiposPagosLists: any
  centrosLists: any

  handleChange: any
  handleChangeDate: any
  handleChangeNameValue: any
}

export default function index(props: Props) {
  const {
    isVisible,
    onClose,
    onSubmit,
    data,

    centrosLists,
    tiposPagosLists,

    handleChange,
    handleChangeNameValue,
    handleChangeDate,
  } = props

  const { Option } = Select
  const { TextArea } = Input

  return (
    <Modal
      title="Añadir pago"
      visible={isVisible}
      onCancel={onClose}
      width={1020}
      bodyStyle={{ backgroundColor: '#E3EFEA', display: 'grid' }}
      style={{ top: 20 }}
      footer={null}>
      <Form onFinish={onSubmit}>
        <Row style={{ display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 49%' }}>
          <Col span={24}>
            <Form.Item label='Centro' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value: any) => handleChangeNameValue('Centro', value)}
                value={data.Centro.clinica_nombre}
                placeholder='Selecciona una opción'
                style={{ width: '100%' }}>
                {
                  centrosLists.map((item: any) => (
                    <Option value={item.id}>{item.clinica_nombre}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Fecha de pago' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <DatePicker
                onChange={(value: any) => handleChangeDate('fecha_pago', value)}
                value={data.fecha_pago && moment(data.fecha_pago)}
                placeholder='dd/mm/aaaa'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 48%' }}>
          <Col span={24}>
            <Form.Item label='Tipo de pago' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value: any) => handleChangeNameValue('tiposPagos', value)}
                placeholder='Selecciona una opción'
                value={data.tiposPagos.nombre}
                style={{ width: '100%' }}>
                {
                  tiposPagosLists.map((item: any) => (
                    <Option value={item.id}>{item.nombre}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: 'grid', gridGap: '.5em', gridTemplateColumns: '95% 5%', alignItems: 'center' }}>
            <Form.Item label='Cantidad' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} className="form-wrapper">
              {data.tiposPagos.nombre === 'Devoluciones' ?
                <InputNumber
                  onChange={(value: any) => handleChangeNameValue('cantidad', value)}
                  style={{ width: '100%' }}
                  className='Escribir cantidad'
                  value={data.cantidad}
                  max={0}
                />
                :
                <InputNumber
                  onChange={(value: any) => handleChangeNameValue('cantidad', value)}
                  style={{ width: '100%' }}
                  className='Escribir cantidad'
                  value={data.cantidad}
                  min={0}
                />
              }
            </Form.Item>

            <span className="form-span">
              €
                            </span>
          </Col>
        </Row>

        <Row style={{ display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 49%' }}>
          <Col span={24}>
            <Form.Item label='Nº de pagos' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <InputNumber
                onChange={(value: any) => handleChangeNameValue('numero_pagos', value)}
                style={{ width: '100%' }}
                className='Escribir cantidad'
                min={0}
                value={data.numero_pagos}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label='OBSERVACIONES'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ fontWeight: 'bold', fontSize: 20 }}>

              <TextArea
                name="observaciones"
                onChange={handleChange}
                value={data.observaciones}
              />
            </Form.Item>
          </Col>
        </Row>
        <ButtonSection
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
