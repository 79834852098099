const cajaDiaConstants = {
    SET_LOADING_CAJA_DIA: 'SET_LOADING_CAJA_DIA',
    SET_CAJA_DIA: 'SET_CAJA_DIA',
    SET_EDITING_CAJA_DIA: 'SET_EDITING_CAJA_DIA',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_CAJA_DIA: 'SET_SAVING_CAJA_DIA'
  };
  
  export default cajaDiaConstants;
  