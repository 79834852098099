import api from '../../helpers/api'

const CentrosServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/centros')
    },
    save: (
        clinica_nombre:any, 
        clinica_id:any, 

        direccion:any, 
        codigo_postal:any, 

        pais:any, 
        poblacion:any, 

        consultor:any, 
        numero_telefono:any, 

        contacto_nombre:any, 
        contacto_numero:any, 
        
        email:any, 
        especialidades_data:any, 

        observaciones:any,
    )=>{
        return api.post('/admin/save/centro',{
            clinica_nombre, 
            clinica_id, 

            direccion, 
            codigo_postal, 

            pais, 
            poblacion, 

            consultor, 
            numero_telefono, 

            contacto_nombre, 
            contacto_numero, 
            
            email, 
            especialidades_data, 

            observaciones
        })
    },
    edit: (
        id:string,
        clinica_nombre:any, 
        clinica_id:any, 

        direccion:any, 
        codigo_postal:any, 

        pais:any, 
        poblacion:any, 

        consultor:any, 
        numero_telefono:any, 

        contacto_nombre:any, 
        contacto_numero:any, 
        
        email:any, 
        especialidades_data:any, 

        observaciones:any,
        activo: boolean
        )=>{
        return api.put('/admin/update/centro/'+id,{
            clinica_nombre, 
            clinica_id, 

            direccion, 
            codigo_postal, 

            pais, 
            poblacion, 

            consultor, 
            numero_telefono, 

            contacto_nombre, 
            contacto_numero, 
            
            email, 
            especialidades_data, 

            observaciones,
            
            activo
        })
    },
    delete: (
        id: any
    )=>{
        return api.get('/admin/destroy/centros/'+id)
    },
    getProfile: (
        id: string
    )=>{
        return api.get('/admin/centros/'+id)
    }
}

export default CentrosServices