const entidadesConstants = {
    SET_LOADING_ENTIDADES: 'SET_LOADING_ENTIDADES',
    SET_ENTIDADES: 'SET_ENTIDADES',
    SET_EDITING_ENTIDADES: 'SET_EDITING_ENTIDADES',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_ENTIDADES: 'SET_SAVING_ENTIDADES'
  };
  
  export default entidadesConstants;
  