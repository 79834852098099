// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_login_form_container__1Rwve {\n  margin: 0 auto !important;\n  margin-top: 150px !important;\n  max-width: 300px;\n  text-align: center; }\n\n.styles_login_form_logo__3Xt1I {\n  margin-bottom: 40px;\n  width: 250px; }\n\n.styles_login_form_forgot__25uSz {\n  float: left;\n  color: #199479; }\n\n.styles_space__2TtvH {\n  margin-bottom: 10px !important; }\n", ""]);
// Exports
exports.locals = {
	"login_form_container": "styles_login_form_container__1Rwve",
	"login_form_logo": "styles_login_form_logo__3Xt1I",
	"login_form_forgot": "styles_login_form_forgot__25uSz",
	"space": "styles_space__2TtvH"
};
module.exports = exports;
