import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {Modal, Form, Row, Col, Input, Select, DatePicker, Checkbox} from 'antd'


import ButtonSection from '../../../../../../components/button-sections'

interface Props {
    type: string
    data: any
    handleChange: any,
    handleChangeSelect: any
    handleChangeDate: any
    clinicaLists: any
    centrosLists: any
    onSubmit: any
    onClose: any
}

function AddClinicaModal(props: Props) {
    const {
        type,
        data,
        handleChange,
        handleChangeSelect,
        handleChangeDate,
        clinicaLists,
        centrosLists,
        onSubmit,
        onClose } = props

    const {Option} = Select
    const {TextArea} = Input

    return (
        <>
                <Form onFinish={onSubmit}>
                    
                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Nombre de usuario'
                                labelCol={{span: 24}}
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message: 'El campo es obligatorio'}
                                ]}>
                                    <Input name="username" onChange={handleChange} value={data.username} placeholder='Escribe el Nombre de usuario' required/>
                            </Form.Item>
                        </Col>
                    
                        <Col span={24}>
                            <Form.Item 
                                label='Contraseña' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message: 'El campo es obligatorio'}
                                ]}>
                                <Input name="password" onChange={handleChange} value={data.password} placeholder='Escribe la Contraseña'/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label='Rol de usuario' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select onChange={(e)=>handleChangeSelect('role', e)} value={data.role} placeholder='Elige una opción'>
                                    <Option value=''>Escoga el rol</Option>
                                    <Option value='Admin'>Admin</Option>
                                    <Option value='Consultor'>Consultor</Option>
                                    <Option value='Gerente'>Gerente</Option>
                                    <Option value='Director'>Director</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                            <Form.Item 
                                label='Clínica asociada' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}} 
                                rules={[
                                    {required: true, message: 'El campo es obligatorio'}
                                ]}>
                                <Select onChange={(e)=>handleChangeSelect('clinica_id', e)} value={data.clinica_name} placeholder='Elige una opción'>
                                    {
                                        clinicaLists && clinicaLists.map( (item:any, i:string)=>(
                                            <Option  key={i} value={item.id}>{item.nombre_comercial}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                           
                            <Form.Item label='Centro asociado' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select onChange={(e)=>handleChangeSelect('centros_id', e)} value={data.centro_name} placeholder='Elige una opción'>
                                    {
                                        centrosLists && centrosLists.map( (item:any, i:string)=>(
                                            <Option key={i} value={item.id}>{item.clinica_nombre}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        
                            <Col span={24}>
                                <Form.Item 
                                    label='Nombre' 
                                    labelCol={{span: 24}} 
                                    wrapperCol={{ span: 24}}
                                    rules={[
                                        {required: true, message: 'El campo es obligatorio'}
                                    ]}>
                                    <Input name="name" onChange={handleChange} value={data.name} placeholder='Escribe el nombre'/>
                                </Form.Item>
                            </Col>
                    </Row>
                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Apellido' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message: 'El campo es obligatorio'}
                                ]}>
                                <Input name="lastname" onChange={handleChange} value={data.lastname} placeholder='Escribe el apellido'/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item 
                                label='DNI' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    {required: true, message:'Por favor introduce su DNI'} 
                                ]}>
                                <Input name="dni" onChange={handleChange} value={data.dni} placeholder='Escribe el dni'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Email' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                { required: true, message: 'Por favor introduce tu email' },
                                { type: 'email', message: 'Introduce un email válido' },
                                ]}>
                                <Input name="email" onChange={handleChange} value={data.email} placeholder='Escribe la dirección de correo'/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Teléfono' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                rules={[
                                    { required: true, message: 'Por favor introduce tu email' },
                                    { type: 'number', message: 'Solo se pueden introducir numeros' },
                                ]}>
                                <Input name="telefono" onChange={handleChange} value={data.telefono} placeholder='Escribe el número'/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label='Fecha de alta' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                {
                                    type === 'edit' ?
                                        <DatePicker defaultValue={data.fecha_alta && moment(data.fecha_alta)} onChange={(e)=>handleChangeDate(e, 'fecha_alta')} style={{width: '100%'}} />
                                    :
                                        <DatePicker name="fecha_alta" onChange={(e)=>handleChangeDate(e, 'fecha_alta')} style={{width: '100%'}}/>
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Fecha de baja' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                {
                                    type === 'edit' ?
                                        <DatePicker defaultValue={data.fecha_baja && moment(data.fecha_baja)} onChange={(e)=>handleChangeDate(e, 'fecha_baja')} style={{width: '100%'}} />
                                    :
                                        <DatePicker name="fecha_baja" onChange={(e)=>handleChangeDate(e, 'fecha_baja')} style={{width: '100%'}}/>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Activo' labelCol={{span: 24}} wrapperCol={{ span: 24}} rules={[{required: true, message: 'El campo es obligatorio'}]}>
                                <Checkbox name="activo" onChange={handleChange} checked={data.activo} />
                            </Form.Item>
                        </Col>
                    </Row>    
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Observaciones'  labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <TextArea name="observaciones" onChange={handleChange} value={data.observaciones}/>
                            </Form.Item>
                        </Col>    
                    </Row>
                    <ButtonSection onCancel={onClose} />
                </Form>
        </>
    )
}

export default AddClinicaModal


