import api from '../../helpers/api'

const CajaDiaServices = {
    fetchPptosTipologia: (
        date: any,
        filters: any
    )=>{
        return api.post('/admin/list/resumen/pptos_tipologia', {
            date,
            filters
        })
    },
    fetchPptosTiposPagos: (
        date: any,
        filters: any
    )=>{
        return api.post('/admin/list/resumen/pptos_tipos_pagos', {
            date,
            filters
        })
    },
    fetchCobros: (
        date: any,
        filters: any
    )=>{
        return api.post('/admin/list/resumen/cobros', {
            date,
            filters
        })
    },
    fetchEspecialidad: (
        date: any,
        filters: any,
        especialidadName: string
    )=>{
        return api.post('/admin/list/resumen/especialidad', {
            date,
            filters,
            especialidadName
        })
    },
}

export default CajaDiaServices