import api from '../../helpers/api'

const EspecialidadesServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/especialidades')
    },
    save: (
        nombre: string
    )=>{
        return api.post('/admin/save/especialidades',{
            nombre
        })
    },
    destroy: (
        id:string,
        )=> {
            return api.get('/admin/destroy/especialidades/'+id)
    }
}

export default EspecialidadesServices