import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {Modal, Form, Row, Col, Input, InputNumber,Select, DatePicker} from 'antd'

import {saveClinica} from '../../../redux/clinicas/actions'
import reformatDate from '../../../helpers/reformatDate'

import ButtonSection from '../../../components/button-sections'

interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
}

function AddClinicaModal(props: Props) {
    const {isVisible, onClose, onSubmit} = props

    const format = 'DD/MM/YYYY'

    const {Option} = Select
    const {TextArea} = Input
    const [form] = Form.useForm()
    
    const [data, setData] = useState({
        cif: '',
        razon_social: '',
        nombre_comercial: '',
        telefono: undefined,
        email: '',
        direccion: '',
        codigo_postal: '',
        pais: '',
        poblacion: '',
        contacto_persona: '',
        contacto_numero: undefined,
        fecha_alta: null,
        fecha_baja: null,
        observaciones:  ''
    })

    const {paisLists} = useSelector((state: any)=>state.admins)


    const handleChange=(event: any)=>{
        let {name, value} = event?.target
        setData({...data, [name]:value})
    }

    const handleChangeSelect = (name: string, value: string)=>{
        setData({...data, [name]:value})
    }

    const handleChangeDate = (e:any, name: string)=>{
        if(e){
        let date = e.format()
        // let newDate = reformatDate(date)
        setData({...data, [name]:date})

        }else{
            setData({...data, [name]: ''})
        }    
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        handleClose()
    }

    const handleClose = ()=>{
        setData({
            cif: '',
            razon_social: '',
            nombre_comercial: '',
            telefono: undefined,
            email: '',
            direccion: '',
            codigo_postal: '',
            pais: '',
            poblacion: '',
            contacto_persona: '',
            contacto_numero: undefined,
            fecha_alta: null,
            fecha_baja: null,
            observaciones:  ''
        })
        onClose()
    }

    useEffect(() => {
        form.resetFields()
    }, []);

    const onSend = ()=>{
        form
        .validateFields()
        .then( (fields:any)=>{
            onSubmit(fields)
            handleClose()
            form.resetFields()
            // onSubmit({...fields,DerivadoEspecialidades: fields.especialidadesArray, EspecialidadesPresupuestosAceptados: tempState, total})
            //onClose()
        })
    }

    return (
        <Modal
            title="Añadir clínica"
            visible={isVisible}
            onCancel={handleClose}
            width={1020}
            footer={false}
            //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{borderRadius:'2px', top: 20}}
            okText="Guardar"
            cancelText="Cancelar"
            >
                <Form form={form} onFinish={onSend} initialValues={data}>
                    
                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='CIF'
                                labelCol={{span: 24}}
                                wrapperCol={{ span: 24}}
                                name='cif'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }
                                >
                                <Input name="cif" onChange={handleChange}  placeholder='Escribe el CIF'/>
                            </Form.Item>
                        </Col>
                    
                        <Col span={24}>
                            <Form.Item 
                                label='Razón social' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='razon_social'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Input name="razon_social" onChange={handleChange}  placeholder='Escribe la razon social'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Nombre Comercial' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='nombre_comercial'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Input name="nombre_comercial" onChange={handleChange}  placeholder='Escribe el nombre'/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Teléfono' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='telefono'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <InputNumber 
                                    name="telefono" 
                                    onChange={(value:any)=>handleChangeSelect('telefono', value)} 
                                    
                                    placeholder='Escribe el telefono'
                                    style={{width: '100%'}}
                                    />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Email' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='email'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Input name="email" onChange={handleChange}  placeholder='Escribe la dirección de correo'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Dirección' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='direccion'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Input name="direccion" onChange={handleChange}  placeholder='Escribe la dirección'/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Código postal' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='codigo_postal'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Input name="codigo_postal" onChange={handleChange}  placeholder='Escribe el código postal'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Selecciona un país' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='pais'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Select onChange={(e: any)=>handleChangeSelect('pais', e)}  placeholder='Elige una opción'>
                                    <Option value=''> ----------</Option>
                                    {
                                        paisLists.map( (item: any)=>(
                                            <Option key={item.id} value={item.id}>{item.nombre}</Option>     
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Selecciona una poblacion' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='poblacion'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                    <Input name="poblacion" onChange={handleChange}  placeholder='Escriba la población'/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Persona de contacto' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='contacto_persona'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <Input name="contacto_persona" onChange={handleChange}  placeholder='Escribe el nombre'/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label='Telefono de contacto' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='contacto_numero'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <InputNumber 
                                    name="contacto_numero" 
                                    onChange={(value:any)=>handleChangeSelect('contacto_numero', value)} 
                                     
                                    placeholder='Escribe el teléfono'
                                    style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{display: 'grid', gridGap:'1em', gridTemplateColumns: '315px 315px 315px', alignContent:'center', alignItems:'center'}}>
                        <Col span={24}>
                            <Form.Item 
                                label='Fecha de alta' 
                                labelCol={{span: 24}} 
                                wrapperCol={{ span: 24}}
                                name='fecha_alta'
                                rules={
                                    [{
                                        required: true,
                                        message: 'El campo no puede quedar vacio'
                                    }]
                                }>
                                <DatePicker 
                                name="fecha_alta" 
                                format={format}
                                onChange={(e)=>handleChangeDate(e, 'fecha_alta')}  
                                value={data.fecha_alta && moment(data.fecha_alta, 'dd/mm/yyyy')}  
                                style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Fecha de baja' labelCol={{span: 24}} wrapperCol={{ span: 24}} name='fecha_baja'>
                                <DatePicker 
                                    name="fecha_baja" 
                                    format={format}
                                    onChange={(e)=>handleChangeDate(e, 'fecha_baja')} 
                                    value={data.fecha_baja && moment(data.fecha_baja, 'dd/mm/yyyy')} 
                                    style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Observaciones'  labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <TextArea name="observaciones" onChange={handleChange} />
                            </Form.Item>
                        </Col>    
                    </Row>  
                    <ButtonSection 
                        onCancel={handleClose}
                        />
                </Form>
        </Modal>
    )
}

export default AddClinicaModal


