import api from '../../helpers/api'

const EntidadesServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/entidades_financieras')
    },
    save: (
        cif: any,
        razon_social: string | boolean,
        nombre_comercial: string | boolean,  
        
        direccion: string | boolean,
        codigo_postal: string | boolean,

        pais: string | boolean,
        poblacion: string | boolean,

        centro: string | boolean,
        numero_telefono: string | boolean,

        contacto_nombre: string | boolean,
        contacto_numero: string | boolean,
        contacto_email: string | boolean,

        activo: boolean,

        observaciones: string | boolean,
    )=>{
        return api.post('/admin/save/entidades_financieras',{
            cif,
            razon_social,
            nombre_comercial,  
            
            direccion,
            codigo_postal,
        
            pais,
            poblacion,
        
            centro,
            numero_telefono,
        
            contacto_nombre,
            contacto_numero,
            contacto_email,
        
            activo,
        
            observaciones,
        })
    },
    update: (
        id: string,
        cif: any,
        razon_social: string | boolean,
        nombre_comercial: string | boolean,  
        
        direccion: string | boolean,
        codigo_postal: string | boolean,

        pais: string | boolean,
        poblacion: string | boolean,

        centro: string | boolean,
        numero_telefono: string | boolean,

        contacto_nombre: string | boolean,
        contacto_numero: string | boolean,
        contacto_email: string | boolean,

        activo: boolean,

        observaciones: string | boolean,
    )=>{
        return api.put('/admin/update/entidades_financieras/'+id,{
            cif,
            razon_social,
            nombre_comercial,  
            
            direccion,
            codigo_postal,
        
            pais,
            poblacion,
        
            centro,
            numero_telefono,
        
            contacto_nombre,
            contacto_numero,
            contacto_email,
        
            activo,
        
            observaciones,
        })
    },
    delete: (
        id: any
    )=>{
        return api.get('/admin/destroy/entidades_financieras/'+id)
    },
}

export default EntidadesServices