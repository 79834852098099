import React, {useState} from 'react'
import moment from 'moment'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker, Alert} from 'antd'

import ButtonSection from '../../../../../../../components/button-sections'
import FormEuro from '../../../parte_presupuestos/components/wrapper-forms/form_euro'
import {savePrevisiones} from "../../../../../../../redux/previsiones/actions";

interface Props {
    isVisible: boolean
    onClose?: any
    onSubmit: any
    data: any

    directorLists: any
    centrosLists:any
    pacienteLists:any

    handleChange: any
    handleChangeDate: any
    handleChangeNameValue: any
}

function Index(props:Props) {
    const {
        isVisible,
        onClose,
        onSubmit,
        data,

        directorLists,
        centrosLists,
        pacienteLists,

        handleChange,
        handleChangeNameValue,
        handleChangeDate,
        } = props


    const {Option} = Select
    const {TextArea} = Input

    const [error, setError] = useState('');

    const beforeonsubmit = (form: any) => {
        if (!data.fecha_prevision) {
            setError("Introduzca una fecha");
            return;
        }
        if (!data.cantidad) {
            setError("Introduzca una cantidad");
            return;
        }
        if (data.Centro.id == '' || data.Centro.id == null) {
            setError("seleccione un centro");
            return;
        }
        if (data.User.id == '' || data.User.id == null) {
            setError("seleccione un asesor");
            return;
        }
        if (data.Paciente.id == '' || data.Paciente.id == null) {
            setError("seleccione un paciente");
            return;
        }
        onSubmit(form);
    }
    return (
        <Modal
            title="Añadir previsión"
            visible={isVisible}
            onCancel={onClose}
            width={1020}
            bodyStyle={{backgroundColor: '#E3EFEA', display: 'grid'}}
            style={{top: 20}}
            footer={null}>
            {error && (
            <Alert
                message=""
                description={error}
                type="error"

            />)}
                <Form onFinish={beforeonsubmit}>
                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 49%'}}>
                        <Col span={24}>
                            <Form.Item label='Fecha previsión de cobro' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <DatePicker
                                    onChange={(value:any)=>handleChangeDate('fecha_prevision', value)}
                                    value={data.fecha_prevision && moment(data.fecha_prevision)}
                                    placeholder='dd/mm/aaaa'
                                    style={{width:'100%'}}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <FormEuro
                                name='cantidad'
                                handleChange={(value:any)=>handleChangeNameValue('cantidad', value)}
                                placeholder='Escribir cantidad'
                                value={data.cantidad}
                                label='Cantidad'
                                />
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 49%'}}> 
                        <Col span={24}>
                            <Form.Item label='Centro' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select 
                                    onChange={(value:any)=>handleChangeNameValue('Centro', value)}
                                    value={data.Centro.nombre}
                                    placeholder='Selecciona una opción'
                                    style={{width:'100%'}}>
                                    {
                                        centrosLists.map( (item:any)=>(
                                            <Option value={item.id}>{item.clinica_nombre}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Asesor' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select 
                                    onChange={(value:any)=>handleChangeNameValue('User', value)}  
                                    placeholder='Selecciona una opción'
                                    value={data.User.nombre}
                                    style={{width:'100%'}}>
                                    {
                                        directorLists.map( (item:any)=>(
                                            <Option value={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{display: 'grid', gridGap: '1em', gridTemplateColumns: '49% 49%'}}> 
                        <Col span={24}>
                            <Form.Item label='Paciente' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                                <Select 
                                    onChange={(value:any)=>handleChangeNameValue('Paciente', value)}  
                                    placeholder='Selecciona una opción'
                                    value={data.Paciente.nombre}
                                    style={{width:'100%'}}>
                                    {
                                        pacienteLists.map( (item:any)=>(
                                            <Option value={item.id}>{item.nombre}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label='OBSERVACIONES'
                                labelCol={{span: 24}}
                                wrapperCol={{ span: 24}}
                                style={{fontWeight: 'bold', fontSize: 20}}>

                                <TextArea
                                    name="observaciones"
                                    onChange={handleChange}
                                    value={data.observaciones}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <ButtonSection
                        onCancel={onClose}
                        />
                </Form>
        </Modal>
    )
}
export default Index