import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {Row, Col, Checkbox, InputNumber, Input, Typography, Form, DatePicker, Select} from 'antd'
import {useSelector} from 'react-redux'

import formatToEUR from '../../../../../../helpers/formatToEuro'

interface Props {
    especialidadesLists: any
    data: any
    tiposPagosLists: any
    entidadesFinancierasLists: any
    assist: any

    fecha_etto: any
    setFecha_etto: any
    setAssist: any

    sortFromList: any
    eachRow: any
    handleChange: any
    handleChangeDate: any
    handleChangeNameValue: any
}

function Derivada(props: Props) {
    const {
        especialidadesLists, 
        data,
        tiposPagosLists,
        entidadesFinancierasLists,
        assist,
        
        fecha_etto,
        setFecha_etto,
        setAssist,

        sortFromList,
        handleChangeDate,
        handleChangeNameValue,
        eachRow, 
    } = props
    
    const {Title} = Typography
    const {Option} = Select
    const [form] = Form.useForm()

    const [especialidadesArray, setEspecialidadesArray] = useState([])
    const [checked, setchecked] = useState([]);
    
    useEffect(() => {
        if(!data.DerivadoEspecialidades){
            let especialidadesArray = especialidadesLists.map( (item:any)=>{
                return {value: item.id, label: item.nombre}
            })
            setEspecialidadesArray(especialidadesArray)
        }

        if(data.DerivadoEspecialidades){
            // console.log(alert('aaa'))
            let especialidadesArray = especialidadesLists.map( (item:any)=>{
                return {value: item.id, label: item.nombre}
            })
            setEspecialidadesArray(especialidadesArray)

            let checkedArray = data.DerivadoEspecialidades.map( (item:any)=>{
                return item.especialidades_id
            })
            setchecked(checkedArray)
        }
        form.resetFields()
    }, [data.DerivadoEspecialidades, especialidadesLists])
    return (
        <>  
            <Row>
                <Form.Item 
                    label='Especialidad derivación:'
                    valuePropName='especialidadesArray'
                    name='especialidadesArray'

                    rules={[
                        {
                            validator: (_, value) =>{
                                if (checked.length > 0) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject('Por favor seleccione al menos una (1) opción');
                                }
                            }
                        }
                    ]}
                   labelCol={{span: 24}} wrapperCol={{span: 24}}>
                    <Checkbox.Group 
                        options={especialidadesArray} 
                        onChange={(value: any)=>setchecked(value)}

                        defaultValue={checked}
                        value={checked}
                        />
                </Form.Item>
            </Row>

            <Row>
                <Title style={{fontSize: 20}}>DATOS DE ASISTENCIA Y MÉTODO DE PAGO</Title>
                
                <Row style={{width:'100%', display: 'grid', gridGap: '.5em', gridTemplateColumns: '24.5% 24.5% 24.5% 24.5%'}}>
                    <Col span={24}>
                        <Form.Item 
                            label='Fecha ETTO' 
                            labelCol={{span: 24}} 
                            valuePropName='fecha_etto' 
                            name='fecha_etto' 
                            wrapperCol={{ span: 24}}
                            rules={
                                [{ required: assist === 'Si' || assist === 'No' ? true : false}]
                            }
                            >
                            <DatePicker 
                                style={{width: '100%'}}
                                disabledDate={d => !d || d.isBefore(data.fecha_visita)}
                                value={fecha_etto && moment(fecha_etto)}
                                onChange={(value:any)=>setFecha_etto(value)}
                                />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label='Asistencia del paciente' labelCol={{span: 24}} wrapperCol={{ span: 24}} name='asistencia_paciente'>
                            <Select 
                                onChange={(value: any)=>{
                                    setAssist(value)
                                }}
                                >
                                    <Option value=''> - </Option>
                                    <Option value='Si'>Si</Option>
                                    <Option value='No'>No</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label='Tipo de pago' name='tipo_pago_id' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                            <Select     
                                >
                                {
                                    tiposPagosLists.map( (item:any, id:any)=>(
                                        <Option key={id} value={item.id}>{item.nombre}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{display: 'flex', alignItems:'center'}}>
                        <Form.Item label='Descuento' name='descuento' labelCol={{span: 24}} wrapperCol={{ span: 24}} className="descuento">
                            <InputNumber min={0} max={100} parser={(value:any) => value.replace('%', '')}/>
                            <span className="descuento-span">
                                % 
                            </span>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{width:'100%', display: 'grid', gridGap: '.5em', gridTemplateColumns: '24.5% 24.5% 24.5% 24.5%'}}>
                    <Col span={24}>
                        <Form.Item 
                            label='Entidad financiera' 
                            name='entidad_financiera_id' 
                            labelCol={{span: 24}} 
                            wrapperCol={{ span: 24}}>
                            <Select 

                                >
                                {
                                    entidadesFinancierasLists.map( (item:any, id:any)=>{
                                        return(
                                        <Option key={id} value={item.id}>{item.nombre_comercial}</Option>
                                    )})
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label='Cantidad financiada' name='cantidad_financiada' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                            <InputNumber 
                                min={0} 
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label='% Costes financieros' name='costes_financieros' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                            <InputNumber 
                                min={0}
                                max={100} 
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>

                    <Col span={15} style={{display: 'flex', alignItems:'center'}}>
                            <div style={{display:'flex', alignItems:'center', gap: '1em', padding: '1em 0'}}>
                                <Title level={3} style={{margin: 0, color: 'rgba(36, 80, 70, 1)', fontSize: 18}}>
                                    Ingreso total financiación
                                </Title>

                                <Title level={3} style={{margin: 0, color: 'rgba(36, 80, 70, 1)', fontSize: 24}}>
                                    {formatToEUR(data.total_financiacion)}
                                </Title>
                            </div>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default Derivada
