import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'

import {Table} from 'antd'

import {fetchEspecialidad} from '../../../../../../redux/acumulado/actions'

import formatToPercentage from '../../../../../../helpers/formatPercentNo100'
import formatToEuro from '../../../../../../helpers/formatToEuro'

interface Props{
    especialidad: string
}

export default function Especialidad(props: Props) {
    let {especialidad} = props

    const dispatch = useDispatch()
    const { date } = useSelector((state: any) => state.ResumenGlobal);
    const filters = useSelector((state: any) => state.Filters);

    const {especialidadDataTable, selected, loading} = useSelector((state:any) => state.Acumulados);

    useEffect(() => {
        dispatch(fetchEspecialidad(date, filters, especialidad))
    }, [date, filters]);
    
    let columns = [{
        title: especialidad,
        key: 'nombre',
        dataIndex: 'nombre'
    },{
        title: 'Fechas filtradas '+moment().format('yyyy'),
        key: 'fecha2021',
        dataIndex: 'fecha2021',
        sorter: {
            compare: (a: any, b: any) => parseInt(a.fecha2021) - parseInt(b.fecha2021),
            multiple: 3,
        },
        render: (text: any, record: any)=>{
            return(
                checkFormat(text, record)
            )
        }
    },{
        title: 'Fechas filtradas '+moment().subtract(12, 'month').format('yyyy'),
        key: 'fecha2020',
        dataIndex: 'fecha2020',
        sorter: {
            compare: (a: any, b: any) => parseInt(a.fecha2020) - parseInt(b.fecha2020),
            multiple: 3,
        },
        render: (text: any, record: any)=>{
            return(
                checkFormat(text, record)
            )
        }
    },{
        title: 'Diferencia',
        key: 'diferencia',
        dataIndex: 'diferencia',
        render: (text: any, record: any) => {
            return (
            <>
            {
                record && formatToPercentage(record.diferencia)
            }
            </>
        )}
    },{
        title: 'Acumulado '+moment().format('yyyy'),
        key: 'acumulado2021',
        dataIndex: 'acumulado2021',
        sorter: {
            compare: (a: any, b: any) => parseInt(a.acumulado2021) - parseInt(b.acumulado2021),
            multiple: 3,
        },
        render: (text: any, record: any)=>{
            return(
                checkFormat(text, record)
            )
        }
    },{
        title: 'Acumulado '+moment().subtract(12, 'month').format('yyyy'),
        key: 'acumulado2020',
        dataIndex: 'acumulado2020',
        sorter: {
            compare: (a: any, b: any) => parseInt(a.acumulado2020) - parseInt(b.acumulado2020),
            multiple: 3,
        },
        render: (text: any, record: any)=>{
            return(
                checkFormat(text, record)
            )
        }
    },{
        title: 'Diferencia acumulada',
        key: 'diferenciaAcumulada',
        dataIndex: 'diferenciaAcumulada',
        render: (text: any, record: any) => {
        return (
            <>
            {
                record && formatToPercentage(record.diferenciaAcumulada)
            }
            </>
        )}
    }]

    return (
        <div style={{overflow: 'scroll'}}>
            <Table 
                columns={columns}
                scroll={{x: true}}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                dataSource={selected}
                loading={loading}
            />
        </div>
    )
}

const checkFormat = (text: string, record: any) =>{
    let {nombre} = record

    if(nombre === 'Presupuestado' || nombre === 'Aceptado'){
        return formatToEuro(text)
    }

    if(nombre === 'Aceptación pacientes' || nombre === 'Aceptado (%)'){
        return formatToPercentage(text)
    }
    
    return text
}