import React, { useState, useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Layout, Menu, Row, Col, message, Button } from 'antd';

import styles from './styles.module.scss';
import logo from '../../assets/dentalpro-horizontal-logo.svg';
import logoutIcon from '../../assets/icon/logout.svg';
import estadisticasIcon from '../../assets/icon/cumplimentacion.svg';

import administradoresIcon from '../../assets/icon/administradores.png';
import resumenIcon from '../../assets/icon/resumen.svg';
import retosIcon from '../../assets/icon/retos.png';

import Icono from '../../components/MenuItemImg';

import Users from './users/';
import Admins from './admins/';
import Clinicas from './clinicas/';
import Centros from './centros/'
import Entidades from './entidades-financieros';
import Especialidades from './especialidades';
import Empleados from './configuracion/empleados'
import Pais from './configuracion/pais'

import Objetivos from './objetivos'

import Cumplimentacion from './cumplimentacion';
import Resumen from './resumen'

import TiposPagos from './tipos/pagos';
import TiposEmpleados from './tipos/empleados';
import TiposPresupuestos from './tipos/presupuesto';
import TiposOrtodoncias from './tipos/ortodoncias';

import FilterSection from './filter-section/'
import ProgressBar from '../../components/progress-bar';

import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/auth/actions';
import { getObjetivoPrevision } from '../../redux/global/actions'

import UseWidth from '../../helpers/useWidth';


const { Header, Sider, Content } = Layout;

export default function AsideSelector() {
  const { SubMenu } = Menu;
  const { user } = useSelector((state: any) => state.auth)

  if (user) {
    if (user.role === 'Admin') return (
      <Sider breakpoint="lg"
        collapsedWidth="0" className={styles.sider}>
        <Menu
          className={styles.menu}
          mode="inline"
          defaultSelectedKeys={['global-init']}
        >
          <Menu.Item
            key="global-init"
            icon={<Icono img={resumenIcon} />}
            title="Global"
            className={styles.submenu}
          >
            <Link className={styles.link} to="/resumen/global">
              Global
            </Link>
          </Menu.Item>
          <SubMenu
            className={styles.submenu}
            key="estadisticas"
            title="Cumplimentación"
            icon={<Icono img={estadisticasIcon} />}
          >


            <Menu.Item key="1">
              <Link className={styles.link} to="/cumplimentacion/parte-presupuesto">
                Parte de presupuestos
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link className={styles.link} to="/cumplimentacion/previsiones">
                Previsiones
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link className={styles.link} to="/cumplimentacion/caja-del-dia">
                Caja del día
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link className={styles.link} to="/cumplimentacion/financiaciones">
                Financiaciones
              </Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="objetivos"
            icon={<Icono img={retosIcon} />}
            title="Objetivos"
            className={styles.submenu}
          >
            <Link className={styles.link} to="/objetivos">
              Objetivos
            </Link>
          </Menu.Item>

          <SubMenu
            key="resumen"
            icon={<Icono img={resumenIcon} />}
            title="Resumen"
            className={styles.submenu}
          >
            <Menu.Item key="6">
              <Link className={styles.link} to="/resumen/seguimiento/doctores">
                Seguimiento doctores
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link className={styles.link} to="/resumen/seguimiento/asesores">
                Seguimiento asesores
              </Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Link className={styles.link} to="/resumen/acumulado">
                Acumulado
              </Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link className={styles.link} to="/resumen/partes_no_aceptados">
                Partes no aceptados
              </Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="configuracion"
            icon={<Icono img={administradoresIcon} />}
            title="Configuración"
            className={styles.submenu}
          >
            <Menu.Item key="10">
              <Link className={styles.link} to="/clinicas">
                Clínicas
              </Link>
            </Menu.Item>
            <Menu.Item key="11">
              <Link className={styles.link} to="/centros">
                Centros
              </Link>
            </Menu.Item>

            <Menu.Item key="12">
              <Link className={styles.link} to="/configuracion/empleados">
                Empleados
              </Link>
            </Menu.Item>
            <Menu.Item key="13">
              <Link className={styles.link} to="/usuarios">
                Usuarios
              </Link>
            </Menu.Item>
            <Menu.Item key="14">
              <Link className={styles.link} to="/entidades-financieras">
                Entidades financieras
              </Link>
            </Menu.Item>
            <Menu.Item key="15">
              <Link className={styles.link} to="/tipos/empleados">
                Tipos de empleado
              </Link>
            </Menu.Item>
            <Menu.Item key="16">
              <Link className={styles.link} to="/tipos/presupuestos">
                Tipos de presupuesto
              </Link>
            </Menu.Item>
            <Menu.Item key="17">
              <Link className={styles.link} to="/tipos/pagos">
                Tipos de pago
              </Link>
            </Menu.Item>
            <Menu.Item key="18">
              <Link className={styles.link} to="/especialidades">
                Especialidades
              </Link>
            </Menu.Item>
            <Menu.Item key="19">
              <Link className={styles.link} to="/tipos/ortodoncia">
                Tipos de ortodoncia
              </Link>
            </Menu.Item>
            <Menu.Item key="20">
              <Link className={styles.link} to="/configuracion/pais">
                País
              </Link>
            </Menu.Item>
          </SubMenu>


        </Menu>
      </Sider>
    )

    if (user && user.role === 'Consultor' || user.role === 'Director' || user.role === 'Gerente') return (
      <Sider breakpoint="lg"
        collapsedWidth="0" className={styles.sider}>
        <Menu
          className={styles.menu}
          mode="inline"
          defaultSelectedKeys={['global-init']}
        >
          <Menu.Item
            key="global-init"
            icon={<Icono img={resumenIcon} />}
            title="Global"
            className={styles.submenu}
          >
            <Link className={styles.link} to="/resumen/global">
              Global
            </Link>
          </Menu.Item>
          <SubMenu
            className={styles.submenu}
            key="estadisticas"
            title="Cumplimentación"
            icon={<Icono img={estadisticasIcon} />}
          >
            <Menu.Item key="1">
              <Link className={styles.link} to="/cumplimentacion/parte-presupuesto">
                Parte de presupuestos
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link className={styles.link} to="/cumplimentacion/previsiones">
                Previsiones
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link className={styles.link} to="/cumplimentacion/caja-del-dia">
                Caja del día
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="objetivos"
            icon={<Icono img={retosIcon} />}
            title="Objetivos"
            className={styles.submenu}
          >
            <Link className={styles.link} to="/objetivos">
              Objetivos
            </Link>
          </Menu.Item>
          <SubMenu
            key="resumen"
            icon={<Icono img={resumenIcon} />}
            title="Resumen"
            className={styles.submenu}
          >
            <Menu.Item key="6">
              <Link className={styles.link} to="/resumen/seguimiento/doctores">
                Seguimiento doctores
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link className={styles.link} to="/resumen/seguimiento/asesores">
                Seguimiento asesores
              </Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Link className={styles.link} to="/resumen/acumulado">
                Acumulado
              </Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link className={styles.link} to="/resumen/partes_no_aceptados">
                Partes no aceptados
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="configuracion"
            icon={<Icono img={administradoresIcon} />}
            title="Configuración"
            className={styles.submenu}
          >
            <Menu.Item key="12">
              <Link className={styles.link} to="/configuracion/empleados">
                Empleados
              </Link>
            </Menu.Item>
          </SubMenu>

        </Menu>
      </Sider>
    )

    if (user.role === 'Admin') return (
      <Sider breakpoint="lg"
        collapsedWidth="0" className={styles.sider}>
        <Menu
          className={styles.menu}
          mode="inline"
          defaultSelectedKeys={[window.location.pathname.replace('/', '')]}
        >
          <SubMenu
            className={styles.submenu}
            key="estadisticas"
            title="Cumplimentación"
            icon={<Icono img={estadisticasIcon} />}
          >
            <Menu.Item key="1">
              <Link className={styles.link} to="/cumplimentacion/parte-presupuesto">
                Parte de presupuestos
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link className={styles.link} to="/cumplimentacion/previsiones">
                Previsiones
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link className={styles.link} to="/cumplimentacion/caja-del-dia">
                Caja del día
              </Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="objetivos"
            icon={<Icono img={retosIcon} />}
            title="Objetivos"
            className={styles.submenu}
          >
            <Link className={styles.link} to="/objetivos">
              Objetivos
            </Link>
          </Menu.Item>

          <SubMenu
            key="resumen"
            icon={<Icono img={resumenIcon} />}
            title="Resumen"
            className={styles.submenu}
          >
            <Menu.Item key="5">
              <Link className={styles.link} to="/resumen/global">
                Global
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link className={styles.link} to="/resumen/seguimiento/doctores">
                Seguimiento doctores
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link className={styles.link} to="/resumen/seguimiento/asesores">
                Seguimiento asesores
              </Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Link className={styles.link} to="/resumen/acumulado">
                Acumulado
              </Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link className={styles.link} to="/resumen/partes_no_aceptados">
                Partes no aceptados
              </Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="configuracion"
            icon={<Icono img={administradoresIcon} />}
            title="Configuración"
            className={styles.submenu}
          >
            <Menu.Item key="12">
              <Link className={styles.link} to="/configuracion/empleados">
                Empleados
              </Link>
            </Menu.Item>
          </SubMenu>


        </Menu>
      </Sider>
    )
  }

  return (
    <div>

    </div>
  )
}
