import globalServices from "./services";

import globalConstants from "./constants";
import {setNotification} from '../global/actions'

const {
    SET_ESPECIALIDAD,
    SET_CONSULTOR,
    SET_CLINICA,
    SET_ASESOR,
    SET_DOCTOR,
    SET_CENTRO
} = globalConstants

const setEspecialidades = (data:any)=>({
    type: SET_ESPECIALIDAD,
    data
})

const setConsultores = (data:any)=>({
    type: SET_CONSULTOR,
    data
})

const setClinicas = (data:any)=>({
    type: SET_CLINICA,
    data
}) 

const setAsesores = (data:any)=>({
    type: SET_ASESOR,
    data
})

const setDoctores = (data:any)=>({
    type: SET_DOCTOR,
    data
})

const setCentros = (data:any)=>({
    type: SET_CENTRO,
    data
})


export const setEspecialidad = (data: any)=>{
    return (dispatch: any)=>{
        dispatch(setEspecialidades(data))
    }
}


export const setConsultor = (data: any)=>{
    return (dispatch: any)=>{
        dispatch(setConsultores(data))
    }
}


export const setClinica = (data: any)=>{
    return (dispatch: any)=>{
        dispatch(setClinicas(data))
    }
}

export const setAsesor = (data: any)=>{
    return (dispatch: any)=>{
        dispatch(setAsesores(data))
    }
}

export const setDoctor = (data: any)=>{
    return (dispatch: any)=>{
        dispatch(setDoctores(data))
    }
}

export const setCentro = (data: any)=>{
    return (dispatch: any)=>{
        dispatch(setCentros(data))
    }
}

export const resetFilters = ()=>{
    return (dispatch: any)=>{
        dispatch(setNotification(0))
        dispatch(setEspecialidad(false))
        dispatch(setConsultor(false))
        dispatch(setClinica(false))
        dispatch(setAsesor(false))
        dispatch(setDoctor(false))
    }
}