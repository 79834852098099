import api from '../../helpers/api'

const TiposPagosServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/tipos_pagos')
    },
    save: (
        nombre: string,
        financiable: boolean
    )=>{
        return api.post('/admin/save/tipos_pagos',{
            nombre,
            financiable
        }).catch(e => console.log(e))
    },
    destroy: (
        id:string,
        )=> {
            return api.get('/admin/destroy/tipos_pagos/'+id)
    }
}

export default TiposPagosServices