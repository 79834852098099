const clinicasConstants = {
    SET_LOADING_PREVISIONES: 'SET_LOADING_PREVISIONES',
    SET_PREVISIONES: 'SET_PREVISIONES',
    SET_EDITING_PREVISIONES: 'SET_EDITING_PREVISIONES',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_PREVISIONES: 'SET_SAVING_PREVISIONES'
  };
  
  export default clinicasConstants;
  