import EmpleadosServices from "./services";

import clinicasConstants from "./constants";
import { fetchLists } from "../admins/actions";
import store from "../rootStore";

const {
    SET_EMPLEADOS,
    SET_LOADING_EMPLEADOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_EMPLEADOS
} = clinicasConstants

export const setLoadingEmpleados = (isLoadingEmpleados: boolean) => ({
    type: SET_LOADING_EMPLEADOS,
    isLoadingEmpleados,
  });
  
  const setEmpleados = (empleados: any) => ({
    type: SET_EMPLEADOS,
    empleados,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingEmpleados(true));
      let {Filters, ResumenGlobal} = store.getState()
      EmpleadosServices.fetchAll(Filters).then((res) => {
        dispatch(setLoadingEmpleados(false));
        // console.log(res)
        return dispatch(setEmpleados(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_EMPLEADOS, SET_EDIT_ERROR, SET_EDIT_OK } = clinicasConstants;
  
  const setEditingPrevisiones = (isEditingPrevisiones: boolean) => ({
    type: SET_EDITING_EMPLEADOS,
    isEditingPrevisiones,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const edit = (data: any) => {
    return (dispatch: any) => {
      console.log(data)
      dispatch(setEditingPrevisiones(true));
      dispatch(setEditError(null));
      EmpleadosServices
        .edit(
          data.id,
          data.centro,
          data.empleado,
          data.especialidadesArray,
          data.nombre,
          data.apellidos,        
          data.telefono,        
          data.email,
          data.dni,
          data.activo,
          data.observaciones,
        )
        .then((res: any) => {
          dispatch(setEditingPrevisiones(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
          dispatch(fetchLists())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingPrevisiones(false));
        });
    };
  };
  
  export const deleteEmpleado = (data: any) => {
    return (dispatch: any) =>{
      EmpleadosServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") dispatch(fetchAll())
      })
      .catch(e => console.log(e))
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingEmpleados = (saving: boolean | null)=>({
    type: SET_SAVING_EMPLEADOS,
    saving
  })

  export const save = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(savingEmpleados(true))
      EmpleadosServices.save(
        data.centro,
        data.empleado,
        data.especialidadesArray,
        data.nombre,
        data.apellidos,        
        data.telefono,        
        data.email,
        data.dni,
        data.activo,
        data.observaciones,
        ).then((res: any) =>{
            dispatch(fetchAll())    
            dispatch(fetchLists())
            dispatch(savingEmpleados(false))
        })
      }catch(e){
        console.log(e)
      }
    }
  }