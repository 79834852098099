import { stat } from "fs";
import clinicasConstants from "./constants";

const {
    SET_EMPLEADOS,
    SET_EDIT_ERROR,
    SET_EDITING_EMPLEADOS,
    SET_EDIT_OK,
    SET_LOADING_EMPLEADOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_EMPLEADOS
} = clinicasConstants

const initialState = {
    loading: true,
    empleados: false,
    editOk: false,
    selectedRowKeys: []
}

const ClinicasReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_EMPLEADOS:
            return {
                ...state,
                loading: action.isLoadingEmpleados
            }
        
        case SET_EMPLEADOS:
            return{
                ...state,
                empleados: action.empleados
            }
        
        case SET_EDITING_EMPLEADOS:
            return {
                ...state,
                loading: action.isEditingEmpleados
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_EMPLEADOS:
            return {
                ...state,
                loading: action.saving
            }
    
    default:
        return state
    }
}

export default ClinicasReducer