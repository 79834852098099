import tiposPagosServices from "./services";

import tiposPagosConstants from "./constants";

const {
    SET_TIPOS_PAGOS,
    SET_LOADING_TIPOS_PAGOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_TIPOS_PAGOS,
} = tiposPagosConstants

export const setLoadingTiposPagos = (isLoadingTiposPagos: boolean) => ({
    type: SET_LOADING_TIPOS_PAGOS,
    isLoadingTiposPagos,
  });
  
  const setTiposPagos = (tiposPagos: any) => ({
    type: SET_TIPOS_PAGOS,
    tiposPagos,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingTiposPagos(true));
      tiposPagosServices.fetchAll().then((res) => {
        dispatch(setLoadingTiposPagos(false));
        return dispatch(setTiposPagos(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_TIPOS_PAGOS, SET_EDIT_ERROR, SET_EDIT_OK } = tiposPagosConstants;
  const setEditingTiposPagos = (isEditingTiposPagos: boolean) => ({
    type: SET_EDITING_TIPOS_PAGOS,
    isEditingTiposPagos,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const deleteTiposPagos = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingTiposPagos(true));
      dispatch(setEditError(null));
      tiposPagosServices
        .destroy(
          data.id
        )
        .then((res: any) => {
          dispatch(setEditingTiposPagos(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingTiposPagos(false));
        });
    };
  };
  
  export const deleteCentro = (data: any) => {
    return (dispatch: any) =>{
      console.log('=D')
      /*tiposPagosServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") fetchAll()
      })
      .catch(e => console.log(e))*/
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingTiposPagos = (saving: boolean | null)=>({
    type: SET_SAVING_TIPOS_PAGOS,
    saving
  })

  export const saveTiposPagos = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingTiposPagos)
      dispatch(savingTiposPagos(true))
      tiposPagosServices.save(
        data.nombre,
        data.financiable
        ).then(res =>
            dispatch(fetchAll())
          )
      }catch(e){
        console.log(e)
      }
    }
  }