import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'

import { fetchListsCajaDia } from '../../../../../../redux/admins/actions'

interface Props {
    isModalVisible: boolean
    onCancel: any
    onSubmit: any
    formData: any
}


function Index(props: Props) {
    const {isModalVisible, onCancel, onSubmit, formData} = props
    const dispatch = useDispatch()

    let {profile, isLoading} = useSelector( (state:any)=>state.PartePresupuesto)
    let {centrosLists, tiposPagosLists} = useSelector( (state:any)=>state.admins)

    const [data, setData] = useState({
        id: false,
        
        fecha_pago: false,
        cantidad: '',
        numero_pagos: 1,
        observaciones: '',

        Centro: {
            id: '',
            clinica_nombre: ''
        },
        tiposPagos: {
            id: '',
            nombre: ''
        },
    })

    useEffect(() => {
        if(formData){
            syncData(formData)
        }

        if(!formData){
            resetData()
        }
    }, [formData])

    useEffect(() => {
        dispatch(fetchListsCajaDia())
    }, [])

    const syncData = (newData: any)=>{
        setData({
            ...newData,
            id: newData.main_id,
            observaciones: newData.caja_observaciones,
            Centro: {
                id: newData.centro_id,
                clinica_nombre: newData.clinica_nombre
            },
            tiposPagos: {
                id: newData.tipo_pago_id,
                nombre: newData.nombre
            },
        })
        
    }
    
    const handleChange=(event: any)=>{
        if(event.target){
            let {name, value} = event?.target
            setData({...data, [name]:value})
        }
    }

    const sortFromList = (name: any, value:any) =>{
        if(name === 'tiposPagos'){
            let sortedValue = tiposPagosLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'Centro'){
            let sortedValue = centrosLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if( 
            name === 'cantidad' ||
            name === 'numero_pagos'
        ){
            handleChangeNameValue(name, value)
        }

        if(name === 'fecha_pago'){
            handleChangeNameValue(name, value.format())
        }
    }

    const handleChangeNameValue = (name:any, value:any)=>{
        if(
            name === 'cantidad' ||
            name === 'fecha_pago'||
            name === 'numero_pagos'
        ){ 
            setData({...data, [name]: value})
        }
        
        if(
            name === 'Centro' ||
            name === 'tiposPagos' 
            ){
            setData({
                ...data,
                [name]: {...value[0]}
            })
        }
    }

    const handleChangeDate = (name: string, e:any)=>{
        if(e){
        let date = e.format("YYYY-MM-DDTHH:mm:ss")
        let newDate = date
        setData({...data, [name]:newDate})

        }else{
            setData({...data, [name]: ''})
        }    
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        handleClose()
    }

    const resetData = ()=>{
        setData({
            id: false,
        
            fecha_pago: false,
            cantidad: '',
            numero_pagos: 1,
            observaciones: '',

            Centro: {
                id: '',
                clinica_nombre: ''
            },
            tiposPagos: {
                id: '',
                nombre: ''
            },
        })
    }

    const handleClose = ()=>{
        resetData()
        onCancel()
    }


    if(isLoading) return (
        <div>
            loading
        </div>
    ) 

    return (
        <Form 
            isVisible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            data={data}
            
            centrosLists={centrosLists}
            tiposPagosLists={tiposPagosLists}

            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleChangeNameValue={sortFromList}
        />
    )
}

export default Index
