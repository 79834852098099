import { stat } from "fs";
import clinicasConstants from "./constants";

const {
    SET_PREVISIONES,
    SET_EDIT_ERROR,
    SET_EDITING_PREVISIONES,
    SET_EDIT_OK,
    SET_LOADING_PREVISIONES,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_PREVISIONES
} = clinicasConstants

const initialState = {
    loading: false,
    previsiones: false,
    editOk: false,
    selectedRowKeys: []
}

const ClinicasReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_PREVISIONES:
            return {
                ...state,
                loading: action.isLoadingPrevisiones
            }
        
        case SET_PREVISIONES:
            return{
                ...state,
                previsiones: action.previsiones
            }
        
        case SET_EDITING_PREVISIONES:
            return {
                ...state,
                isEditingPrevisiones: action.isEditingPrevisiones
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_PREVISIONES:
            return {
                ...state
            }
    
    default:
        return state
    }
}

export default ClinicasReducer