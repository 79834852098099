import moment from "moment";
import globalConstants from "./constants";
import "moment/locale/es";
moment.locale("es");

const {
  SET_OBJETIVO_PREVISIONES,

  SET_RESUMEN_GLOBAL,
  SET_RESUMEN_ASESORES,
  SET_INDICADOR_ASESORES,
  SET_ESTADISTICA_ASESORES,
  SET_RESUMEN_DOCTORES,
  SET_TIPOLOGIA_DOCTORES,
  SET_DERIVACIONES_DOCTORES,
  SET_DERIVACIONES_EFECTIVAS_DOCTORES,
  SET_TOTALIZADOR_DOCTORES,
  SET_ESTADISTICA_DOCTORES,

  SET_RESUMEN_GLOBAL_LOADING,
  SET_DATE,
  SET_OLD_DATES,
  SET_ETTO_DATES,
  SET_NOTIFICATION,
} = globalConstants;

const initialState = {
  date: {
    starts: moment().startOf("month").set({ hour: 0, minute: 0, second: 0 }),
    ends: moment().endOf("month").set({ hour: 23, minute: 59, second: 59 }),
  },
  lastDates: false,
  etto:false,
  // lastDates: true,

  resumenData: false,
  indicadorAsesoresData: false,
  resumenAsesoresData: false,

  resumenDoctoresData: false,
  tipologiaDoctoresData: false,
  derivacionDoctoresData: false,
  derivacionesEfectivasDoctoresData: false,
  totalizadorDoctoresData: false,
  estadisticaDoctoresData: false,

  loadingAsesores: false,
  loading: false,
  estadisticaAsesoresData: {
    cierreCita: 0,
    ratioAsistencia: 0,
    ventaMedia: 0,
    presupuestoMedio: 0,
    aceptacionPersonas: 0,
    aceptacionEuros: 0,
    financiado: 0,
  },

  notificationNumber: 0,

  headerAsesores: [{}],
  exportAsesores: [],

  headersDoctores: [{}],
  exportDoctores: [],
};

const EspecialidadesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_RESUMEN_GLOBAL:
      return {
        ...state,
        resumenData: action.data,
      };

    case SET_RESUMEN_GLOBAL_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };

    case SET_DATE:
      return {
        ...state,
        date: {
          starts: action.date.startDate,
          ends: action.date.endDate,
        },
      };

    case SET_OLD_DATES:
      return {
        ...state,
        lastDates: action.data,
      };
    case SET_ETTO_DATES:
      return {
        ...state,
        etto: action.data,
      };
    case SET_RESUMEN_ASESORES:
      return {
        ...state,
        resumenAsesoresData: action.data,
        headerAsesores: resumenAsesorHeader,
        exportAsesores: action.data,
      };

    case SET_INDICADOR_ASESORES:
      return {
        ...state,
        indicadorAsesoresData: action.data,
        headerAsesores: indicadorAsesoresHeader,
        exportAsesores: action.data,
      };

    case SET_RESUMEN_DOCTORES:
      return {
        ...state,
        resumenDoctoresData: action.data,
        headersDoctores: estadisticaDoctoresHeader,
        exportDoctores: action.data,
      };

    case SET_TIPOLOGIA_DOCTORES:
      return {
        ...state,
        tipologiaDoctoresData: action.data,
        headersDoctores: tipologiaDoctoresHeader,
        exportDoctores: action.data,
      };

    case SET_DERIVACIONES_DOCTORES:
      let header = getHeader(
        derivacionDoctoresHeader,
        action.data.especialidades
      );
      return {
        ...state,
        derivacionDoctoresData: action.data.data,
        // headersDoctores: derivacionDoctoresHeader,
        headersDoctores: header,
        exportDoctores: action.data.data,
      };

    case SET_DERIVACIONES_EFECTIVAS_DOCTORES:
      let headers = getHeader(
        derivacionDoctoresHeader,
        action.data.especialidades
      );

      return {
        ...state,
        derivacionesEfectivasDoctoresData: action.data.data,
        // headersDoctores: derivacionEfectivaDoctoresHeader,
        headersDoctores: headers,
        exportDoctores: action.data.data,
      };

    case SET_TOTALIZADOR_DOCTORES:
      return {
        ...state,
        totalizadorDoctoresData: action.data,
        headersDoctores: totalizadorDoctoresHeader,
        exportDoctores: action.data,
      };

    case SET_ESTADISTICA_DOCTORES:
      return {
        ...state,
        estadisticaDoctoresData: action.data,
        headersDoctores: estadisticaTotalesDoctoresHeader,
        exportDoctores: [
          ...action.data.aceptacionPorEspecialidad,
          ...action.data.derivaciones,
        ],
      };

    case SET_OBJETIVO_PREVISIONES:
      return {
        ...state,
        objetivo: parseInt(action.data.objetivo),
        cajaDia: parseInt(action.data.cajaDia),
        prevision: parseInt(action.data.prevision),
      };

    case SET_ESTADISTICA_ASESORES:
      return {
        ...state,
        estadisticaAsesoresData: action.data,
        headerAsesores: estadisticaAsesoresHeader,
        exportAsesores: [action.data],
      };

    case SET_NOTIFICATION:
      return {
        ...state,
        notificationNumber: action.number,
      };
    default:
      return state;
  }
};

export default EspecialidadesReducer;

//Asesores headers --------------------------------------------------------
const resumenAsesorHeader = [
  { label: "Clinica", key: "clinica_nombre" },
  { label: "Asesor", key: "name" },
  { label: "Total presupuestos", key: "totalPresupuestos" },
  { label: "Citas ETTO", key: "citasEtto" },
  { label: "Presupuestado", key: "totalPresupuestado" },
  { label: "Aceptado", key: "totalAceptado" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
  { label: "Caidas", key: "caidas" },
];

const estadisticaAsesoresHeader = [
  { label: "Cierre cita", key: "cierreCita" },
  { label: "Ratio asistencia", key: "ratioAsistencia" },
  { label: "Venta media", key: "ventaMedia" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
  { label: "Aceptación personas", key: "aceptacionPersonas" },
  { label: "Aceptación euros", key: "aceptacionEuros" },
  { label: "Financiado", key: "financiado" },
];

const indicadorAsesoresHeader = [
  { label: "Clinica", key: "clinica_nombre" },
  { label: "Asesor", key: "name" },
  { label: "Cierre de citas", key: "cierreCita" },
  { label: "Ratio de asistencia", key: "ratioAsistencia" },
  { label: "Venta media", key: "ventaMedia" },
  { label: "Fidelización", key: "fidelizacion" },
  { label: "Captación", key: "captacion" },
  { label: "ETTO media", key: "ettoMedia" },
  { label: "Descuento Medio", key: "descuentoMedio" },
];

//Doctores headers --------------------------------------------------------
const especialidadDoctoresHeader = [
  { label: "Clinica", key: "clinica" },
  { label: "Asesor", key: "asesor" },
  { label: "Doctor", key: "user" },
  { label: "Especialidad", key: "especialidad" },
  { label: "Presupuestos", key: "presupuestos" },
  { label: "Aceptados", key: "aceptados" },
  { label: "Presupuestado", key: "presupuestado" },
  { label: "Aceptado", key: "aceptado" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
];

const tipologiaDoctoresHeader = [
  { label: "Clinica", key: "clinica" },
  { label: "Asesor", key: "asesor" },
  { label: "Doctor", key: "user" },
  { label: "Tipología", key: "tipologia" },
  { label: "Presupuestos", key: "presupuestos" },
  { label: "Aceptados", key: "aceptados" },
  { label: "Presupuestado", key: "presupuestado" },
  { label: "Aceptado", key: "aceptado" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
];

const derivacionDoctoresHeader = [
  { label: "Clinica", key: "clinica" },
  { label: "Asesor", key: "asesor" },
  { label: "Doctor", key: "user" },
  { label: "Presupuestos", key: "presupuestos" },
];

const derivacionEfectivaDoctoresHeader = [
  { label: "Clinica", key: "clinica_nombre" },
  { label: "Asesor", key: "name" },
  { label: "Total presupuestos", key: "totalPresupuestos" },
  { label: "Citas ETTO", key: "citasEtto" },
  { label: "Presupuestado", key: "totalPresupuestado" },
  { label: "Aceptado", key: "totalAceptado" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
  { label: "Caidas", key: "caidas" },
];

const totalizadorDoctoresHeader = [
  { label: "Clinica", key: "clinica" },
  { label: "Doctor", key: "user" },
  { label: "Presupuestos", key: "presupuestos" },
  { label: "Aceptados", key: "aceptados" },
  { label: "Presupuestado", key: "presupuestado" },
  { label: "Aceptado", key: "aceptado" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
  { label: "Aceptado medio", key: "aceptadoMedio" },
];

const estadisticaDoctoresHeader = [
  { label: "Clinica", key: "clinica_nombre" },
  { label: "Asesor", key: "name" },
  { label: "Total presupuestos", key: "totalPresupuestos" },
  { label: "Citas ETTO", key: "citasEtto" },
  { label: "Presupuestado", key: "totalPresupuestado" },
  { label: "Aceptado", key: "totalAceptado" },
  { label: "Presupuesto medio", key: "presupuestoMedio" },
  { label: "Caidas", key: "caidas" },
];

const estadisticaTotalesDoctoresHeader = [
  { label: "Nombre", key: "nombre" },
  { label: "Cantidad", key: "amount" },
  { label: "Porcentaje", key: "percent" },
];

const getHeader = (oldHeader: any, newHeader: any) => {
  let columnArray =
    newHeader &&
    newHeader.map((item: any) => {
      return {
        label: `Derivaciones de ${item.nombre}`,
        key: item.nombre,
      };
    });

  return [...oldHeader, ...columnArray];
};
