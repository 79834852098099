import React, { useEffect, useState, useRef  } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import {
    PageHeader,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    message,
    Space
} from 'antd';
import exportIcon from '../../../assets/icon/export.svg';
import PlusOutlinedIcon from '../../../../../assets/icon/plusOutlined.svg';

import CumplimentacionModal from './components/'
import Filter from './components/filter'

import formatToEUR from '../../../../../helpers/formatToEuro'
import {
    fetchAll,
    edit,
    setSelectedRowKeys,
    deletePresupuesto,
    savePartePresupuestos
} from '../../../../../redux/parte_presupuestos/actions';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';

import CustomButton from '../../../../../components/custom-button';
import formatToEURO from '../../../../../helpers/formatToEuro'

function Especialidades() {
  const headers = [
    { label: 'Clinica', key: 'nombre_comercial' },
    { label: 'Nombre', key: 'nombre_apellidos' },
    { label: 'Telefono', key: 'numero_telefono' },
    { label: 'Asesor', key: 'name' },
    { label: 'Primer doctor', key: 'empleado_nombre' },
    { label: 'Fecha de visita', key: 'fecha_visita' },
    { label: 'Fecha ETTO', key: 'fecha_etto' },
    { label: 'Tipo de presupuesto', key: 'presupuesto' },
    { label: 'Asistencia', key: 'asistencia_paciente' },
    { label: 'Total presupuestado', key: 'total_presupuestado' },
    { label: 'Total aceptado', key: 'total_aceptado' },
    { label: 'Comentario', key: 'parte_presupuesto_observaciones' },
  ];

  const columns = [
    {
        title: 'Clinica',
        dataIndex: 'nombre_comercial',
        Key:'nombre_comercial',
        width: '7vw',
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Nombre',
        dataIndex: 'nombre_apellidos',
        Key:'nombre_apellidos',
        width: '7vw',
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Telefono',
        dataIndex: 'numero_telefono',
        Key:'numero_telefono',
        width: '5vw',
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Asesor',
        dataIndex: 'name',
        Key:'name',
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Primer doctor',
        dataIndex: 'empleado_nombre',
        Key:'nombre',
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Fecha de visita',
        dataIndex: 'fecha_visita',
        Key:'fecha_visita',
        sorter: {
          compare: (a: any, b: any) => moment(a.fecha_visita).unix() - moment(b.fecha_visita).unix(),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: moment(text).format('DD/MM/YYYY'),
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Fecha ETTO',
        dataIndex: 'fecha_etto',
        Key:'fecha_etto',
        sorter: {
          compare: (a: any, b: any) => moment(a.fecha_etto).unix() - moment(b.fecha_etto).unix(),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: text ? moment(text).format('DD/MM/YYYY') : '-',
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Tipo de presupuesto',
        dataIndex: 'presupuesto',
        Key:'presupuesto',
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Asistencia',
        dataIndex: 'asistencia_paciente',
        Key:'asistencia_paciente',
        render: (text: any, record: any) => {
          const obj = {
            children: text === '' ? '-' : text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Total presupuestado',
        dataIndex: 'total_presupuestado',
        Key:'total_presupuestado',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.total_presupuestado) - parseInt(b.total_presupuestado),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: formatToEUR(text),
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Total aceptado',
        dataIndex: 'total_aceptado',
        Key:'total_aceptado',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.total_aceptado) - parseInt(b.total_aceptado),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: formatToEUR(text),
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
        title: 'Comentario',
        dataIndex: 'parte_presupuesto_observaciones',
        Key:'parte_presupuesto_observaciones',
        width:'8vw',
        render: (text: any, record: any) => {
          const obj = {
            children: text === '' ? '-' : text,
            props: {
              onClick: ()=>showModalEdit(record.main_id)
            }
          }
          return obj
        }
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      width: '6vw',
      render: (text: any, record: any) => {
        const obj = {
          children:  <DeleteOutlined style={{ fontSize: '20px', color: '#47464E' }} />,
          props: {
            onClick: ()=>showModalWatch(record.main_id)
          }
        }
        return obj
      }
    }
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [checkedData, setCheckedData] = useState({
        rojas: true,
        verdes: true,
        amarillo: true,
        blancas: true
    })

    const [user, setUser] = useState({
    nombre_apellidos: '',
    numero_telefono: '',
    fecha_visita: false,
    
    presupuesto: '',
    aceptado: '',

    TiposPresupuesto: {
        id: '',
        nombre: ''
    },

    user: {
        id: '',
        nombre: ''
    },

    doctor: {
        id: '',
        name: ''
    },

    entidad_financiera: {
        id: '',
        nombre: ''
    },

    total:{
        presupuestado: 0,
        aceptado: 0
    },
    especialidadesPresupuestoAceptado: false,

    derivable_periodoncia: '',
    derivable_estetica: '',
    tiene_ausencias: '',
    numero_ausencias: '',
    apinamiento_oclusion: '',
    derivado: false,
    derivadoEspecialidades: false,

    fecha_etto: '',
    asistencia_paciente: '',
    
    TiposPagos:{
        id: '',
        nombre: ''
    },

    observaciones: '',
    cantidad_financiada: '',
    costes_financieros: '',

    total_financiacion: 0
  });
  const [selectedUserName, setSelectedUserName] = useState('');

  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.main_id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);


  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name

    setIsModalWatchVisible(true);
    setIsModalEditVisible(false)
    setIsModalAddVisible(false)
  };

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalAddVisible(false)
    setIsModalWatchVisible(false)
    setIdSelectedEdit(0);
    setUser({
      nombre_apellidos: '',
      numero_telefono: '',
      fecha_visita: false,
      
      presupuesto: '',
      aceptado: '',

      TiposPresupuesto: {
          id: '',
          nombre: ''
      },

      user: {
          id: '',
          nombre: ''
      },

      doctor: {
          id: '',
          name: ''
      },

      entidad_financiera: {
          id: '',
          nombre: ''
      },

      total:{
          presupuestado: 0,
          aceptado: 0
      },
      especialidadesPresupuestoAceptado: false,

      derivable_periodoncia: '',
      derivable_estetica: '',
      tiene_ausencias: '',
      numero_ausencias: '',
      apinamiento_oclusion: '',
      derivado: false,
      derivadoEspecialidades: false,

      fecha_etto: '',
      asistencia_paciente: '',
      
      TiposPagos:{
          id: '',
          nombre: ''
      },

      observaciones: '',
      cantidad_financiada: '',
      costes_financieros: '',

      total_financiacion: 0
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleOk = (form: any) => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(edit(form));
    handleCancel()
  };

  const filters = useSelector((state:any) => state.Filters)
  const {date, lastDates} = useSelector((state:any) => state.ResumenGlobal)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());

  }, [dispatch, filters, date, lastDates]);



  const { loading, partePresupuestoArray} = useSelector((state: any) => state.PartePresupuesto);

  const [data, setData] = useState(partePresupuestoArray || [{}]);
  const [dataFiltered, setDataFiltered] = useState(partePresupuestoArray || [{}])

  useEffect(() => {
      UpdateFilters();
    setData(partePresupuestoArray);
  }, [partePresupuestoArray]);

  const { Search } = Input;

  const onSearch = (value: any) => {
   /* const results: any = [];

    if (value) {
      partePresupuestoArray.forEach((user: any) => {
        const { nombre_apellidos } = user;
        const superString = `${nombre_apellidos}`;
        if (superString.toLowerCase().includes(value.toLowerCase())) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(partePresupuestoArray);
    }*/
      UpdateFilters();
  };

    function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const handleClickCheckbox = (filterState: any, searchClick:boolean = false) =>{
    let {rojas, verdes, blancas, amarillo} = filterState

      var results: any = [];
      // @ts-ignore
      var search_word: any = null;
      if(SearchButtonRef.current!=null) {
          search_word = SearchButtonRef.current.input.value ?? null;
      }
      if(search_word!=null && search_word!="") {
          partePresupuestoArray.forEach((user: any) => {
              const {nombre_apellidos} = user;
              const superString = `${nombre_apellidos}`;
              if (superString.toLowerCase().includes(search_word.toLowerCase())) {
                  results.push(user);
              }
          });
      }else{
          results = partePresupuestoArray;
      }


      let searchedRojas = results.filter( (item:any)=>{
        if(rojas){
          return item.asistencia_paciente === 'No' && item.fecha_etto
        }
      })

      let searchedVerdes = results.filter( (item:any)=>{
        if(verdes){
          return item.asistencia_paciente === 'Si' && item.fecha_etto
        }
      })
      
      let searchedBlancas = results.filter( (item:any)=>{
        if(blancas){
          return !item.fecha_etto 
        }
      })

      let searchedAmarillo = results.filter( (item:any)=>{
        if(amarillo){
          return item.asistencia_paciente === '' && item.fecha_etto
        }
      })
      setData([
        ...searchedRojas,
        ...searchedAmarillo,
        ...searchedBlancas,
        ...searchedVerdes
      ])
      setCheckedData(filterState);

  }
  
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuracion / Presupuestos',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.PartePresupuesto);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const handleDelete = ()=>{
    dispatch(deletePresupuesto(idSelected))
    setIsModalWatchVisible(false)
  }

  const onsubmit = (form: any)=> {
      dispatch(savePartePresupuestos(form))
  }
  const UpdateFilters =()=>{

        setTimeout(function(){

            handleClickCheckbox(checkedData);

        }, 1000);
    }

  const SearchButtonRef = useRef<Input>(null);

  return (
    <div>
      <PageHeader title="PRESUPUESTOS" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container" >
        <div className="search">
          <Dropdown overlay={menu}>
            <Button style={{height: '100%'}}>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
            ref={SearchButtonRef}
            />
        </div>
      <div className="search">

        <Filter 
            handleClick={handleClickCheckbox}
            />
        </div>
        
        <div className="search">

          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data}
            headers={headers}
            filename={'partePresupuestoArray.csv'}
            separator={','}
            >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          
          <CustomButton 
            style={{
              backgroundColor: '#245046',
              width: '97px',
              height: '39px',
              color: '#ffffff',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer'
            }}
            onClick={()=>setIsModalAddVisible(true)}  
            >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>
          </div>

          <CumplimentacionModal
              isModalVisible={isModalAddVisible}
              onCancel={handleCancel}
              formData={false}
              onSubmit={onsubmit}
            />

          <CumplimentacionModal
              formData={user}
              isModalVisible={isModalEditVisible}
              onCancel={handleCancel}
              onSubmit={handleOk}
            />
      </div>

      <div className="content_container">
        <Table
            rowKey="Id"
          rowClassName={(record, index) => {
              switch(record.asistencia_paciente){
                case 'Si':
                  return 'table-asistencia-success'

                case 'No':
                  return 'table-asistencia-failure'

                default:
                  if(!record.fecha_etto){
                    return 'default-table-parte-presupuesto-tiny'
                  } else {    
                    return 'default-table-parte-presupuestos' 
              }
            }
          }}
          columns={columns}
          dataSource={data}
          scroll={{x: true}}
          pagination={{ pageSize }}
          loading={loading}
          // onRow={ (record, rowIndex)=>{
          //   return {
          //     onClick: event => {
          //       showModalEdit(record.main_id)
          //     }
          //   }
          // }}
        />

        <Modal
          visible={isModalWatchVisible}
          onCancel={handleCancel}
          onOk={handleDelete}
          title='Eliminar Presupuesto'
          >
            <h3>¿Estás seguro que deseas eliminar el registro seleccionado?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Especialidades;