import React from 'react'

import styles from './styles.module.scss'
import formatToPercentage from '../../../../../../helpers/formatPercentNo100'
import formatToEuro from '../../../../../../helpers/formatToEuro'

import { colorChooser } from '../../../../../../components/progress-bar'

export default function PercentageNumber(props: any) {
    let {value, label} = props
    return (
        <div className={styles.percentage_wrapper}>
            <span className={styles.label}>{label}</span>
            <span className={styles.value} style={{color: colorChooser(value)}}>{label === 'PRESUPUESTO MEDIO' ? formatToEuro(value) : formatToPercentage(value)}</span>
        </div>
    )
}
