import CajaDiaServices from "./services";

import clinicasConstants from "./constants";

import store from "../rootStore";
import { fetchAll as fetchObjetivos } from "../objetivos/actions";
import { getObjetivoPrevision } from "../global/actions";

const {
  SET_CAJA_DIA,
  SET_LOADING_CAJA_DIA,
  SET_SELECTED_ROW_KEYS,
  SET_SAVING_CAJA_DIA,
} = clinicasConstants;

export const setLoadingCajaDia = (isLoadingCajaDia: boolean) => ({
  type: SET_LOADING_CAJA_DIA,
  isLoadingCajaDia,
});

const setCajaDia = (cajaDia: any) => ({
  type: SET_CAJA_DIA,
  cajaDia,
});

export const fetchAll = () => {
  return (dispatch: any) => {
    dispatch(setLoadingCajaDia(true));
    let { Filters, ResumenGlobal } = store.getState();
    let anyHandler: any = ResumenGlobal;
    CajaDiaServices.fetchAll(
      {
        starts: anyHandler.date.starts,
        ends: anyHandler.date.ends,
        last: anyHandler.lastDates,
      },
      Filters
    ).then((res) => {
      dispatch(setLoadingCajaDia(false));
      return dispatch(setCajaDia(res.data.response));
    });
  };
};

const { SET_EDITING_CAJA_DIA, SET_EDIT_ERROR, SET_EDIT_OK } = clinicasConstants;

const setEditingCajaDia = (isEditingCajaDia: boolean) => ({
  type: SET_EDITING_CAJA_DIA,
  isEditingCajaDia,
});

const setEditError = (editError: string | null) => ({
  type: SET_EDIT_ERROR,
  editError,
});

const setEditOk = (editOk: boolean) => ({
  type: SET_EDIT_OK,
  editOk,
});

export const edit = (data: any) => {
  return (dispatch: any) => {
    dispatch(setEditingCajaDia(true));
    dispatch(setEditError(null));
    CajaDiaServices.edit(
      data.id,
      data.Centro,
      data.tiposPagos,
      data.numero_pagos,
      data.cantidad,
      data.fecha_pago,
      data.observaciones
    )
      .then((res: any) => {
        dispatch(setEditingCajaDia(false));
        dispatch(setEditOk(true));
        dispatch(fetchAll());
        dispatch(fetchObjetivos());
        dispatch(getObjetivoPrevision());
      })
      .catch((err) => {
        if (
          // Messy
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setEditError(err.response.data.errors.message));
        } else {
          dispatch(setEditError("Ha ocurrido un error"));
        }
        dispatch(setEditingCajaDia(false));
      });
  };
};

export const deleteCajaDia = (data: any) => {
  return (dispatch: any) => {
    CajaDiaServices.delete(data)
      .then((res) => {
        let { message } = res.data;

        if (message === "deleted succesfully") {
          dispatch(fetchAll());
          dispatch(getObjetivoPrevision());
          dispatch(fetchObjetivos());
        }
      })
      .catch((e) => console.log(e));
  };
};

const selecteddRowKeys = (selectedRowKeys: any) => ({
  type: SET_SELECTED_ROW_KEYS,
  selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
  return (dispatch: any) => {
    return dispatch(selecteddRowKeys(selectedRowKeys));
  };
};

const savingCajaDia = (saving: boolean | null) => ({
  type: SET_SAVING_CAJA_DIA,
  saving,
});

export const saveCajaDia = (data: any) => {
  return (dispatch: any) => {
    try {
      dispatch(setLoadingCajaDia(true));
      dispatch(savingCajaDia(true));
      CajaDiaServices.save(
        data.Centro,
        data.tiposPagos,
        data.numero_pagos,
        data.cantidad,
        data.fecha_pago,
        data.observaciones
      ).then((res) => {
        dispatch(fetchAll());
        dispatch(setLoadingCajaDia(false));
        dispatch(fetchObjetivos());
        dispatch(getObjetivoPrevision());
      });
    } catch (e) {
      console.log(e);
    }
  };
};

