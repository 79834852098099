import React, { useState } from 'react';
import { Form, Input, Button, Result } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import logo from '../../assets/dentalpro-horizontal-logo.svg';
import authServices from '../../redux/auth/services';

function RecoverRequest() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onFinish = async ({ email }: { email: string }) => {
    try {
      setError(undefined);
      setLoading(true);
      await authServices.requestRecovery(email);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(err.response.data.errors.message);
      setLoading(false);
    }
  };

  if (success) {
    return (
      <Result
        status="success"
        title="Debe haber llegado un link de recuperación a tu email"
        style={{ paddingTop: 200 }}
        extra={[
          <Link to="/login">
            <Button type="primary" key="login">
              Volver a la página principal
            </Button>
          </Link>,
        ]}
      />
    );
  }

  return (
    <div className={styles.login_form_container}>
      <img src={logo} alt="Logo" className={styles.login_form_logo} />
      <Form name="recovery_request" onFinish={onFinish}>
        <Form.Item
          className={styles.space}
          label="Introduce el email de la cuenta a recuperar"
          name="email"
          rules={[
            { required: true, message: 'Por favor introduce tu email' },
            { type: 'email', message: 'Introduce un email válido' },
          ]}
          validateStatus={error && 'error'}
          help={error || null}
        >
          <Input
            prefix={
              <MailOutlined
                className="site-form-item-icon"
                style={{ color: '#199479' }}
              />
            }
            placeholder="Tu correo"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item className={styles.space}>
          <Link className={styles.login_form_forgot} to="/login">
            ¿Tienes cuenta?
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login_form-submit"
            disabled={loading}
            block
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default RecoverRequest;
