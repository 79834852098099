import PrevisionesServices from "./services";

import clinicasConstants from "./constants";

import { getObjetivoPrevision } from "../global/actions";
import store from "../rootStore";
const {
    SET_PREVISIONES,
    SET_LOADING_PREVISIONES,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_PREVISIONES
} = clinicasConstants

export const setLoadingPrevisiones = (isLoadingPrevisiones: boolean) => ({
    type: SET_LOADING_PREVISIONES,
    isLoadingPrevisiones,
  });
  
  const setPrevisiones = (previsiones: any) => ({
    type: SET_PREVISIONES,
    previsiones,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingPrevisiones(true));
      let {ResumenGlobal, Filters} = store.getState()
      let {date: globalDate, lastDates} : any = ResumenGlobal

      let date = {
        starts: globalDate.starts.format('YYYY-MM-DDTHH:mm:ss'),
        ends: globalDate.ends.format('YYYY-MM-DDTHH:mm:ss')
      }

      PrevisionesServices.fetchAll(date, lastDates, Filters).then((res) => {
        dispatch(setLoadingPrevisiones(false));
        return dispatch(setPrevisiones(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_PREVISIONES, SET_EDIT_ERROR, SET_EDIT_OK } = clinicasConstants;
  
  const setEditingPrevisiones = (isEditingPrevisiones: boolean) => ({
    type: SET_EDITING_PREVISIONES,
    isEditingPrevisiones,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const edit = (data: any) => {
    return (dispatch: any) => {
      dispatch(setLoadingPrevisiones(true));
      dispatch(setEditError(null));
      PrevisionesServices
        .edit(
          data.prevision_id,
          data.Centro,
          data.Paciente,
          data.User,
          data.cantidad,
          data.fecha_prevision,
          data.observaciones,
        )
        .then((res: any) => {
          dispatch(setLoadingPrevisiones(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
          dispatch(getObjetivoPrevision())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingPrevisiones(false));
        });
    };
  };
  
  export const deletePrevision = (data: any) => {
    return (dispatch: any) =>{
      PrevisionesServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") {
          dispatch(fetchAll())
          dispatch(getObjetivoPrevision())
        }
      })
      .catch(e => console.log(e))
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingPrevisiones = (saving: boolean | null)=>({
    type: SET_SAVING_PREVISIONES,
    saving
  })

  export const savePrevisiones = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingPrevisiones(true));
      PrevisionesServices.save(
          data.Centro,
          data.Paciente,
          data.User,
          data.cantidad,
          data.fecha_prevision,
          data.observaciones,
        ).then(res =>{
          dispatch(setLoadingPrevisiones(false));
          dispatch(fetchAll())
          dispatch(getObjetivoPrevision())
        })
      }catch(e){
        console.log(e)
      }
    }
  }