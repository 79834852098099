const centrosConstants = {
    SET_LOADING_TIPOS_PAGOS: 'SET_LOADING_TIPOS_PAGOS',
    SET_TIPOS_PAGOS: 'SET_TIPOS_PAGOS',
    SET_EDITING_TIPOS_PAGOS: 'SET_EDITING_TIPOS_PAGOS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_TIPOS_PAGOS: 'SET_SAVING_TIPOS_PAGOS'
  };
  
  export default centrosConstants;
  