import React from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';


import Users from './users/';
import Admins from './admins/';
import Clinicas from './clinicas/';
import Centros from './centros/'
import Entidades from './entidades-financieros';
import Especialidades from './especialidades';
import Empleados from './configuracion/empleados'
import Pais from './configuracion/pais'

import Objetivos from './objetivos'

import Cumplimentacion from './cumplimentacion';
import Resumen from './resumen'

import TiposPagos from './tipos/pagos';
import TiposEmpleados from './tipos/empleados';
import TiposPresupuestos from './tipos/presupuesto';
import TiposOrtodoncias from './tipos/ortodoncias';

import Global from './resumen/pages/global'


import { useDispatch, useSelector } from 'react-redux';



export default function SwitchSelector() {
    const {user} = useSelector((state: any)=>state.auth)

    if(user && user.role === 'Admin') return (
        <div>
            <Switch>
                <Route path="/cumplimentacion" component={Cumplimentacion}/>
                <Route path="/objetivos" component={Objetivos} />
                <Route path="/resumen" component={Resumen} />
                <Route path='/configuracion/empleados' component={Empleados} exact/>
                <Route path='/configuracion/pais' component={Pais} exact/>
                <Route path="/usuarios" component={Users} exact />
                <Route path="/administradores" component={Admins} />
                <Route path="/clinicas" component={Clinicas} />
                <Route path="/centros" component={Centros} />
                <Route path="/entidades-financieras" component={Entidades}/>
                <Route path='/especialidades' component={Especialidades}/>
                <Route path='/tipos/pagos' component={TiposPagos} /> 
                <Route path='/tipos/presupuestos' component={TiposPresupuestos} /> 
                <Route path='/tipos/empleados' component={TiposEmpleados} /> 
                <Route path='/tipos/ortodoncia' component={TiposOrtodoncias} /> 
                <Route path='/resumen/global' exact component={Global}/>
            </Switch>
        </div>
    )
    
    if(user && user.role === 'Gerente') return (
        <div>
            <Switch>
                <Route path="/cumplimentacion" component={Cumplimentacion}/>
                <Route path="/objetivos" component={Objetivos} />
                <Route path="/resumen" component={Resumen} />

                <Route path="/cumplimentacion" component={Cumplimentacion}/>
                <Route path="/objetivos" component={Objetivos} />
                <Route path="/resumen" component={Resumen} />
                <Route path='/configuracion/empleados' component={Empleados} exact/>
            </Switch>
        </div>
    )

    if(user && user.role === 'Consultor') return (
        <div>
            <Switch>
                <Route path='/resumen/global' exact component={Global}/>
                <Route path="/cumplimentacion" component={Cumplimentacion}/>
                <Route path="/objetivos" component={Objetivos} />
                <Route path="/resumen" component={Resumen} />
                <Route path='/configuracion/empleados' component={Empleados} exact/>
            </Switch>
        </div>
    )

    if(user && user.role === 'Director') return (
        <div>
            <Switch>
                <Route path='/resumen/global' exact component={Global}/>
                <Route path="/cumplimentacion" component={Cumplimentacion}/>
                <Route path="/objetivos" component={Objetivos} />
                <Route path="/resumen" component={Resumen} />
                <Route path='/configuracion/empleados' component={Empleados} exact/>
            </Switch>
        </div>
    )

    return (
        <div>
    
        </div>
    )
}
