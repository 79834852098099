import React from 'react'
import {Switch, Route} from 'react-router-dom'

import PartePresupuesto from './pages/parte_presupuestos'
import Previsiones from './pages/previsiones'
import CajaDia from './pages/caja_dia'
import Financiaciones from './pages/financiaciones'

interface Props {}

function CumplimentacionIndex(props: Props) {
    const {} = props

    return (
        <>
            <Switch>
                <Route path='/cumplimentacion/parte-presupuesto' exact component={PartePresupuesto}/>
                <Route path='/cumplimentacion/previsiones' exact component={Previsiones}/>
                <Route path='/cumplimentacion/caja-del-dia' exact component={CajaDia}/>
                <Route path='/cumplimentacion/financiaciones' exact component={Financiaciones}/>
            </Switch>
        </>   
    )
}

export default CumplimentacionIndex
