import usersService from './services';

import usersConstants from './constants';
import { servicesVersion } from 'typescript';

import { fetchLists } from '../../redux/admins/actions';
import { getObjetivoPrevision } from '../global/actions';

import store from '../rootStore';

const {
  SET_LOADING_USERS,
  SET_USERS,
  SET_HOME,
  SET_EDIT_OK,
  SET_SELECTED_ROW_KEYS,
  SET_SAVING_ERROR,
  SET_USER,
  SET_USER_ARRAY
} = usersConstants;

export const setLoadingUsers = (isLoadingUsers: boolean) => ({
  type: SET_LOADING_USERS,
  isLoadingUsers,
});

const setUsers = (users: any) => ({
  type: SET_USERS,
  users,
});

export const fetchAllUsers = () => {
  return (dispatch: any) => {
    dispatch(setLoadingUsers(true));
    let {Filters} = store.getState()
    usersService.fetchAll(Filters).then((res) => {
      dispatch(setLoadingUsers(false));
      dispatch(getObjetivoPrevision())
      return dispatch(setUsers(res.data.response));
    });
  };
};

// NEW
const { CHECKING_HOME } = usersConstants;
const setCheckingHome = (isChecking: boolean) => ({
  type: CHECKING_HOME,
  isChecking,
});

const setHome = (home: any) => ({ type: SET_HOME, home });

export const fetchHome = (id: any) => {
  return async (dispatch: any) => {
    dispatch(setCheckingHome(true));
    try {
      const response = await usersService.fetchUserHose(id);
      if (response.data.response) {
        dispatch(setHome(response.data.response));
      }
      dispatch(setCheckingHome(false));
    } catch (e) {
      dispatch(setCheckingHome(false));
    }
  };
};

const { SET_EDITING_USER, SET_EDIT_ERROR } = usersConstants;
const setEditingUser = (isEditingUser: boolean) => ({
  type: SET_EDITING_USER,
  isEditingUser,
});

const setEditError = (editError: string | null) => ({
  type: SET_EDIT_ERROR,
  editError,
});

const setEditOk = (editOk: boolean) => ({
  type: SET_EDIT_OK,
  editOk,
});

export const editUser = (data: any) => {
  return (dispatch: any) => {
    dispatch(savingError(''));
    usersService
      .editUser(
        data.id,
        data.username,
        data.role,
        data.clinica_data,
        data.clinica_name,
        data.name,
        data.lastname,
        data.telefono,
        data.dni,
        data.email,
        data.password,
        data.fecha_alta,
        data.fecha_baja,
        data.centros_id,
        data.observaciones,
        data.activo)
      .then((res: any) => {
        dispatch(fetchAllUsers())
        dispatch(fetchLists())
        dispatch(getUser(data.id))
        dispatch(savingError('saved'))
      })
      .catch((e) => {
        if (e.response.status === 409){
          dispatch(savingError('exist'))
        } else if (e.response.status === 422) {
          dispatch(savingError('form'))
        }else {
          dispatch(savingError('error'))
        }
      });
  };
};

export const deleteUser = (data: any) => {
  return (dispatch: any) =>{
    usersService.deleteUser(data)
    .then(res => {
      let {message} = res.data

      if(message === "deleted succesfully") dispatch(fetchAllUsers())
    })
    .catch(e => console.log(e))
  }
}

const selecteddRowKeys = (selectedRowKeys: any) => ({
  type: SET_SELECTED_ROW_KEYS,
  selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
  return (dispatch: any) => {
    return dispatch(selecteddRowKeys(selectedRowKeys));
  };
};


export const saveUser = (data: any)=>{
  return (dispatch: any)=>{
    dispatch(savingError(''))
    usersService
      .saveUser(
        data.username,
        data.role,
        data.clinica_data,
        data.clinica_name,
        data.name,
        data.lastname,
        data.telefono,
        data.dni,
        data.email,
        data.password,
        data.centros_id,
        data.fecha_alta,
        data.fecha_baja,
        data.observaciones,
        data.activo)
      .then((res:any)=>{
        dispatch(savingError('saved'))
        dispatch(fetchLists())
        dispatch(fetchAllUsers())
      })
      .catch( e =>{
        if (e.response.status === 409){
          dispatch(savingError('exist'))
        } else if (e.response.status === 422) {
          dispatch(savingError('form'))
        }else {
          dispatch(savingError('error'))
        }
      })
  }
}

const savingError = (savingError: any)=>({
  type: SET_SAVING_ERROR,
  savingError
})

export const setUser = (user: boolean)=>({
  type: SET_USER,
  user
})

const setUserArray = (userArray: any)=>({
  type: SET_USER_ARRAY,
  userArray
})

export const getUser = (id: any)=>{
  return (dispatch:any)=>{
    if(id){
      usersService
        .getUser(id)
        .then( (res)=>{
          dispatch(setUserArray(res.data))
        })
        .catch((e:any)=>console.log(e))
    }
  }
}
