import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'

import { fetchListsPrevisiones } from '../../../../../../redux/admins/actions'

interface Props {
    isModalVisible: boolean
    onCancel: any
    onSubmit: any
    formData: any
}


function Index(props: Props) {
    const {isModalVisible, onCancel, onSubmit, formData} = props
    const dispatch = useDispatch()

    let {profile, isLoading} = useSelector( (state:any)=>state.PartePresupuesto)
    let {centrosLists, directorLists, pacienteLists} = useSelector( (state:any)=>state.admins)

    const [data, setData] = useState({
        id: false,
        fecha_prevision: false,
        cantidad: '',
        Centro: {
            id: '',
            nombre: ''
        },
        User: {
            id: '',
            nombre: ''
        },
        Paciente: {
            id: '',
            nombre: ''
        },
        observaciones: ''
    })

    useEffect(() => {
        if(formData){
            syncData(formData)
        }

        if(!formData){
            resetData()
        }
    }, [formData])

    useEffect(() => {
        dispatch(fetchListsPrevisiones())
    }, [])

    const syncData = (newData: any)=>{
        setData({
                ...newData,
                observaciones: newData.previsiones_observaciones,
                User: {
                    id: newData.selected_user_id,
                    nombre: newData.name
                },
                Centro: {
                    id: newData.prevision_centro_id,
                    nombre: newData.clinica_nombre
                },
                Paciente: {
                    id: newData.parte_presupuesto_id,
                    nombre: newData.nombre_apellidos
                },
            })
    }
    
    const handleChange=(event: any)=>{
        if(event.target){
            let {name, value} = event?.target
            setData({...data, [name]:value})
        }
    }

    const sortFromList = (name: any, value:any) =>{
        if(name === 'User'){
            let sortedValue = directorLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'Paciente'){
            let sortedValue = pacienteLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'Centro'){
            let sortedValue = centrosLists.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if( name === 'cantidad'){
            handleChangeNameValue(name, value)
        }

        if(name === 'fecha_prevision'){
            handleChangeNameValue(name, value.format())
        }
    }

    const handleChangeNameValue = (name:any, value:any)=>{
        if(
            name === 'cantidad' ||
            name === 'fecha_prevision'
        ) setData({...data, [name]: value})
        
        if(name !== 'cantidad') setData({
            ...data,
            [name]: value[0]
        })
    }
    
    const handleChangeDate = (name: string, e:any)=>{
        if(e){
        let date = e
        setData({...data, [name]:date})

        }else{
            setData({...data, [name]: ''})
        }    
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        handleClose()
    }

    const resetData = ()=>{
        setData({
            id: false,
            fecha_prevision: false,
            cantidad: '',
            Centro: {
                id: '',
                nombre: ''
            },
            User: {
                id: '',
                nombre: ''
            },
            Paciente: {
                id: '',
                nombre: ''
            },
            observaciones: ''
        })
    }

    const handleClose = ()=>{
        resetData()
        onCancel()
    }


    if(isLoading) return (
        <div>
            loading
        </div>
    ) 

    return (
        <Form 
            isVisible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            data={data}
            
            centrosLists={centrosLists}
            directorLists={directorLists}
            pacienteLists={pacienteLists}

            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleChangeNameValue={sortFromList}
        />
    )
}

export default Index
