import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  message,
  Tag,
  Checkbox,
} from "antd";
import { TweenOneGroup } from "rc-tween-one";

import { getCentro } from "../../../../redux/centros/actions";
import Item from "antd/lib/list/Item";

import ButtonSection from "../../../../components/button-sections";

interface Props {
  type: string | boolean;
  prevData: any;
  isVisible: boolean;
  onClose?: any;
  onSubmit: any;
}

function AddClinicaModal(props: Props) {
  const { type, isVisible, onClose, onSubmit, prevData } = props;

  const { Option } = Select;
  const { TextArea } = Input;

  let dispatch = useDispatch();

  const { clinicaLists, especialidadesLists, consultorLists, paisLists } =
    useSelector((state: any) => state.admins);
  const { centroProfile } = useSelector((state: any) => state.centros);

  useEffect(() => {
    if (type !== "edit") {
      setData({
        clinica_nombre: "",
        clinica_id: "",
        clinica_select_nombre: "",

        direccion: "",
        codigo_postal: "",

        pais: "Elige una opción",
        poblacion: "",

        consultor: "",
        consultor_nombre: "",

        numero_telefono: "",
        email: "",

        contacto_nombre: "",
        contacto_numero: "",

        especialidades_data: [],
        especialidades_nombre: "Elige una opción",

        observaciones: "",
      });
    }
  }, [type]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getCentro(prevData.id));
    }
  }, [prevData]);

  useEffect(() => {
    if (type === "edit") {
      syncArray(centroProfile);
    }
  }, [centroProfile, type]);

  useEffect(() => {
    setEspecialidadesArray(especialidadesLists);
  }, [especialidadesLists]);

  const [especialidadesArray, setEspecialidadesArray] = useState(
    especialidadesLists || []
  );

  const [data, setData] = useState<any>({
    clinica_nombre: "",
    clinica_id: "",
    clinica_select_nombre: "",

    direccion: "",
    codigo_postal: "",

    pais: "Elige una opción",
    poblacion: "",

    consultor: "",
    consultor_nombre: "",
    numero_telefono: "",

    contacto_nombre: "",
    contacto_numero: "",
    email: "",

    especialidades_data: [],
    especialidades_nombre: "Elige una opción",

    observaciones: "",
    activo: true,
  });

  const handleChange = (event: any) => {
    let { name, value, type, checked } = event?.target;
    if (type === "checkbox") {
      setData({ ...data, [name]: checked });
    }

    if (type !== "checkbox") {
      setData({ ...data, [name]: value });
    }
  };

  const syncArray = (oldArray: any) => {
    let newArray: any = [];
    if (oldArray.especialidades) {
      for (var i = 0; i < especialidadesLists.length; i++) {
        var igual = false;
        for (var j = 0; j < oldArray.especialidades.length && !igual; j++) {
          if (
            especialidadesLists[i]["id"] == oldArray.especialidades[j]["id"] &&
            especialidadesLists[i]["nombre"] ==
              oldArray.especialidades[j]["nombre"]
          )
            igual = true;
        }
        if (!igual) newArray.push(especialidadesLists[i]);
      }

      setEspecialidadesArray(newArray);
      //
      let clinicaReformatted;

      if (oldArray.clinicas !== "") {
        clinicaReformatted = clinicaLists.filter((clinica: any) => {
          return clinica.id === oldArray.clinicas.id;
        });
      }

      setData({
        ...oldArray,
        clinica_id: clinicaReformatted[0] ? clinicaReformatted[0].id : "",
        clinica_select_nombre: clinicaReformatted[0]
          ? clinicaReformatted[0].nombre_comercial
          : "Elige una opción",
        consultor: oldArray && oldArray.usuarios ? oldArray.usuarios.id : "",
        consultor_nombre:
          oldArray && oldArray.usuarios
            ? `${oldArray.usuarios.name} ${oldArray.usuarios.lastname}`
            : "Elige una opción",
        especialidades_data: oldArray.especialidades,
      });
    }
  };

  const handleChangeSelect = (name: string, value: string) => {
    setData({ ...data, [name]: value });

    if (name === "clinica_id") {
      setData({
        ...data,
        clinica_id: value,
        clinica_select_nombre: value,
      });
    }

    if (name === "consultor") {
      setData({
        ...data,
        consultor: value,
        consultor_nombre: value,
      });
    }

    if (name === "especialidades_array") {
      if (especialidadesArray.length > 0) {
        setEspecialidadesArray(
          especialidadesArray.filter((item: any) => {
            return item.id !== value;
          })
        );
      }

      if (especialidadesArray.length == 0) {
        setEspecialidadesArray(
          especialidadesLists.filter((item: any) => {
            return item.id !== value;
          })
        );
      }

      value = especialidadesLists.find((item: any) => {
        return item.id == value;
      });
      let especialidadesWrapper: any = [];

      if (data.especialidades_data) {
        especialidadesWrapper = [...data.especialidades_data, value];
      }

      if (!data.especialidades_data) {
        especialidadesWrapper = [value];
      }
      let length = especialidadesWrapper.length;
      setData({
        ...data,
        especialidades_data: especialidadesWrapper,
        especialidades_nombre: especialidadesWrapper[length - 1].nombre,
      });
    }
  };

  const handleSubmit = () => {
    onSubmit(data);
    handleClose();
  };

  const forMap = (tag: any) => {
    const tagElem = (
      <Tag
        closable
        style={{
          height: 29,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#245046",
          color: "white",
        }}
        onClose={(e: any) => {
          e.preventDefault();
          handleCloseTag(tag);
        }}
      >
        {tag.nombre}
      </Tag>
    );
    return (
      <span key={tag.id} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const handleCloseTag = (tag: any) => {
    let { id } = tag;

    setEspecialidadesArray([...especialidadesArray, tag]);

    setData({
      ...data,
      especialidades_data: data.especialidades_data.filter((item: any) => {
        return item.id != id;
      }),
    });
  };

  const tagChild =
    data.especialidades_data && data.especialidades_data.map(forMap);

  const handleClose = () => {
    onClose();
    setData({
      clinica_nombre: "",
      clinica_id: "",
      clinica_select_nombre: "",

      direccion: "",
      codigo_postal: "",

      pais: "Elige una opción",
      poblacion: "",

      consultor: "",
      consultor_nombre: "",
      numero_telefono: "",

      contacto_nombre: "",
      contacto_numero: "",
      email: "",

      especialidades_data: [],
      especialidades_nombre: "Elige una opción",

      observaciones: "",
    });
  };

  return (
    <Modal
      title="Añadir centro"
      visible={isVisible}
      onCancel={handleClose}
      onOk={handleSubmit}
      width={1020}
      footer={null}
      //headerStyle={{backgroundColor: '#E3EFEA', border: '1px solid #E3EFEA'}}
      bodyStyle={{ backgroundColor: "#E3EFEA", display: "grid" }}
      style={{ top: 20 }}
      okText="Guardar"
      cancelText="Cancelar"
    >
      <Form onFinish={handleSubmit}>
        <Row
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateColumns: "315px 315px 315px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={24}>
            <Form.Item
              label="Nombre clínica"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "El campo es obligatorio" }]}
            >
              <Input
                name="clinica_nombre"
                onChange={handleChange}
                value={data.clinica_nombre}
                placeholder="Escribe el nombre"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Selecciona una clínica"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              {type === "edit" ? (
                <Select
                  onChange={(e: any) => handleChangeSelect("clinica_id", e)}
                  value={data.clinica_select_nombre}
                  placeholder="Elige una opción"
                >
                  {clinicaLists.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.nombre_comercial}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  onChange={(e: any) => handleChangeSelect("clinica_id", e)}
                  placeholder="Elige una opción"
                >
                  {clinicaLists.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.nombre_comercial}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Dirección"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "El campo es obligatorio" }]}
            >
              <Input
                name="direccion"
                onChange={handleChange}
                value={data.direccion}
                placeholder="Escribe el telefono"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateColumns: "315px 315px 315px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={24}>
            <Form.Item
              label="Código postal"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "El campo es obligatorio" }]}
            >
              <Input
                name="codigo_postal"
                onChange={handleChange}
                value={data.codigo_postal}
                placeholder="Escribe la dirección de correo"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Selecciona un país"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                onChange={(e) => handleChangeSelect("pais", e)}
                value={data.pais}
                placeholder="Elige una opción"
              >
                <Option value=""> ----------</Option>
                {paisLists.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Población"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                name="poblacion"
                onChange={handleChange}
                value={data.poblacion}
                placeholder="Escriba la población"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateColumns: "315px 315px 315px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={24}>
            <Form.Item
              label="Selecciona un consultor"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "El campo es obligatorio" }]}
            >
              {type === "edit" ? (
                <Select
                  onChange={(e: any) => handleChangeSelect("consultor", e)}
                  value={data.consultor_nombre}
                  placeholder="Elige una opción"
                >
                  {consultorLists.map((item: any) => (
                    <Option
                      key={item.id}
                      value={item.id}
                    >{`${item.name} ${item.lastname}`}</Option>
                  ))}
                </Select>
              ) : (
                <Select
                  onChange={(e: any) => handleChangeSelect("consultor", e)}
                  placeholder="Elige una opción"
                >
                  {consultorLists.map((item: any) => (
                    <Option
                      key={item.id}
                      value={item.id}
                    >{`${item.name} ${item.lastname}`}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Teléfono"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: "El campo es obligatorio" }]}
            >
              <Input
                name="numero_telefono"
                onChange={handleChange}
                value={data.numero_telefono}
                placeholder="Escribe el número"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email de contacto"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Por favor introduce tu email" },
                { type: "email", message: "Introduce un email válido" },
              ]}
            >
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                value={data.email}
                placeholder="Escriba la direccion de correo"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateColumns: "315px 315px 315px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={24}>
            <Form.Item
              label="Persona de contacto"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Por favor introduce su DNI" },
              ]}
            >
              <Input
                name="contacto_nombre"
                onChange={handleChange}
                value={data.contacto_nombre}
                placeholder="Escribe el nombre"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Teléfono de contacto"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: "Por favor introduce su DNI" },
              ]}
            >
              <Input
                name="contacto_numero"
                onChange={handleChange}
                value={data.contacto_numero}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateColumns: "315px 630px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Item
            label="Añadir especialidades"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              onChange={(e: any) =>
                handleChangeSelect("especialidades_array", e)
              }
              value={data.especialidades_nombre}
              placeholder="Elige una opción"
            >
              {especialidadesArray.map((item: any) => (
                <Option value={item.id}>{item.nombre}</Option>
              ))}
            </Select>
          </Form.Item>
          <Col span={24}>
            <TweenOneGroup
              enter={{
                scale: 0.8,
                opacity: 0,
                type: "from",
                duration: 100,
                onComplete: (e) => {
                  //e.target.style = '';
                },
              }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              appear={false}
            >
              {tagChild}
            </TweenOneGroup>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item
            label="Activo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "El campo es obligatorio" }]}
          >
            <Checkbox
              name="activo"
              onChange={handleChange}
              checked={data.activo}
            />
          </Form.Item>
        </Col>

        <Row>
          <Col span={24}>
            <Form.Item
              label="Observaciones"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                name="observaciones"
                onChange={handleChange}
                value={data.observaciones}
                placeholder="Escribir comentario..."
              />
            </Form.Item>
          </Col>
        </Row>
        <ButtonSection onCancel={onClose} />
      </Form>
    </Modal>
  );
}

export default AddClinicaModal;
