import api from '../../helpers/api'

const PrevisionesServices = {
    fetchAll: (
        filters: any
    )=>{
        return api.post('/admin/list/empleados',{
            filters
        })
    },
    save: (
        centro:any,
        empleado:any,
        especialidadesArray: any,
        nombre:any,
        apellidos:any,        
        telefono:any,        
        email:any,
        dni:any,
        activo:any,
        observaciones:any,
    )=>{
        return api.post('/admin/save/empleado',{
            centro,
            empleado,
            especialidadesArray,
            nombre,
            apellidos,
            telefono,
            email, 
            dni,
            activo,
            observaciones,
        })
    },
    edit: (
        id: string,
        Centro:any,
        empleado:any,
        especialidadesArray: any,
        nombre:any,
        apellidos:any,        
        telefono:any,        
        email:any,
        dni:any,
        activo:any,
        observaciones:any,
        )=>{
        return api.put('/admin/update/empleado/'+id,{
            Centro,
            empleado,
            especialidadesArray,
            nombre,
            apellidos,
            telefono,
            email,
            dni,
            activo,
            observaciones,
        })
    },
    delete: (
        id: any
    )=>{
        return api.get('/admin/delete/empleados/'+id)
    },
}

export default PrevisionesServices