const clinicasConstants = {
    SET_LOADING_EMPLEADOS: 'SET_LOADING_EMPLEADOS',
    SET_EMPLEADOS: 'SET_EMPLEADOS',
    SET_EDITING_EMPLEADOS: 'SET_EDITING_EMPLEADOS',
    SET_EDIT_ERROR: 'SET_EDIT_ERROR',
    SET_EDIT_OK: 'SET_EDIT_OK',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_SAVING_EMPLEADOS: 'SET_SAVING_EMPLEADOS'
  };
  
  export default clinicasConstants;
  