import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {Table} from 'antd'
import formatToEUR from '../../../../../../helpers/formatToEuro'
import formatToPercent from '../../../../../../helpers/formatToPercent'

import {
  getIndicadorAsesores,
} from '../../../../../../redux/global/actions';


interface Props {
    pageSize: any
    data: any
    loading: any
}

export default function ResumenAsesores(props: Props) {
    let {pageSize, loading, data} = props

    const columns = [
        {
          title: 'Clinica',
          dataIndex: 'clinica_nombre',
          Key:'clinica_nombre'
        },{
          title: 'Asesor',
          dataIndex: 'name',
          Key:'name'
        },{
          title: 'Cierre de citas',
          dataIndex: 'cierreCita',
          Key:'cierreCita',
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToPercent(record.cierreCita)
              }
            </>
          )
        },{
          title: 'Ratio de asistencia',
          dataIndex: 'ratioAsistencia',
          Key:'ratioAsistencia',
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToPercent(record.ratioAsistencia)
              }
            </>
          )
        },{
          title: 'Venta media   ',
          dataIndex: 'ventaMedia',
          Key:'ventaMedia',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.ventaMedia) - parseInt(b.ventaMedia),
            multiple: 3,
          },
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToEUR(record.ventaMedia)
              }
            </>
          )
        },{
          title: 'Fidelización',
          dataIndex: 'fidelizacion',
          Key:'fidelizacion'  
        },{
          title: 'Captación',
          dataIndex: 'captacion',
          Key:'captacion'
        },{
          title: 'ETTO Media',
          dataIndex: 'ettoMedia',
          Key:'ettoMedia'
        },{
          title: 'Descuento Medio',
          dataIndex: 'descuentoMedio',
          Key:'descuentoMedio',
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToPercent(record.descuentoMedio)
              }
            </>
          )
        },
      ];

  const filters = useSelector ( (state: any)=> state.Filters)
  const { date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  const dispatch = useDispatch()  

  useEffect( ()=>{
    dispatch(getIndicadorAsesores({...date, last: lastDates, filters}))
  },[date, lastDates, filters])

  useEffect( ()=>{
    dispatch(getIndicadorAsesores({...date, last: lastDates, filters}))
  },[])
  
    return (
        <Table
          columns={columns}
          scroll={{x: true}}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />
    )
}
