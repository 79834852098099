import PaisServices from "./services";

import paisConstants from "./constants";
import { fetchLists } from "../admins/actions";

const {
    SET_PAIS,
    SET_LOADING_PAIS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_PAIS
} = paisConstants

export const setLoadingPAIS = (isLoadingPais: boolean) => ({
    type: SET_LOADING_PAIS,
    isLoadingPais,
  });
  
  const setPais = (data: any) => ({
    type: SET_PAIS,
    data,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingPAIS(true));
      PaisServices.fetchAll().then((res) => {
        dispatch(setLoadingPAIS(false));
        return dispatch(setPais(res.data.response));
      });
    };
  };
  
  // const { SET_EDITING_PAIS, SET_EDIT_ERROR, SET_EDIT_OK } = paisConstants;
  // const setEditingPAIS = (isEditingPAIS: boolean) => ({
  //   type: SET_EDITING_PAIS,
  //   isEditingPAIS,
  // });
  
  // const setEditError = (editError: string | null) => ({
  //   type: SET_EDIT_ERROR,
  //   editError,
  // });
  
  // const setEditOk = (editOk: boolean) => ({
  //   type: SET_EDIT_OK,
  //   editOk,
  // });
  
  // export const editPAIS = (data: any) => {
  //   return (dispatch: any) => {
  //     dispatch(setEditingPAIS(true));
  //     dispatch(setEditError(null));
  //     PaisServices
  //       .destroy(
  //         data.id
  //       )
  //       .then((res: any) => {
  //         dispatch(setEditingPAIS(false));
  //         dispatch(setEditOk(true));
  //         dispatch(fetchAll())
  //         dispatch(fetchLists())
  //       })
  //       .catch((err) => {
  //         if (
  //           // Messy
  //           err.response &&
  //           err.response.status !== 500 &&
  //           err.response.data &&
  //           err.response.data.errors.message
  //         ) {
  //           dispatch(setEditError(err.response.data.errors.message));
  //         } else {
  //           dispatch(setEditError('Ha ocurrido un error'));
  //         }
  //         dispatch(setEditingPAIS(false));
  //       });
  //   };
  // };
  
  export const deleteCentro = (data: any) => {
    return (dispatch: any) =>{
      dispatch(setLoadingPAIS(true))
      PaisServices.destroy(data)
      .then(res => {
        let {message} = res.data
        dispatch(fetchAll())
        dispatch(setLoadingPAIS(false))
        // if(message === "deleted succesfully") fetchAll()
      })
      .catch(e => console.log(e))
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingPAIS = (saving: boolean | null)=>({
    type: SET_SAVING_PAIS,
    saving
  })

  export const savePAIS = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingPAIS(true))
      dispatch(savingPAIS(true))
      PaisServices.save(
        data.nombre,
        ).then(res =>{
            dispatch(fetchAll())
            dispatch(fetchLists())
            dispatch(setLoadingPAIS(false))
            dispatch(savingPAIS(false))
        })
      }catch(e){
        console.log(e)
      }
    }
  }