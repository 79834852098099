import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import FormEuro from './form_euro'

interface Props {
    handleChangeNameValue: any
    data: any
    inputName: string
}

function General(props: Props) {
    const {
        data,
        handleChangeNameValue,
        inputName
    } = props

    const [inputData, setInputData] = useState({
        presupuesto: 0,
        aceptado: 0,
        doctor: {
            id: '',
            nombre: ''
        }
    })
    /*
    useEffect(() => {
        setInputData(data)
    }, [data])*/

    const {doctorLists} = useSelector( (state:any)=>state.admins)
    const {Option} = Select

    const handleChange = (inputCamp:any, value:any) => {
        handleChangeNameValue(inputName, inputCamp, value)
    }

    return (
        <div className="wrapper">
            <Row style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '33% 33% 32.5%'}}>

                <FormEuro 
                    label='Presupuesto'
                    placeholder='Escribir cantidad'
                    value={data.presupuesto}
                    name='presupuesto'
                    handleChange={(value:any)=>handleChangeNameValue(inputName, 'presupuesto', value)}
                />

                <FormEuro 
                    label='Aceptado'
                    placeholder='Escribir cantidad'
                    value={data.aceptado}
                    name='aceptado'
                    handleChange={(value:any)=>handleChangeNameValue(inputName, 'aceptado', value)}
                    />

                <Col span={24}>
                    <Form.Item label='Doctor' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            value={data.empleado_id}
                            onChange={(value:any)=>handleChange('empleado_id', value)}
                            style={{width: '100%'}}>
                            {
                                doctorLists.map( (item:any, id:any)=>(
                                    <Option key={id} value={item.id}>{item.nombre} {item.apellidos}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default General
