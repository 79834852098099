import React from 'react'
import {Progress} from 'antd'

import ProgressArrow from '../assets/icon/progress_arrow.svg'
import formatToPercentage from '../helpers/formatToPercent'

interface Props {
    percent: any
    label: string
}

export default function ProgressBar(props: Props) {
    let {percent, label} = props

    return (
        <div style={{padding: '1em 0', position: 'relative'}}>
            <div  style={{display: 'grid', gridTemplateColumns: '60% 40%'}}>
                <span style={{fontSize: 18, fontWeight: 500, color: '#245046'}}>{label && label}</span>
                <span style={{alignSelf: 'flex-end', fontSize: 18, fontWeight: 500, color: '#245046', textAlign: 'right'}}>{percent && formatToPercentage(percent)}</span>
            </div>
            <Progress 
                percent={percent}
                showInfo={false}
                strokeColor={
                    percent && setProgressColor(percent)
                }
                strokeWidth={30}
                status='active'
                />
            <img style={{position: 'absolute',transition: '.3s', bottom: 0, left: `${percent > 100 ? 96 : percent-4}%`}} src={ProgressArrow}/>
        </div>
    )
}

const setProgressColor = (percent:any)=>{
    if(percent >= 99){
        return '#58D47B'
    }

    if(percent <= 98.9 && percent >= 90){
        return  '#FFD100'
    }

    if(percent < 89.9){
        return '#FF5A5A'
    }
}