import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    PageHeader,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    message,
    Space
} from 'antd';
import exportIcon from '../../../assets/icon/export.svg';
import PlusOutlinedIcon from '../../../../../assets/icon/plusOutlined.svg';

import CustomButton from '../../../../../components/custom-button';

import {
    fetchAll,
    // edit,
    setSelectedRowKeys,
    //deleteCentro,
    // saveCajaDia
} from '../../../../../redux/financiaciones/actions';

import formatToEUR from '../../../../../helpers/formatToEuro'
import { AnyMxRecord } from 'dns';

function Especialidades() {
  const headers = [
    { label: 'Fecha prevista', key: 'fecha_prevision' },
    { label: 'Cantidad prevista', key: 'cantidad' },
    { label: 'Asesor', key: 'asesor' },
    { label: 'Paciente', key: 'primer_doctor' },
    { label: 'Fecha de visita', key: 'fecha_visita' },
    { label: 'Fecha ETTO', key: 'fecha_etto' },
    { label: 'Tipo de presupuesto', key: 'tipo_presupuesto' },
    { label: 'Asistencia', key: 'asistencia' },
    { label: 'Total presupuestado', key: 'total_presupuestado' },
    { label: 'Total aceptado', key: 'total_aceptado' },
    { label: 'Comentario', key: 'comentario' },
  ];

  const columns = [
    {
        title: 'Financiera',
        dataIndex: 'fecha_prevision',
        Key:'fecha_prevision',
        width: '10vw'
    },{
        title: 'Financiado',
        dataIndex: 'cantidad_financiada',
        Key:'cantidad_financiada',
        width: '10vw',
    },{
        title: 'Interés',
        dataIndex: 'user.name',
        Key:'user.name',
        width: '10vw',
        ellipsis: true
    },{
        title: 'Plazos  ',
        dataIndex: 'asesor',
        Key:'asesor',
        width: '10vw'
    },{
        title: 'Paciente',
        dataIndex: 'nombre_apellidos',
        Key:'nombre_apellidos',
        width: '35vw',
        align: 'left' as 'left'
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      width: '6vw',
      render: (text: any, record: any) => (
        <>
          <DeleteOutlined
            style={{ fontSize: '20px', color: '#47464E' }}
            onClick={() =>showModalWatch(text)}
          />
        </>
      )
    }
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
      id: false,
          
      fecha_pago: false,
      cantidad: '',
      numero_pagos: '',
      observaciones: '',

      Centro: {
          id: '',
          clinica_nombre: ''
      },
      tiposPagos: {
          id: '',
          nombre: ''
      },
  });
  const [selectedUserName, setSelectedUserName] = useState('');


  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);


  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.nombre);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalAddVisible(false)
    setIdSelectedEdit(0);
    setUser({
      id: false,
        
      fecha_pago: false,
      cantidad: '',
      numero_pagos: '',
      observaciones: '',

      Centro: {
          id: '',
          clinica_nombre: ''
      },
      tiposPagos: {
          id: '',
          nombre: ''
      },
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleOk = (form:any) => {
    setSuccess('Usuario actualizado correctamente');
    // dispatch(edit(form));
    handleCancel()
  };
  
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit]);

  
  const { loading, financiacionesArray} = useSelector((state: any) => state.Financiaciones);
  
  const [data, setData] = useState(financiacionesArray || [{}]);
  
  useEffect(() => {
    setData(financiacionesArray);
  }, [financiacionesArray]); 

  
  const { Search } = Input;
  
  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      financiacionesArray.forEach((user: any) => {
        const { nombre } = user;
        const superString = `${nombre}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(financiacionesArray);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuracion / Financiación',
    },
  ];
  
  const { selectedRowKeys } = useSelector((state: any) => state.Previsiones);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any)=> {
    //   dispatch(saveCajaDia(form))
  }

  return (
    <div>
      <PageHeader title="FINANCIACIÓN" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">

        <Dropdown overlay={menu}>
          <Button style={{height: '100%'}}>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
          />
        </div>
        <div className="search">

        
          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={'financiacionesArray.csv'}
            separator={','}
            >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          </div>
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
          onRow={ (record, rowIndex)=>{
            return {
              onClick: event => {
                showModalEdit(record.id)
              }
            }
          }}
        />

        <Modal
          visible={isModalWatchVisible}
          onCancel={handleCancel}
          onOk={handleOk}>
            <h3>Esta seguro de que desea eliminar la especialidad?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Especialidades;