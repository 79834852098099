export const setEspecialidadesArray = (newData: any, especialidadesLists:any)=>{
    let array:any = []
        //if(!newData.EspecialidadesPresupuestosAceptados[0]){
            especialidadesLists.forEach((item:any)=>{
                if(item.nombre === 'Ortodoncia') array.push({
                    especialidad_id: item.id,
                    nombre: item.nombre, 
                    presupuesto: 0, 
                    aceptado: 0,
                    doctor: null,
                    implantes_aceptados: 0,
                    implantes_presupuestados: 0,
                    tipo_ortodoncia: null
                })

                if(item.nombre === 'Implantología') array.push({
                    especialidad_id: item.id,
                    nombre: item.nombre, 
                    presupuesto: 0, 
                    aceptado: 0 ,
                    doctor: null,
                    implantes_presupuestados: 0,
                    implantes_aceptados: 0
                })


                if(item.nombre !== 'Ortodoncia' && item.nombre !== 'Implantología') array.push({
                    especialidad_id: item.id,
                    nombre: item.nombre, 
                    presupuesto: 0, 
                    aceptado: 0 ,
                    doctor: null
                })
            })
        //}
    return array
}