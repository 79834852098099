import api from '../../helpers/api';

const authServices = {
  fetchAll: (
    filters: any
  ) => {
    return api.post('/admin/list/users', {filters});
  },

  fetchUserHose: (id: string) => {
    return api.get(`/admin/user_home/${id}`);
  },

  editUser: (
    id: any,
    username:any,
    role:any,
    clinica_data: any,
    clinica_name:any,
    name:any,
    lastname:any,
    telefono:any,
    dni:any,
    email:any,
    password:any,
    fecha_alta: any,
    fecha_baja: any,
    centros_id: any,
    observaciones: any,
    activo: boolean
  ) => {
    return api.put('/admin/edit/user/' + id, {
      username,
      role,
      clinica_data,
      clinica_name,
      name,
      lastname,
      telefono,
      dni,
      email,
      password,
      centros_id,
      fecha_alta,
      fecha_baja,
      observaciones,
      activo,
    });
  },

  deleteUser: (id: string)=>{
    return api.get('/admin/destroy/users/'+id)
  },

  saveUser: (
    username:any,
    role:any,
    clinica_data: any,
    clinica_name:any,
    name:any,
    lastname:any,
    telefono:any,
    dni:any,
    email:any,
    password:any,
    centros_id: any,
    fecha_alta: any,
    fecha_baja: any,
    observaciones: any,
    activo: boolean
  )=>{
    return api.post('admin/save/user',{
      username,
      role,
      clinica_data,
      clinica_name,
      name,
      lastname,
      telefono,
      dni,
      email,
      password,
      centros_id,
      fecha_alta,
      fecha_baja,
      observaciones,
      activo,
    })
  },

  getUser: (
    id:string
  ) =>{
    return api.get('/admin/users/'+id)
  }
};

export default authServices;
