import EspecialidadesServices from "./services";

import centrosConstants from "./constants";
import { fetchLists } from "../admins/actions";

const {
    SET_ESPECIALIDADES,
    SET_LOADING_ESPECIALIDADES,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_ESPECIALIDADES
} = centrosConstants

export const setLoadingEspecialidades = (isLoadingEspecialidades: boolean) => ({
    type: SET_LOADING_ESPECIALIDADES,
    isLoadingEspecialidades,
  });
  
  const setEspecialidades = (especialidades: any) => ({
    type: SET_ESPECIALIDADES,
    especialidades,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingEspecialidades(true));
      EspecialidadesServices.fetchAll().then((res) => {
        dispatch(setLoadingEspecialidades(false));
        return dispatch(setEspecialidades(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_ESPECIALIDADES, SET_EDIT_ERROR, SET_EDIT_OK } = centrosConstants;
  const setEditingEspecialidades = (isEditingEspecialidades: boolean) => ({
    type: SET_EDITING_ESPECIALIDADES,
    isEditingEspecialidades,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const editEspecialidades = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingEspecialidades(true));
      dispatch(setEditError(null));
      EspecialidadesServices
        .destroy(
          data.id
        )
        .then((res: any) => {
          dispatch(setEditingEspecialidades(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
          dispatch(fetchLists())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingEspecialidades(false));
        });
    };
  };
  
  export const deleteCentro = (data: any) => {
    return (dispatch: any) =>{
      console.log('=D')
      /*EspecialidadesServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") fetchAll()
      })
      .catch(e => console.log(e))*/
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingEspecialidades = (saving: boolean | null)=>({
    type: SET_SAVING_ESPECIALIDADES,
    saving
  })

  export const saveEspecialidades = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingEspecialidades)
      dispatch(savingEspecialidades(true))
      EspecialidadesServices.save(
        data.nombre,
        ).then(res =>{
            dispatch(fetchAll())
            dispatch(fetchLists())
        })
      }catch(e){
        console.log(e)
      }
    }
  }