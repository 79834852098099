import React, {useState, useEffect} from 'react'
import {Pie} from 'react-chartjs-2'

export default function PieChart(props: any) {
    let {data, label} = props
    
    const [labels, setLabels] = useState([])
    const [datas, setDatas] = useState([])

    useEffect(() => {
      if(props){

        let labelForGraphs:any = []
        let dataForGraphs:any = []

        data && data.map( (item: any)=>{
          let {nombre, percent, amount} = item
          labelForGraphs.push(`${nombre} (${amount})`)
          dataForGraphs.push(percent)
        })

        setLabels(labelForGraphs)
        setDatas(dataForGraphs)
      }
    }, [props]);

    const data2 = {
        labels: labels,
        datasets: [
          {
            data: datas,
            backgroundColor: [
              'rgba(85, 85, 85, 1)',
              'rgba(0, 8, 79, 1)'
            ]
          },
        ],
      };
    return (
        <div style={{position: 'relative'}}>
          <Pie width={300} height={300} data={data2} options={{maintainAspectRatio: false}}/>
        </div>
    )
}
