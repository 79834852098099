import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from './form'
import EditForm from './form/editForm'

import {fetchCentroRelatedUser, setUser, fetchUsers} from '../../../../redux/objetivos/actions'
import { fetchListsCajaDia } from '../../../../redux/admins/actions'

interface Props {
    isModalVisible: boolean
    onCancel: any
    onSubmit: any
    formData: any
}


function Index(props: Props) {
    const {isModalVisible, onCancel, onSubmit, formData} = props
    const dispatch = useDispatch()

    let {fetchedUser, isLoading} = useSelector( (state:any)=>state.Objetivos)
    let {centrosLists} = useSelector( (state:any)=>state.admins)

    const [data, setData] = useState({
        id: false,
        
        cantidad: '',
        fecha_inicio: null, 
        fecha_fin: null,
        observaciones: '',

        Centro: {
            id: '',
            clinica_nombre: ''
        },
        user: {
            id: '',
            name: ''
        }
    })

    useEffect(() => {
        if(formData){
            syncData(formData)
        }

        if(!formData){
            resetData()
            setEdit(false)
        }
    }, [formData])

    const [edit, setEdit] = useState(false)

    useEffect(() => {
        dispatch(fetchListsCajaDia())
        dispatch(fetchUsers())
    }, [])

    useEffect(() => {
        if(data.Centro.id !== '' && data.Centro.id){
            dispatch(fetchCentroRelatedUser(data.Centro.id))
        }

        if(data.Centro.id === ''){
                dispatch(setUser([]))
        }
    }, [data.Centro])
    
    const syncData = (newData: any)=>{
        setEdit(true)
        setData({
            ...newData,
            Centro: {
                id: newData.centro_id,
                clinica_nombre: newData.nombre_comercial,
                clinica_id: newData.clinica_id
            },
            user: {
                id: newData.user_id,
                name: newData.name
            }
        })
    }

    const handleChange=(event: any)=>{
        if(event.target){
            let {name, value} = event?.target
            setData({...data, [name]:value})
        }
    }

    const sortFromList = (name: any, value:any) =>{
        if(name === 'user'){
            let sortedValue = fetchedUser.filter( (index:any)=> index.id === value)
            handleChangeNameValue(name, sortedValue)
        }

        if(name === 'Centro'){
            let sortedValue:any 
            if(value !== ''){
                sortedValue = centrosLists.filter( (index:any)=> index.id === value)
            }
            if(value === ''){
                sortedValue = [{
                    id: '',
                    clinica_nombre: ''
                }]
            }
            handleChangeNameValue(name, sortedValue)
        }

        if( 
            name === 'cantidad' ||
            name === 'numero_pagos'
        ){
            handleChangeNameValue(name, value)
        }

        if(name === 'fecha_pago'){
            handleChangeNameValue(name, value.format())
        }
    }

    const handleChangeNameValue = (name:any, value:any)=>{
        if(
            name === 'cantidad' ||
            name === 'fecha_pago'||
            name === 'numero_pagos'
        ){ 
            setData({...data, [name]: value})
        }
        
        if(
            name === 'Centro' ||
            name === 'user' 
            ){
            setData({
                ...data,
                [name]: {...value[0]}
            })
        }
    }

    const handleChangeDate = (name: string, e:any)=>{
        if(e){
        let date = e.format()
        let newDate = date
        setData({...data, [name]:newDate})

        }else{
            setData({...data, [name]: false})
        }    
    }

    const handleSubmit = ()=>{
        onSubmit(data)
        handleClose()
    }

    const resetData = ()=>{
        setData({
            id: false,
        
            cantidad: '',
            fecha_inicio: null, 
            fecha_fin: null,
            observaciones: '',
    
            Centro: {
                id: '',
                clinica_nombre: ''
            },
            user: {
                id: '',
                name: ''
            }
        })
    }

    const handleClose = ()=>{
        resetData()
        onCancel()
    }

    if(isLoading) return (
        <div>
            loading
        </div>
    ) 

    if(!edit) return (
        <Form 
            isVisible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            
            data={data}
            users={fetchedUser}

            centrosLists={centrosLists}

            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleChangeNameValue={sortFromList}
        />
    )

    if(edit) return (
        <EditForm 
            isVisible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            
            data={data}
            users={fetchedUser}

            centrosLists={centrosLists}

            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleChangeNameValue={sortFromList}
        />
    )

    return (
        <div className="">
            
        </div>
    )
}

export default Index
