const usersConstants = {
  SET_LOADING_USERS: 'SET_LOADING_USERS',
  SET_USERS: 'SET_USERS',
  CHECKING_HOME: 'CHECKING_HOME',
  SET_HOME: 'SET_HOME',
  SET_EDITING_USER: 'SET_EDITING_USER',
  SET_EDIT_ERROR: 'SET_EDIT_ERROR',
  SET_EDIT_OK: 'SET_EDIT_OK',
  PAGE_SIZE: 10,
  SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
  SET_SAVING_ERROR: 'SET_SAVING_ERROR',
  SET_USER: 'SET_USER',
  SET_UPDATED: 'SET_UPDATED',
  SET_USER_ARRAY: 'SET_USER_ARRAY'
};

export default usersConstants;
