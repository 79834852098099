import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {Table} from 'antd'
import formatToEUR from '../../../../../../helpers/formatToEuro'

import {
  getResumenAsesores,
} from '../../../../../../redux/global/actions';


interface Props {
    pageSize: any
    data: any
    loading: any
}

export default function ResumenAsesores(props: Props) {
    let {pageSize, loading, data} = props

    const columns = [
        {
          title: 'Clinica',
          dataIndex: 'clinica_nombre',
          Key:'clinica_nombre'
        },{
          title: 'Asesor',
          dataIndex: 'name',
          Key:'name'
        },{
          title: 'Total presupuestos',
          dataIndex: 'totalPresupuestos',
          Key:'totalPresupuestos',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.totalPresupuestos) - parseInt(b.totalPresupuestos),
            multiple: 3,
          },
        },{
          title: 'Citas ETTO',
          dataIndex: 'citasEtto',
          Key:'citasEtto',
        },{
          title: 'Asistencia ETTO',
          dataIndex: 'asistenciaEtto',
          Key:'asistenciaEtto',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.asistenciaEtto) - parseInt(b.asistenciaEtto),
            multiple: 3,
          },
        },{
          title: 'Presupuestado',
          dataIndex: 'totalPresupuestado',
          Key:'totalPresupuestado',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.totalPresupuestado) - parseInt(b.totalPresupuestado),
            multiple: 3,
          },
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToEUR(record.totalPresupuestado)
              }
            </>
          )
        },{
          title: 'Aceptado',
          dataIndex: 'totalAceptado',
          Key:'totalAceptado',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.totalAceptado) - parseInt(b.totalAceptado),
            multiple: 3,
          },
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToEUR(record.totalAceptado)
              }
            </>
          )
        },{
          title: 'Presupuesto medio',
          dataIndex: 'presupuestoMedio',
          Key:'presupuestoMedio',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.presupuestoMedio) - parseInt(b.presupuestoMedio),
            multiple: 3,
          },
          render: (index: any, record: any)=>(
            <>
              {
                record && formatToEUR(record.presupuestoMedio)
              }
            </>
          )
        },{
          title: 'Caidas',
          dataIndex: 'caidas',
          Key:'caidas',
          sorter: {
            compare: (a: any, b: any) => parseInt(a.caidas) - parseInt(b.caidas),
            multiple: 3,
          },
        }
      ];

  const filters = useSelector ( (state: any)=> state.Filters)
  const {date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  const dispatch = useDispatch()
      

  useEffect( ()=>{
    dispatch(getResumenAsesores({...date, last: lastDates, filters}))
  },[date])

  useEffect( ()=>{
    dispatch(getResumenAsesores({...date, last: lastDates, filters}))
  },[])

    return (
        <Table
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          scroll={{x: true}}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />
    )
}
