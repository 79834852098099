import { stat } from "fs";
import entidadesConstants from "./constants";

const {
    SET_ENTIDADES,
    SET_EDIT_ERROR,
    SET_EDITING_ENTIDADES,
    SET_EDIT_OK,
    SET_LOADING_ENTIDADES,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_ENTIDADES
} = entidadesConstants

const initialState = {
    loading: false,
    entidades: [],
    editOk: false,
    selectedRowKeys: [],

    savingError: ''
}

const ENTIDADESReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING_ENTIDADES:
            return {
                ...state,
                loading: action.isLoadingEntidades
            }
        
        case SET_ENTIDADES:
            return{
                ...state,
                entidades: action.entidades
            }
        
        case SET_EDITING_ENTIDADES:
            return {
                ...state,
                isEditingEntidades: action.isEditingEntidades
            }
        
        case SET_EDIT_ERROR:
            return {
                ...state,
                editError: action.editError
            }
        
        case SET_EDIT_OK: 
            return {
                ...state,
                editOk: action.editOk,
            };

        case SET_SELECTED_ROW_KEYS: 
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            };

        case SET_SAVING_ENTIDADES:
            return {
                ...state
            }
    
    default:
        return state
    }
}

export default ENTIDADESReducer