import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Space
} from 'antd';
import exportIcon from '../../../assets/icon/export.svg';
import PlusOutlinedIcon from '../../../assets/icon/plusOutlined.svg';

import {
  fetchAll,
  editEspecialidades,
  setSelectedRowKeys,
  deleteCentro,
  saveEspecialidades
} from '../../../redux/especialidades/actions';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import CustomButton from '../../../components/custom-button';
import EspecialidadAddModal from './addEspecialidadModal'

function Especialidades() {
  const headers = [
    { label: 'Nombre', key: 'nombre' },
  ];

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      Key:'nombre'
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      render: (text: any, record: any) => (
        <>
          <DeleteOutlined
            style={{ fontSize: '20px', color: '#47464E' }}
            onClick={() =>showModalEdit(text)}
          />
        </>
      )
    }
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
      id: '',
      nombre: '',
  });
  const [selectedUserName, setSelectedUserName] = useState('');


  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);


  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.nombre);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIdSelectedEdit(0);
    setUser({
      id: '',
      nombre:'',
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleOk = () => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(editEspecialidades(user));
    handleCancel()
  };

  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit]);

  
  const { loading, especialidades} = useSelector((state: any) => state.especialidades);

  const [data, setData] = useState(especialidades || [{}]);

  useEffect(() => {
    setData(especialidades);
  }, [especialidades]); 

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      especialidades.forEach((user: any) => {
        const { nombre } = user;
        const superString = `${nombre}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(especialidades);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuracion / Especialidades',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.especialidades);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any)=> {
      dispatch(saveEspecialidades(form))
  }

  return (
    <div>
      <PageHeader title="Especialidades" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">

        <Dropdown overlay={menu}>
          <Button style={{height: '100%'}}>
            {pageSize} <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="Buscar..."
          onSearch={onSearch}
          style={{ width: 200 }}
          />
        </div>

        <div className="search">
          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={'especialidades.csv'}
            separator={','}
            >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          
          <CustomButton 
            style={{
              backgroundColor: '#245046',
              width: '97px',
                height: '39px',
                color: '#ffffff',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-around',
                cursor: 'pointer'
              }}
              onClick={()=>setIsModalAddVisible(true)}  
              >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>
              </div>
          </div>
            <EspecialidadAddModal 
                isVisible={isModalAddVisible}
                onSubmit={onsubmit}
                onClose={()=>setIsModalAddVisible(false)}
                />

      <div className="content_container">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />
                
        <Modal
          visible={isModalEditVisible}
          onCancel={handleCancel}
          title='Eliminar especialidades'
          onOk={handleOk}>
            <h3>¿Estás seguro que deseas eliminar "{user.nombre}”?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Especialidades;

