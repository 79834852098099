import globalServices from "./services";

import globalConstants from "./constants";
import store from "../rootStore";

const {
  SET_OBJETIVO_PREVISIONES,

  SET_RESUMEN_GLOBAL,
  SET_RESUMEN_ASESORES,
  SET_INDICADOR_ASESORES,
  SET_ESTADISTICA_ASESORES,

  SET_RESUMEN_DOCTORES,
  SET_TIPOLOGIA_DOCTORES,
  SET_DERIVACIONES_DOCTORES,
  SET_DERIVACIONES_EFECTIVAS_DOCTORES,
  SET_TOTALIZADOR_DOCTORES,
  SET_ESTADISTICA_DOCTORES,

  SET_RESUMEN_GLOBAL_LOADING,
  SET_DATE,
  SET_OLD_DATES,
  SET_ETTO_DATES,
  SET_NOTIFICATION,
} = globalConstants;

const setResumenGlobalLoading = (isLoading: boolean) => ({
  type: SET_RESUMEN_GLOBAL_LOADING,
  isLoading,
});

const setResumenGlobal = (data: any) => ({
  type: SET_RESUMEN_GLOBAL,
  data,
});

export const getResumen = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    dispatch(setDate({ startDate: date.starts, endDate: date.ends }));
    globalServices
      .fetchResumen(date.starts, date.ends, date.last, date.filters, date.etto)
      .then((res: any) => {
        dispatch(setResumenGlobalLoading(false));
        dispatch(setResumenGlobal(res.data));
      });
  };
};

const setDate = (date: any) => ({
  type: SET_DATE,
  date,
});

const setResumenAsesores = (data: any) => ({
  type: SET_RESUMEN_ASESORES,
  data,
});

export const getResumenAsesores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchResumenAsesores(date.starts, date.ends, date.last, date.filters, date.etto)
      .then((res: any) => {
        dispatch(setResumenAsesores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setIndicadorAsesores = (data: any) => ({
  type: SET_INDICADOR_ASESORES,
  data,
});

export const getIndicadorAsesores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchIndicadorAsesores(date.starts, date.ends, date.last, date.filters, date.etto)
      .then((res: any) => {
        dispatch(setIndicadorAsesores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setResumenDoctores = (data: any) => ({
  type: SET_RESUMEN_DOCTORES,
  data,
});

export const getResumenDoctores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchResumenDoctores(date.starts, date.ends, date.last, date.filters, date.etto)
      .then((res: any) => {
        dispatch(setResumenDoctores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setTipologiaDoctores = (data: any) => ({
  type: SET_TIPOLOGIA_DOCTORES,
  data,
});

export const getTipologiaDoctores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchTipologiaDoctores(date.starts, date.ends, date.last, date.filters, date.etto)
      .then((res: any) => {
        dispatch(setTipologiaDoctores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setDerivacionDoctores = (data: any) => {
  let { admins } = store.getState();
  return {
    type: SET_DERIVACIONES_DOCTORES,
    data: { data, especialidades: admins.especialidadesLists },
  };
};

export const getDerivacionDoctores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchDerivacionesDoctores(
        date.starts,
        date.ends,
        date.last,
        date.filters, date.etto
      )
      .then((res: any) => {
        dispatch(setDerivacionDoctores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setDerivacionesEfectivasDoctores = (data: any) => {
  let { admins } = store.getState();
  return {
    type: SET_DERIVACIONES_EFECTIVAS_DOCTORES,
    data: { data, especialidades: admins.especialidadesLists },
  };
};

export const getDerivacionesEfectivasDoctores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchDerivacionesEfectivasDoctores(
        date.starts,
        date.ends,
        date.last,
        date.filters, date.etto
      )
      .then((res: any) => {
        dispatch(setDerivacionesEfectivasDoctores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setTotalizadorDoctores = (data: any) => ({
  type: SET_TOTALIZADOR_DOCTORES,
  data,
});

export const getTotalizadorDoctores = (date: any) => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    globalServices
      .fetchTotalizadorDoctores(date.starts, date.ends, date.last, date.filters, date.etto)
      .then((res: any) => {
        dispatch(setTotalizadorDoctores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setEstadisticaDoctores = (data: any) => ({
  type: SET_ESTADISTICA_DOCTORES,
  data,
});

export const getEstadisticasDoctores = (date: any) => {
  return (dispatch: any) => {
    globalServices
      .fetchEstadisticasDoctores(
        date.starts,
        date.ends,
        date.last,
        date.filters, date.etto
      )
      .then((res: any) => {
        dispatch(setEstadisticaDoctores(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setObjetivoPrevisiones = (data: any) => ({
  type: SET_OBJETIVO_PREVISIONES,
  data,
});

export const getObjetivoPrevision = () => {
  return (dispatch: any) => {
    dispatch(setResumenGlobalLoading(true));
    let { Filters, ResumenGlobal } = store.getState();
    let date: any = ResumenGlobal;
    globalServices
      .fetchObjetivoPrevision(
        date.date.starts,
        date.date.ends,
        date.lastDates,
        Filters, date.etto
      )
      .then((res: any) => {
        dispatch(setObjetivoPrevisiones(res.data));
        dispatch(setResumenGlobalLoading(false));
      });
  };
};

const setLastDates = (data: boolean) => ({
  type: SET_OLD_DATES,
  data,
});


export const setUseOldDates = (data: boolean) => {
  return (dispatch: any) => {
    dispatch(setLastDates(data));
  };
};

const setEttoDates = (data: boolean) => ({
  type: SET_ETTO_DATES,
  data,
});
export const setUseEttoDates = (data: boolean) => {
  return (dispatch: any) => {
    dispatch(setEttoDates(data));
  };
};


const setEstadisticaAsesores = (data: any) => ({
  type: SET_ESTADISTICA_ASESORES,
  data,
});

export const getEstadisticasAsesores = () => {
  return (dispatch: any) => {
    let { Filters, ResumenGlobal } = store.getState();
    let anyHandler: any = ResumenGlobal;
    globalServices
      .fetchEstadisticasAsesores(
        anyHandler.date.starts,
        anyHandler.date.ends,
        anyHandler.lastDates,
        Filters, anyHandler.etto
      )
      .then((res: any) => {
        dispatch(setEstadisticaAsesores(res.data.data));
      });
  };
};

export const setNotification = (number: number) => ({
  type: SET_NOTIFICATION,
  number,
});

export const getNotifications = () => {
  return (dispatch: any) => {
    let { Filters, ResumenGlobal } = store.getState();
    let anyHandler: any = ResumenGlobal;
    globalServices
      .getNotifications(anyHandler.date, Filters)
      .then((res: any) => {
        dispatch(setNotification(res.data.response));
      });
  };
};

