const partePresupuestosConstants = {
    SET_LOADING_FINANCIACIONES: 'SET_LOADING_FINANCIACIONES',
    SET_FINANCIACIONES: 'SET_FINANCIACIONES',
    PAGE_SIZE: 10,
    SET_SELECTED_ROW_KEYS: 'SET_SELECTED_ROW_KEYS',
    SET_LOADING_PROFILE: 'SET_LOADING_PROFILE',
    SET_PROFILE: 'SET_PROFILE'
  };
  
  export default partePresupuestosConstants;
  