import { combineReducers } from 'redux';

import auth from './auth/reducer';
import admins from './admins/reducer';
import users from './users/reducer';

import Pais from './pais/reducer'
import Empleados from './empleados/reducer'
import clinicas from './clinicas/reducer'
import centros from './centros/reducer'
import especialidades from './especialidades/reducer';
import entidades from './entidades_financieras/reducer'
import tiposEmpleados from './tipos_empleados/reducer'
import tiposPagos from './tipos_pagos/reducer'
import tiposPresupuestos from './tipos_presupuestos/reducer';
import tiposOrtodoncias from './tipos_ortodoncias/reducer';
import PartePresupuesto from './parte_presupuestos/reducer'

import Previsiones from './previsiones/reducer'
import CajaDia from './caja_dia/reducer'
import Objetivos from './objetivos/reducer'
import Financiaciones from './financiaciones/reducer'

import ResumenGlobal from './global/reducer'

import Filters from './filters/reducer'
import ParteNoAceptados from './partes_no_aceptados/reducer'
import Acumulados from './acumulado/reducer'

// Use ES6 object literal shorthand syntax to define the object shape
const rootReducer = combineReducers({
  auth,
  admins,
  users,

  Pais,
  Empleados,
  clinicas,
  centros,
  entidades,
  especialidades,
  tiposEmpleados,
  tiposPagos,
  tiposPresupuestos,
  tiposOrtodoncias,
  PartePresupuesto,
  Previsiones,
  CajaDia,

  Objetivos,
  Financiaciones,
  ResumenGlobal,

  Filters,
  ParteNoAceptados,
  Acumulados
});

export default rootReducer;
