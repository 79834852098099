import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Dropdown,
  Menu,
  message,
  Checkbox,
  Space
} from 'antd';
import PlusOutlinedIcon from '../../../../assets/icon/plusOutlined.svg'

import {
  fetchAll,
  edit,
  setSelectedRowKeys,
  deleteEmpleado,
  save
} from '../../../../redux/empleados/actions';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import CustomButton from '../../../../components/custom-button';
import EntidadesModal from './components/'

function EntidadesRouter() {
  const headers = [
    { label: 'Nombre', key: 'empleado_nombre' },
    { label: 'Apellidos', key: 'apellidos' },
    { label: 'DNI', key: 'dni'},
    { label: 'telefono', key: 'telefono' },
    { label: 'Email', key: 'email' },
    { label: 'Tipo empleado', key: 'nombre' },
    { label: 'Persona de contacto', key: 'contacto_nombre' },
    { label: 'Centro', key: 'clinica_nombre' },
  ];
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'empleado_nombre',
      Key:'empleado_nombre',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Apellidos',
      dataIndex: 'apellidos',
      Key:'apellidos',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'DNI',
      dataIndex: 'dni',
      Key:'dni',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Telefono',
      dataIndex: 'telefono',
      Key:'telefono',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Email',
      dataIndex: 'email',
      Key:'email',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Tipo Empleado',
      dataIndex: 'nombre',
      Key:'nombre',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Especialidad',
      dataIndex: 'contacto_nombre',
      Key:'contacto_nombre',
      render: (text: any, record: any) => {
        let data = setEspecialidades(record)

        const obj = {
          children: data,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Centro',
      dataIndex: 'clinica_nombre',
      Key:'clinica_nombre'
    },{
      title: 'Activo',
      dataIndex: 'id',
      key: 'activo',
      render: (text: any, record: any) => {
        const obj = {
          children: <Checkbox checked={record.activo} />,
          props: {
            onClick: ()=>showModalEdit(record.main_id)
          }
        }
        return obj
      }
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      render: (text: any, record: any) => {
        const obj = {
          children: <DeleteOutlined
          style={{ fontSize: '20px', color: '#47464E'}}
        />,
          props: {
            onClick: ()=>showModalDelete(record.main_id)
          }
        }
        return obj
      }
    }
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [idSelectedDelete, setIdSelectedDelete]:any = useState()
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
      id: '',
      nombre: '',
      main_id: '',
      empleado_nombre: ''
  });
  const [selectedUserName, setSelectedUserName] = useState('');


  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.main_id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);

  useEffect(() => {
    if (idSelectedDelete) {
      const userr: any = data.find((e: any) => e.main_id === idSelectedDelete);
      setUser(userr);
    }
  }, [idSelectedDelete]);

  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.nombre);

    setIsModalWatchVisible(true);
  }
  
  const showModalDelete = (text: any) => {
    setIdSelectedDelete(text)
    setIsModalDeleteVisible(true)
  }

  const handleOkDelete = async () => {
    setIsModalDeleteVisible(false)
    await dispatch(deleteEmpleado(idSelectedDelete))
    setIdSelectedDelete('')
  }

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalDeleteVisible(false);
    setIdSelectedEdit(0);
    setIdSelectedDelete(0)
    setUser({
      id: '',
      nombre: '',
      main_id: '',
      empleado_nombre: ''
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleOk = (form: any) => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(edit({id:user.main_id, ...form}));
    handleCancel()
  };

  
  const dispatch = useDispatch();
  const filterState = useSelector((state:any) => state.Filters);
  const {date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit, filterState, date, lastDates]);

  
  const { loading, empleados, editError, editOk} = useSelector((state: any) => state.Empleados);

  const [data, setData] = useState(empleados || [{}]);

  useEffect(() => {
    setData(empleados);
  }, [empleados]); 

  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      empleados.forEach((user: any) => {
        const { empleado_nombre, apellidos, dni, numero_telefono, email, nombre } = user;
        const superString = `${empleado_nombre}, ${apellidos}, ${dni}, ${numero_telefono}, ${email}, ${nombre},`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(empleados);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Configuracion / Empleados',
    },
  ];

  const { selectedRowKeys } = useSelector((state: any) => state.Empleados);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any)=> {
      dispatch(save(form))
  }

  const handleSubmit = (form: any) =>{
      dispatch(edit(form))
  }


  useEffect(() => {
    if(editError === 'error'){
        message.warning('Ha ocurrido un error', 2)
    }
    if(editOk){
        message.success('Usuario almacenado correctamente', 2)
    }
    if(editError === 'form'){
        message.warning('Hubo un error con los datos suministrados')
    }
    if(editError === 'exist'){
        message.warning('El usuario ya se encuentra registrado', 2)
    }
}, [editError, editOk]);
  
  return (
    <div>
      <PageHeader title="Empleados" ghost={false} breadcrumb={{ routes }} />

      <div className="top_content_container">
        <div className="search">
          <Dropdown overlay={menu}>
            <Button>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
            />
        </div>
        <div className="search">

          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={'empleados.csv'}
            separator={','}
            >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          
          <CustomButton 
            style={{
              backgroundColor: '#245046',
              width: '97px',
              height: '39px',
              color: '#ffffff',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer'
            }}
            onClick={()=>setIsModalAddVisible(true)}  
            >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>
          </div>
            <EntidadesModal 
              formData={false}
              isModalVisible={isModalAddVisible}
              onSubmit={onsubmit}
              onCancel={()=>setIsModalAddVisible(false)}
              />

            <EntidadesModal 
              formData={user}
              isModalVisible={isModalEditVisible}
              onSubmit={handleOk}
              onCancel={handleCancel}
              />
      </div>

      <div className="content_container">
        <Table
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
          scroll={{x: true}}
        />
                
        <Modal
          visible={isModalDeleteVisible}
          onCancel={handleCancel}
          onOk={handleOkDelete}
          title='Eliminar empleado'
          >
            <h3>¿Estás seguro que deseas eliminar "{user.empleado_nombre}”?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default EntidadesRouter;

const setEspecialidades = (record: any)=>{
  if(record.especialidad) {
    if(record.especialidad.length === 1){
      return (
        <>
          {
            record.especialidad[0].nombre
          }
        </>
      )
    } 

    if(record.especialidad.length > 1){

      let length = record.especialidad.length - 1
      return (
        <>
          {
            record.especialidad.map( (item:any, id:any)=>{
              if(id === length){
                return (
                  <>
                    {item.nombre}
                  </>
                  )
              }

              if(id !== length){
                return (
                <>
                  {item.nombre+', '}
                </>
                )
              }
            })
          }
        </>
      )
    }
    if(record.especialidad.length === 0){
      return (
        <>
          -
        </>
      )
    }
  } 
}