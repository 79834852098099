import React,{forwardRef } from 'react'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

interface Props {
    handleChange: any
    value: any
    label: any
    placeholder: any
    name: any
}

const Form_euro = forwardRef((props: Props, ref) => {
    const {
        handleChange,
        label,
        value,
        placeholder,
        name
    } = props

    return (
        <Col span={24} style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '90% 5%'}}>
            <Form.Item label={label} labelCol={{span: 24}} wrapperCol={{ span: 24}} className="form-wrapper">
                <InputNumber
                    onChange={handleChange}
                    name={name}
                    style={{width: '100%'}}
                    value={value}
                    placeholder={placeholder}
                    min={0}
                    ref={ref}/>

                <span className="form-span">
                    €
                </span>
            </Form.Item>
        </Col>
    )
});

export default Form_euro
