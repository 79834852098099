import { stat } from "fs";
import clinicasConstants from "./constants";

const {
    SET_PPTOS_TIPOLOGIA,
    SET_LOADING,
    SET_PPTOS_TIPOS_PAGOS,
    SET_COBROS,
    SET_ESPECIALIDAD_TABLE,
    SET_SELECTED
} = clinicasConstants

const initialState = {
    loading: false,
    pptosTipologiaData: false,
    pptosTipoPagosData: false,
    cobrosData: false,
    especialidadDataTable: false,
    editOk: false,
    selected: [],
    selectedRowKeys: []
}

const CajaDiaReducer = (state = initialState, action: any) =>{
    switch(action.type){
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            }

        case SET_SELECTED:
            return {
                ...state,
                selected: action.data
            }
        
        case SET_PPTOS_TIPOLOGIA:
            return{
                ...state,
                pptosTipologiaData: action.data,
                selectedRowKeys: action.data,
                selected: action.data
            }
    
        case SET_PPTOS_TIPOS_PAGOS:
            return{
                ...state,
                pptosTipoPagosData: action.data,
                selectedRowKeys: action.data,
                selected: action.data

            }

        case SET_COBROS:
            return{
                ...state,
                cobrosData: action.data,
                selectedRowKeys: action.data,
                selected: action.data
            }

        case SET_ESPECIALIDAD_TABLE:
            return {
                ...state,
                especialidadDataTable: action.data,
                selectedRowKeys: action.data,
                selected: action.data
            }
    
    default:
        return state
    }
}

export default CajaDiaReducer