import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import { DownloadOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    PageHeader,
    Table,
    Modal,
    Input,
    Button,
    Typography,
    Dropdown,
    Menu,
    Space
} from 'antd';
import PlusOutlinedIcon from '../../../assets/icon/plusOutlined.svg';

import CumplimentacionModal from './components'
import CustomButton from '../../../components/custom-button';

import {
    fetchAll,
    edit,
    setSelectedRowKeys,
    deleteObj,
    saveObjetivos
} from '../../../redux/objetivos/actions';

import formatToEUR from '../../../helpers/formatToEuro'
import YearTable from './components/yearTable';

function Especialidades() {
  const headers = [
    { label: 'Objetivo', key: 'cantidad' },
    { label: 'Asesor', key: 'name' },
    { label: 'Clinica', key: 'nombre_comercial' },
    { label: 'Fecha inicio', key: 'fecha_inicio' },
    { label: 'Fecha fin', key: 'fecha_fin' },
    { label: 'Observaciones', key: 'observaciones' },
  ];

  const columns = [
    {
        title: 'Objetivo',
        dataIndex: 'cantidad',
        Key:'cantidad',
        width: '8vw',
        sorter: {
          compare: (a: any, b: any) => parseInt(a.cantidad) - parseInt(b.cantidad),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: text,
            props: {
              onClick: ()=>showModalEdit(record.id)
            }
          }
          return obj
        }
    },{
      title: 'Asesor',
      dataIndex: 'name',
      Key:'name',
      width: '8vw',
      render: (text: any, record: any) => {
        const obj = {
          children: text,
          props: {
            onClick: ()=>showModalEdit(record.id)
          }
        }
        return obj
      }
  },{
    title: 'Clínica',
    dataIndex: 'nombre_comercial',
    Key:'nombre_comercial',
    width: '20vw',
    render: (text: any, record: any) => {
      const obj = {
        children: text,
        props: {
          onClick: ()=>showModalEdit(record.id)
        }
      }
      return obj
    }
  },{
        title: 'Fecha inicio',
        dataIndex: 'fecha_inicio',
        Key:'fecha_inicio',
        width: '8vw',
        style: 'background-color: red;',
        sorter: {
          compare: (a: any, b: any) => moment(a.fecha_inicio).unix() - moment(b.fecha_inicio).unix(),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: moment(text).format('DD/MM/YYYY'),
            props: {
              onClick: ()=>showModalEdit(record.id)
            }
          }
          return obj
        }
    },{
        title: 'Fecha fin',
        dataIndex: 'fecha_fin',
        Key:'fecha_fin',
        width: '8vw',
        ellipsis: true,
        sorter: {
          compare: (a: any, b: any) => moment(a.fecha_fin).unix() - moment(b.fecha_fin).unix(),
          multiple: 3,
        },
        render: (text: any, record: any) => {
          const obj = {
            children: moment(text).format('DD/MM/YYYY'),
            props: {
              onClick: ()=>showModalEdit(record.id)
            }
          }
          return obj
        }
    },{
        title: 'Observaciones',
        dataIndex: 'observaciones',
        Key:'observaciones',
        width: '20vw',
        render: (text: any, record: any) => {
          const obj = {
            children: text  ? text : '-',
            props: {
              onClick: ()=>showModalEdit(record.id)
            }
          }
          return obj
        }
    },{
      title: 'Eliminar',
      dataIndex: 'id',
      key: 'eliminar',
      width: '6vw',
      render: (text: any, record: any) => {
        const obj = {
          children: <DeleteOutlined
          style={{ fontSize: '20px', color: '#47464E'}}
        />,
          props: {
            onClick: ()=>showModalWatch(text)
          }
        }
        return obj
      }
    }
  ];

  const [isModalAddVisible, setIsModalAddVisible] = useState(false)
  const [isModalWatchVisible, setIsModalWatchVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [idSelectedEdit, setIdSelectedEdit] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [user, setUser] = useState({
      id: false,
          
      cantidad: '',
      fecha_inicio: null, 
      fecha_fin: null,
      observaciones: '',

      Centro: {
          id: '',
          clinica_nombre: ''
      },
      user: {
          id: '',
          name: ''
      }
  });
  const [selectedUserName, setSelectedUserName] = useState('');

  useEffect(() => {
    if (idSelectedEdit) {
      const userr: any = data.find((e: any) => e.id === idSelectedEdit);
      setUser(userr);
    }
  }, [idSelectedEdit]);


  const showModalEdit = (text: any) => {
    setIdSelectedEdit(text);
    setIsModalEditVisible(true);
  };

  const showModalWatch = (text: any) => {
    setIdSelected(text);

    // Find user with same id and set to get the name
    const user: any = data.find((e: any) => e.id === text);
    setSelectedUserName(user.nombre);

    setIsModalWatchVisible(true);
  };

  const handleCancel = () => {
    setIsModalEditVisible(false);
    setIsModalAddVisible(false)
    setIsModalWatchVisible(false)
    setIdSelectedEdit(0);
    setUser({
      id: false,
        
      cantidad: '',
      fecha_inicio: null, 
      fecha_fin: null,
      observaciones: '',

      Centro: {
          id: '',
          clinica_nombre: ''
      },
      user: {
          id: '',
          name: ''
      }
    });
    setSuccess('');
  };
  const [success, setSuccess] = useState('');
  const { Text } = Typography;

  const handleOk = (form:any) => {
    setSuccess('Usuario actualizado correctamente');
    dispatch(edit(form));
    handleCancel()
  };

  const dispatch = useDispatch();
  const filterState = useSelector((state:any) => state.Filters);
  const {date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  
  
  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch, idSelectedEdit, filterState, date, lastDates]);
  
  
  const {loading, objetivos} = useSelector((state: any) => state.Objetivos);
  
  const [data, setData] = useState(objetivos || [{}]);
  
  useEffect(() => {
    const filteredObjetivos = objetivos ? objetivos?.filter((d: any) => {
      if (date.starts && date.ends) {
        return moment(d.fecha_inicio) >= date.starts && moment(d.fecha_fin) <= date.ends;
      }
      return d;
    }) : objetivos;
    setData(filteredObjetivos);
  }, [objetivos]); 
  
  const { Search } = Input;

  const onSearch = (value: any) => {
    const results: any = [];
    if (value) {
      objetivos.forEach((user: any) => {
        const { name, cantidad,  nombre_comercial, observaciones, fecha_inicio, fecha_fin} = user;
        const superString = `${name}, ${cantidad}, ${nombre_comercial}, ${observaciones}, ${fecha_fin}, ${fecha_inicio}`;
        if (superString.includes(value)) {
          results.push(user);
        }
      });
      return setData(results);
    }
    if (value.trim() === '') {
      return setData(objetivos);
    }
  };

  function handleMenuClick(e: any) {
    setPageSize(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Objetivos',
    },
  ];
  
  const { selectedRowKeys } = useSelector((state: any) => state.Previsiones);
  const [selectedToExport, setSelectedToExport] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));

    const results: any = [];
    if (selectedRowKeys) {
      selectedRowKeys.map((k: any) => {
        data.map((d: any) => {
          if (d.key === k) {
            results.push(d);
          }
        });
      });
      return setSelectedToExport(results);
    }
    if (selectedRowKeys.length === 0) {
      return setSelectedToExport([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onsubmit = (form: any)=> {
      dispatch(saveObjetivos(form))
      handleCancel()
  }

  const handleOkDelete = ()=>{
    dispatch(deleteObj(idSelected))
    handleCancel()
  }

  return (
    <div>
      <PageHeader title="OBJETIVOS" ghost={false} breadcrumb={{ routes }} />

      <YearTable />

      <div className="top_content_container" >
        <div className="search">
          <Dropdown overlay={menu}>
            <Button style={{height: '100%'}}>
              {pageSize} <DownOutlined />
            </Button>
          </Dropdown>
          <Search
            placeholder="Buscar..."
            onSearch={onSearch}
            style={{ width: 200 }}
            />
        </div>
        <div className="search">

          <CSVLink
            style={{
              color: '#245046',
              width: '113px',
              height: '39px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent:'space-around',
              cursor: 'pointer',
              border: '1px solid #245046',
              backgroundColor: '#FFFFFF'
            }}
            data={data ? data : selectedRowKeys}
            headers={headers}
            filename={'objetivos.csv'}
            separator={','}
            >
            <DownloadOutlined />
            Descargar
          </CSVLink>
          
          <CustomButton 
            style={{
              backgroundColor: '#245046',
              width: '97px',
                height: '39px',
                color: '#ffffff',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-around',
                cursor: 'pointer'
              }}
              onClick={()=>setIsModalAddVisible(true)}  
            >  
            <img src={PlusOutlinedIcon} alt="" style={{ margin: '5px' }} />
            Añadir
          </CustomButton>
          </div>

          <CumplimentacionModal
              isModalVisible={isModalAddVisible}
              onCancel={handleCancel}
              formData={false}
              onSubmit={onsubmit}
            />

          <CumplimentacionModal
              formData={user}
              isModalVisible={isModalEditVisible}
              onCancel={handleCancel}
              onSubmit={handleOk}
            />
      </div>

      <div className="content_container">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize }}
          loading={loading}
        />

        <Modal
          visible={isModalWatchVisible}
          onCancel={handleCancel}
          onOk={handleOkDelete}
          title='Eliminar objetivo'
          >
            <h3>¿Estás seguro que deseas eliminar el registro seleccionado?</h3>
        </Modal>
      </div>
    </div>
  );
}

export default Especialidades;