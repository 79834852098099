import React from 'react'

import GraphSelector from './graphs/'

import styles from './styles.module.scss'
interface Props {
    data: any
}

export default function GraphsWrapper(props: Props) {
    let {data} = props

    return (
        <div 
            className={styles.wrapper_porcentage}
        >
            <GraphSelector 
                label='PRESUPUESTOS POR TIPOLOGÍA'
                data={data && data.PresupuestosPorTipologia}
                circular={false}
            />
            
            <GraphSelector 
                label='ACEPTACIÓN POR TIPOLOGÍA'
                data={data && data.AceptacionPorTipologia}
                circular={false}
            />
            
            <GraphSelector 
                label='PRESUPUESTOS MEDIO POR TIPOLOGÍA'
                data={data && data.MediaPorTipologia}
                circular={false}
            />

            <GraphSelector 
                label='VENTA MEDIA POR TIPOLOGÍA'
                data={data && data.VentaMediaPorTipologia}
                circular={false}
            />

            <GraphSelector 
                label='PRESUPUESTOS CAPTACIÓN Y FIDELIZACÍÓN'
                data={data && data.PresupuestosCaptacionFid}
                circular={true}
            />

            <GraphSelector 
                label='MIX DE VENTA POR ESPECIALIDAD'
                data={data && data.MixVentaEspecialidad}
                circular={false}
            />

            <GraphSelector 
                label='ACEPTACIÓN POR ESPECIALIDAD'
                data={data && data.AceptacionEspecialidad}
                circular={false}
            />

            <GraphSelector 
                label='REFERIDOS POR ESPECIALIDAD'
                data={data && data.ReferidosEspecialidad}
                circular={false}
            />

        </div>
    )
}
