import CentrosServices from "./services";

import centrosConstants from "./constants";
import { fetchLists } from "../admins/actions";

const {
  SET_CENTROS,
  SET_LOADING_CENTROS,
  SET_SELECTED_ROW_KEYS,
  SET_SAVING_CENTROS,
  SET_CENTRO_PROFILE,
  SET_SAVING_ERROR,
} = centrosConstants;

export const setLoadingCentros = (isLoadingClinicas: boolean) => ({
  type: SET_LOADING_CENTROS,
  isLoadingClinicas,
});

const setCentros = (centros: any) => ({
  type: SET_CENTROS,
  centros,
});

export const fetchAll = () => {
  return (dispatch: any) => {
    dispatch(setLoadingCentros(true));
    CentrosServices.fetchAll().then((res) => {
      dispatch(setLoadingCentros(false));
      return dispatch(setCentros(res.data.response));
    });
  };
};

const { SET_EDITING_CENTROS, SET_EDIT_ERROR, SET_EDIT_OK } = centrosConstants;
const setEditingCentro = (isEditingClinicas: boolean) => ({
  type: SET_EDITING_CENTROS,
  isEditingClinicas,
});

export const setEditError = (editError: string | null) => ({
  type: SET_EDIT_ERROR,
  editError,
});

export const setEditOk = (editOk: boolean) => ({
  type: SET_EDIT_OK,
  editOk,
});

export const editCentro = (data: any) => {
  return (dispatch: any) => {
    dispatch(setEditingCentro(true));
    dispatch(setEditError(null));
    CentrosServices.edit(
      data.id,
      data.clinica_nombre,
      data.clinica_id,

      data.direccion,
      data.codigo_postal,

      data.pais,
      data.poblacion,

      data.consultor,
      data.numero_telefono,

      data.contacto_nombre,
      data.contacto_numero,

      data.email,
      data.especialidades_data,

      data.observaciones,

      data.activo
    )
      .then((res: any) => {
        dispatch(setEditingCentro(false));
        dispatch(setEditOk(true));
        dispatch(fetchAll());
        dispatch(fetchLists());
        setTimeout(() => dispatch(setEditOk(false)), 200);
      })
      .catch((err) => {
        if (
          // Messy
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setEditError(err.response.data.errors.message));
          setTimeout(() => dispatch(setEditError(null)), 200);
        } else {
          dispatch(setEditError("Ha ocurrido un error"));
          setTimeout(() => dispatch(setEditError(null)), 200);
        }
        dispatch(setEditingCentro(false));
      });
  };
};

export const deleteCentro = (data: any) => {
  return (dispatch: any) => {
    CentrosServices.delete(data)
      .then((res) => {
        let { message } = res.data;

        if (message === "deleted succesfully") dispatch(fetchAll());
      })
      .catch((e) => console.log(e));
  };
};

const selecteddRowKeys = (selectedRowKeys: any) => ({
  type: SET_SELECTED_ROW_KEYS,
  selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
  return (dispatch: any) => {
    return dispatch(selecteddRowKeys(selectedRowKeys));
  };
};

const savingCentros = (saving: boolean | null) => ({
  type: SET_SAVING_CENTROS,
  saving,
});

export const saveCentro = (data: any) => {
  return (dispatch: any) => {
    try {
      dispatch(setLoadingCentros);
      dispatch(savingCentros(true));
      CentrosServices.save(
        data.clinica_nombre,
        data.clinica_id,

        data.direccion,
        data.codigo_postal,

        data.pais,
        data.poblacion,

        data.consultor,
        data.numero_telefono,

        data.contacto_nombre,
        data.contacto_numero,

        data.email,
        data.especialidades_data,

        data.observaciones
      )
        .then((res) => {
          dispatch(fetchLists());
          dispatch(fetchAll());
          dispatch(savingError("saved"));
          setTimeout(() => dispatch(savingError(null)), 200);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            dispatch(savingError("exist"));
            setTimeout(() => dispatch(savingError(null)), 200);
          } else if (e.response.status === 422) {
            dispatch(savingError("form"));
            setTimeout(() => dispatch(savingError(null)), 200);
          } else {
            dispatch(savingError("error"));
            setTimeout(() => dispatch(savingError(null)), 200);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

const savingError = (savingError: any) => ({
  type: SET_SAVING_ERROR,
  savingError,
});

const setCentroProfile = (data: any) => ({
  type: SET_CENTRO_PROFILE,
  data,
});

export const getCentro = (id: any) => {
  return (dispatch: any) => {
    if (id) {
      CentrosServices.getProfile(id)
        .then((res) => {
          dispatch(setCentroProfile(res.data));
        })
        .catch((e: any) => console.log(e));
    }
  };
};
