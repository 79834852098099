import api from '../../helpers/api'

const ClinicasServices = {
    fetchAll: ()=>{
        return api.get('/admin/list/clinica')
    },
    save: (
        cif: string,
        razon_social: string,
        nombre_comercial: string,
        numero_telefono: string, 
        email: string, 
        direccion: string, 
        codigo_postal: string, 
        pais: string, 
        poblacion: string, 
        contacto_nombre: string, 
        contacto_numero: string,
        fecha_alta: string,    
        fecha_baja: string,
        observaciones: string,
    )=>{
        return api.post('/admin/save/clinica',{
            cif,
            razon_social,
            nombre_comercial,
            numero_telefono, 
            email, 
            direccion, 
            codigo_postal, 
            pais, 
            poblacion, 
            contacto_nombre, 
            contacto_numero,
            fecha_alta,    
            fecha_baja,
            observaciones
        })
    },
    edit: (
        id: string,
        cif: string,
        razon_social: string,
        nombre_comercial: string,
        numero_telefono: string, 
        email: string, 
        direccion: string, 
        codigo_postal: string, 
        pais: string, 
        poblacion: string, 
        contacto_nombre: string, 
        contacto_numero: string,
        fecha_alta: string,    
        fecha_baja: string,
        observaciones: string,
        )=>{
        return api.put('/admin/update/clinica/'+id,{
            cif,
            razon_social,
            nombre_comercial,
            numero_telefono, 
            email, 
            direccion, 
            codigo_postal, 
            pais, 
            poblacion, 
            contacto_nombre, 
            contacto_numero,
            fecha_alta,    
            fecha_baja,
            observaciones,
        })
    },
    delete: (
        id: any
    )=>{
        return api.get('/admin/destroy/clinica/'+id)
    },
}

export default ClinicasServices