import tiposEmpleadosServices from "./services";

import tiposEmpleadosConstants from "./constants";

const {
    SET_TIPOS_EMPLEADOS,
    SET_LOADING_TIPOS_EMPLEADOS,
    SET_SELECTED_ROW_KEYS,
    SET_SAVING_TIPOS_EMPLEADOS
} = tiposEmpleadosConstants

export const setLoadingTiposEmpleados = (isLoadingTiposEmpleados: boolean) => ({
    type: SET_LOADING_TIPOS_EMPLEADOS,
    isLoadingTiposEmpleados,
  });
  
  const setTipoEmpleado = (tipo: any) => ({
    type: SET_TIPOS_EMPLEADOS,
    tipo,
  });
  
  export const fetchAll = () => {
    return (dispatch: any) => {
      dispatch(setLoadingTiposEmpleados(true));
      tiposEmpleadosServices.fetchAll().then((res) => {
        dispatch(setLoadingTiposEmpleados(false));
        return dispatch(setTipoEmpleado(res.data.response));
      });
    };
  };
  
  const { SET_EDITING_TIPOS_EMPLEADOS, SET_EDIT_ERROR, SET_EDIT_OK } = tiposEmpleadosConstants;
  const setEditingTiposEmpleados = (isEditingTiposEmpleados: boolean) => ({
    type: SET_EDITING_TIPOS_EMPLEADOS,
    isEditingTiposEmpleados,
  });
  
  const setEditError = (editError: string | null) => ({
    type: SET_EDIT_ERROR,
    editError,
  });
  
  const setEditOk = (editOk: boolean) => ({
    type: SET_EDIT_OK,
    editOk,
  });
  
  export const deleteTipoEmpleado = (data: any) => {
    return (dispatch: any) => {
      dispatch(setEditingTiposEmpleados(true));
      dispatch(setEditError(null));
      tiposEmpleadosServices
        .destroy(
          data.id
        )
        .then((res: any) => {
          dispatch(setEditingTiposEmpleados(false));
          dispatch(setEditOk(true));
          dispatch(fetchAll())
        })
        .catch((err) => {
          if (
            // Messy
            err.response &&
            err.response.status !== 500 &&
            err.response.data &&
            err.response.data.errors.message
          ) {
            dispatch(setEditError(err.response.data.errors.message));
          } else {
            dispatch(setEditError('Ha ocurrido un error'));
          }
          dispatch(setEditingTiposEmpleados(false));
        });
    };
  };
  
  export const deleteCentro = (data: any) => {
    return (dispatch: any) =>{
      console.log('=D')
      /*tiposEmpleadosServices.delete(data)
      .then(res => {
        let {message} = res.data
  
        if(message === "deleted succesfully") fetchAll()
      })
      .catch(e => console.log(e))*/
    }
  }
  
  const selecteddRowKeys = (selectedRowKeys: any) => ({
    type: SET_SELECTED_ROW_KEYS,
    selectedRowKeys,
  });
  
  export const setSelectedRowKeys = (selectedRowKeys: any) => {
    return (dispatch: any) => {
      return dispatch(selecteddRowKeys(selectedRowKeys));
    };
  };
  
  const savingTipo = (saving: boolean | null)=>({
    type: SET_SAVING_TIPOS_EMPLEADOS,
    saving
  })

  export const saveTiposEmpleados = (data:any) =>{
    return (dispatch: any)=>{
    try{
      dispatch(setLoadingTiposEmpleados)
      dispatch(savingTipo(true))
      tiposEmpleadosServices.save(
        data.nombre,
        data.tieneEspecialidad
        ).then(res =>
            dispatch(fetchAll())
          )
      }catch(e){
        console.log(e)
      }
    }
  }